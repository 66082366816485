import { Autocomplete, Avatar, AvatarGroup, Box, Checkbox, TextField, Tooltip } from '@mui/material'
import RenderTaskName from './RenderTaskName'
import TaskInfo from '../TaskDrawer/TaskInfo'
import { taskTypeList } from '../DataGrid/filterArrays'
import dayjs from 'dayjs'
import { NcoStatusContainer, StyledGridDefaultTypography, StyledGridTypography } from '../../style/styleElements'
import RenderTaskRole from '../Tasks/RenderTaskRole/RenderTaskRole'
import EditSelectCell from '../NCO/EditSelectCell'
import { strictEqualsOperator } from '../DataGrid/gridOperators'
import { getGridStringOperators } from '@mui/x-data-grid-pro'
import { renderColorByString } from '../DataGrid/renderColorByString'
import EditableDatePicker from '../NCO/EditableDatePicker'
import { getDensityStyles } from '../DataGrid/style'
import { taskMasterStatusOptions } from '../DataGrid/filterArrays'
import { formatDayJsDateTime } from '../DataGrid/createColumns/renderDealsColumns'
import UserImageComponent from '../Users/UserImageComponent'
import { handleFormatTypesString } from '../Forms/types.ts'

const formatDateString = date => {
  if (!date) return ''
  return dayjs.isDayjs(date) ? date.format('MM/DD/YYYY') : dayjs(date).format('MM/DD/YYYY')
}

const renderTeamTasksColumns = (
  theme,
  users,
  density = 'standard',
  handleMassUpdate,
  rowSelectionModel,
  handleTaskClick,
  handleClickEditStart,
  handleHighPrioChange,
  handleUserChange,
  handleSelectUserRow
) => {
  const densityStyles = getDensityStyles(density)
  return [
    {
      field: 'name',
      headerName: 'Name',
      flex: 1,
      minWidth: 175,
      editable: rowSelectionModel.length <= 1,
      isCellEditable: () => rowSelectionModel.length <= 1,
      renderCell: params => (
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'left',
            width: '100%',
            height: '100%',
            boxSizing: 'border-box',
            padding: densityStyles.padding,
            gap: densityStyles.gap,
            cursor: 'pointer',
          }}
          onClick={event => handleTaskClick(event, params?.row)}
        >
          {params.value}
        </Box>
      ),
      renderEditCell: params => {
        const handleChange = event => {
          params.api.setEditCellValue({
            id: params.id,
            field: params.field,
            value: event.target.value,
          })
        }

        const handleBlur = () => {
          params.api.stopCellEditMode({ id: params.id, field: params.field })
          // handleChangeName(params.id, params.value)
        }

        return (
          <TextField
            fullWidth
            value={params.value || ''}
            onChange={handleChange}
            onBlur={handleBlur}
            onKeyDown={event => {
              if (event.key === 'Enter') {
                handleBlur()
              }
            }}
            autoFocus
          />
        )
      },
    },
    // {
    //   field: 'info',
    //   headerName: 'Info',
    //   flex: 0.3,
    //   minWidth: 50,
    //   // editable: rowSelectionModel.length <= 1,
    //   renderCell: params => {
    //     return (
    //       <Box
    //         style={{
    //           // display: 'flex',
    //           alignItems: 'center',
    //           justifyContent: 'space-between',
    //         }}
    //       >
    //         <TaskInfo handleTaskClick={handleTaskClick} params={params.row} />
    //       </Box>
    //     )
    //   },
    // },
    {
      field: 'masterStatus',
      headerName: 'Master Status',
      flex: 0.6,
      width: 75,
      editable: rowSelectionModel.length <= 1,
      isCellEditable: () => rowSelectionModel.length <= 1,
      filterOperators: [...getGridStringOperators(), strictEqualsOperator],
      renderCell: params => {
        const handleClick = () => {
          params.api.startCellEditMode({
            id: params.id,
            field: params.field,
          })
        }
        return (
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              // width: '100%',
              cursor: 'pointer',
            }}
            onClick={() => handleClickEditStart(params)}
          >
            <NcoStatusContainer
              size='small'
              type={params.value}
              sx={{
                bgcolor: renderColorByString(params.value, theme),
                textAlign: 'center',
                fontSize: 14,
                color: 'rgba(0,0,0,1)',
              }}
            >
              {params.value}
            </NcoStatusContainer>
          </Box>
        )
      },
      renderEditCell: params => <EditSelectCell params={params} options={taskMasterStatusOptions} />,
    },
    {
      field: 'taskDays',
      headerName: 'Task Days',
      flex: 0.3,
      minWidth: 50,
      align: 'center', // This centers the column's content
      headerAlign: 'left', // Optionally, also center the header text
      valueGetter: params => {
        return params ?? 0
      },
    },
    {
      field: 'highPrio',
      headerName: 'High Priority',
      flex: 0.3,
      minWidth: 50,
      align: 'center',
      // Only allow inline editing if at most one row is selected
      editable: rowSelectionModel.length <= 1,
      isCellEditable: () => rowSelectionModel.length <= 1,
      renderCell: params => (
        <Checkbox
          checked={!!params.value}
          onChange={event => {
            // Prevent inline update if more than one row is selected
            if (rowSelectionModel.length > 1) return
            // Otherwise, call our single update handler for highPrio.
            handleHighPrioChange(params.row.SK, event.target.checked)
          }}
          inputProps={{ 'aria-label': 'High Priority' }}
        />
      ),
    },

    {
      field: 'type',
      headerName: 'Task Type',
      flex: 0.3,
      minWidth: 175,
      editable: false,
      // editable: rowSelectionModel.length <= 1,
      // isCellEditable: () => rowSelectionModel.length <= 1,
      filterOperators: [...getGridStringOperators(), strictEqualsOperator],
      valueGetter: params => {
        return handleFormatTypesString(params)
      },
      renderCell: params => {
        const handleClick = () => {
          params.api.startCellEditMode({
            id: params.id,
            field: params.field,
          })
        }
        return (
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              width: '100%',
              height: '100%',
              // cursor: 'pointer', ///////////////////////
              boxSizing: 'border-box', // Include padding in the size calculation
            }}
            // onClick={() => handleClickEditStart(params)} ///////////////
          >
            <NcoStatusContainer
              size='small'
              type={params.value}
              sx={{
                bgcolor: renderColorByString(params.value, theme),
                textAlign: 'center',
                fontSize: 14,
                color: 'rgba(0,0,0,1)',
              }}
            >
              {params.value}
            </NcoStatusContainer>
          </Box>
        )
      },
      renderEditCell: params => <EditSelectCell params={params} options={taskTypeList} />,
    },
    {
      field: 'startDate',
      headerName: 'Start Date',
      width: 100,
      flex: 0.5,
      editable: rowSelectionModel.length <= 1,
      isCellEditable: () => rowSelectionModel.length <= 1,
      headerAlign: 'center',
      valueGetter: params => {
        return params ? formatDateString(params) : null
      },
      renderCell: params => {
        const handleClick = () => {
          params.api.startCellEditMode({
            id: params.id,
            field: params.field,
          })
        }
        return (
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              width: '100%',
              cursor: 'pointer',
            }}
            onClick={() => handleClickEditStart(params)}
          >
            {params && params.value ? params.value : ''}
          </Box>
        )
      },
      renderEditCell: params => {
        const handleChange = newValue => {
          params.api.setEditCellValue({
            id: params.id,
            field: params.field,
            value: newValue ? newValue.toISOString() : null, // Save as ISO string
          })
        }
        return <EditableDatePicker value={params.value} onChange={handleChange} />
      },
    },
    {
      field: 'dateDue',
      headerName: 'Date Due',
      width: 100,
      flex: 0.5,
      headerAlign: 'center',
      valueGetter: params => {
        return params ? formatDateString(params) : null
      },
      renderCell: params => {
        return (
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              width: '100%',
            }}
          >
            {params.value ? params.value : ''}
          </Box>
        )
      },
    },

    {
      field: 'dueDate',
      headerName: 'Due Date',
      width: 100,
      flex: 0.5,
      headerAlign: 'center',
      editable: rowSelectionModel.length <= 1,
      isCellEditable: () => rowSelectionModel.length <= 1,
      valueGetter: params => {
        return params ? formatDateString(params) : null
      },
      renderCell: params => {
        const handleClick = () => {
          params.api.startCellEditMode({
            id: params.id,
            field: params.field,
          })
        }
        return (
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              width: '100%',
            }}
            onClick={() => handleClickEditStart(params)}
          >
            {params && params.value ? params.value : ''}
          </Box>
        )
      },
      renderEditCell: params => {
        const handleChange = newValue => {
          params.api.setEditCellValue({
            id: params.id,
            field: params.field,
            value: newValue ? newValue.toISOString() : null, // Save as ISO string
          })
        }
        return <EditableDatePicker value={params.value} onChange={handleChange} />
      },
    },
    {
      field: 'assigner',
      headerName: 'Assigner',
      flex: 0.1,
      minWidth: 100,
      headerAlign: 'center',
      valueGetter: params => {
        return params && params.name ? params.name : 'No User Assigned'
      },
      renderCell: params => {
        const user = params?.row?.assigner
        return (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
              alignItems: 'center',
              height: '100%',
              padding: densityStyles.padding,
              gap: densityStyles.gap,
            }}
          >
            {user ? (
              <UserImageComponent
                imageUrl={user.image_512}
                width={densityStyles.avatarSize}
                height={densityStyles.avatarSize}
              />
            ) : (
              <Avatar sx={{ opacity: 0.1, width: densityStyles.avatarSize, height: densityStyles.avatarSize }} />
            )}
          </Box>
        )
      },
    },
    {
      field: 'assignees',
      headerName: 'Assignee(s)',
      flex: 0.1,
      minWidth: 100,
      headerAlign: 'center',
      valueGetter: params => {
        const usersArray = Array.isArray(params) ? params : !Array.isArray(params) ? [params] : []
        return usersArray.map(user => user.name).join(', ') // Returns names as a string
      },
      renderCell: params => {
        const values = Array.isArray(params?.row?.assignees)
          ? params?.row?.assignees
          : !Array.isArray(params?.row?.assignees)
          ? [params?.row?.assignees]
          : []
        return (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: '100%',
              flexWrap: 'wrap',
              padding: densityStyles.padding,
              gap: densityStyles.gap,
            }}
            // onClick={() => handleClickEditStart(params)}
            // onClick={() => handleSelectUserRow(params?.rowNode?.id, role)}
          >
            <AvatarGroup max={4} spacing={'small'}>
              {values.length > 0
                ? values.map((user, index) => (
                    // <Tooltip key={`${user.user_uuid || user.id} - ${index}`} title={user?.role}>
                    <Tooltip key={`${user.user_uuid || user.id} - ${index}`} title={user?.role}>
                      <Avatar
                        key={`${user.user_uuid || user.id} - ${index}`}
                        src={user.image_512}
                        sx={{ height: densityStyles.avatarSize, width: densityStyles.avatarSize }}
                      />
                    </Tooltip>
                  ))
                : 'Select'}
            </AvatarGroup>
          </Box>
        )
      },
    },
    // ...renderTeamMemberColumns(),
    {
      field: 'timeCreated',
      headerName: 'Time Created',
      width: 100,
      flex: 0.5,
      headerAlign: 'center',
      valueGetter: params => {
        if (!params) return null

        // Ensure params.value is a valid date object or ISO string
        const date = dayjs(params) // ✅ Use dayjs to parse

        return date.valueOf()
      },
      // valueGetter: params => {
      //   console.log('paramsdatetime', params ? formatDayJsDateTime(params) : null)
      //   return params ? formatDayJsDateTime(params) : null
      // },
      renderCell: params => {
        const date = params?.row?.timeCreated
        const dateValue = date ? formatDayJsDateTime(date) : null
        return (
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              width: '100%',
            }}
          >
            {dateValue ? dateValue : ''}
          </Box>
        )
      },
      sortComparator: (a, b) => b - a,
    },
  ]
}

export default renderTeamTasksColumns
