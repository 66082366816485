import React, { useEffect, useState, useMemo, useRef } from 'react'
import { Box, Autocomplete, TextField, Typography, CircularProgress, useTheme } from '@mui/material'
import { useDebounce } from 'use-debounce'
import { useQueryClient } from '@tanstack/react-query'
import { useLocation, useNavigate } from 'react-router-dom'
import CorporateFareIcon from '@mui/icons-material/CorporateFare'
import AttachMoneyIcon from '@mui/icons-material/AttachMoney'
import PersonIcon from '@mui/icons-material/Person'
import { renderColorByString } from '../DataGrid/renderColorByString'

const renderEntityIcon = type => {
  switch (type) {
    case 'deals':
      return <AttachMoneyIcon sx={{ color: '#fbc11e' }} />
    case 'organizations':
      return <CorporateFareIcon sx={{ color: 'rgb(114, 229, 218)' }} />
    case 'contacts':
      return <PersonIcon sx={{ color: 'rgb(255, 123, 187)' }} />
    default:
      return null
  }
}

const EntitySearch = () => {
  const queryClient = useQueryClient()
  const navigate = useNavigate()
  const location = useLocation()
  const theme = useTheme()
  const [searchTerm, setSearchTerm] = useState('')
  const [debouncedSearchTerm] = useDebounce(searchTerm, 300) // ✅ Debounce user input
  const inputRef = useRef(null)
  // ✅ Get pre-fetched data from React Query cache
  const allEntities = queryClient.getQueryData(['entities', 'index']) || []
  // ✅ Filter entities client-side based on search term
  const filteredEntities = useMemo(() => {
    if (!debouncedSearchTerm) return [] // Show no results when input is empty
    return allEntities.filter(entity => entity?.name?.toLowerCase()?.includes(debouncedSearchTerm.toLowerCase()))
  }, [debouncedSearchTerm, allEntities])
  useEffect(() => {
    const entitiesWithoutName = allEntities.filter(entity => !entity?.name)
    if (entitiesWithoutName.length > 0) {
      console.warn('🚨 Entities missing name field:', entitiesWithoutName)
      console.warn(
        '🚨 Entity IDs missing name:',
        entitiesWithoutName.map(entity => entity?.entityId || 'No ID')
      )
    }
  }, [allEntities])

  // ✅ Handles selection and navigation
  // Conditionally focus when location state indicates we should
  useEffect(() => {
    if (location.state?.focusSearch && inputRef.current) {
      inputRef.current.focus()
      // Optionally, clear the flag after focusing if needed
      // navigate(location.pathname, { replace: true, state: {} })
    }
  }, [location])
  useEffect(() => {
    if (allEntities) {
      console.log('allEntities', allEntities)
    }
  }, [allEntities])

  const handleChangeItem = (_, newItem) => {
    if (!newItem) {
      setSearchTerm('')
      return
    }
    setSearchTerm(newItem.name)
    const pathSegments = location.pathname.split('/')
    const currentTab = pathSegments.length > 3 ? pathSegments[3] : 'details'
    const endTab = newItem?.type === pathSegments[1] ? currentTab : 'details'
    const newPath = `/${newItem.type}/${newItem.entityId}/${endTab}`
    navigate(newPath, { state: { focusSearch: true } })
  }

  return (
    <Autocomplete
      multiple={false}
      inputValue={searchTerm}
      onInputChange={(_, newValue) => setSearchTerm(newValue)}
      options={filteredEntities} // ✅ Uses filtered results
      getOptionLabel={option => option.name || ''}
      onChange={handleChangeItem}
      isOptionEqualToValue={(option, value) => option.entityId === value?.entityId}
      renderOption={(props, option) => (
        <Box
          component='li'
          {...props}
          key={option.entityId}
          sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', gap: 2 }}
        >
          <Box sx={{ display: 'flex', flexGrow: 1, gap: 1 }}>
            <Box>{renderEntityIcon(option.type)}</Box>
            <Typography sx={{ color: theme.palette.text.primary }}>{option.name}</Typography>
          </Box>
          <Box sx={{ display: 'flex' }}>
            <Typography sx={{ color: renderColorByString(option?.status) }}>{option?.status}</Typography>
          </Box>
        </Box>
      )}
      renderInput={params => (
        <TextField
          {...params}
          placeholder='Search Deals, Organizations, Contacts...'
          InputProps={{
            ...params.InputProps,
            inputRef: inputRef,
            // endAdornment: allEntities.length === 0 ? <CircularProgress color='inherit' size={18} /> : null,
          }}
        />
      )}
      clearOnEscape
      fullWidth
      freeSolo
    />
  )
}

export default EntitySearch

// import React, { useEffect, useState, useMemo } from 'react'
// import { Box, Autocomplete, TextField, CircularProgress, Typography } from '@mui/material'
// import { useLocation, useNavigate, useParams } from 'react-router-dom'
// import { useDebounce } from 'use-debounce'
// import { useEntities } from '../api/aws/useEntities'
// import CorporateFareIcon from '@mui/icons-material/CorporateFare'
// import AttachMoneyIcon from '@mui/icons-material/AttachMoney'
// import PersonIcon from '@mui/icons-material/Person'

// // ✅ Define entity icons
// const renderEntityIcon = type => {
//   switch (type) {
//     case 'deal':
//       return <AttachMoneyIcon sx={{ color: '#fbc11e' }} />
//     case 'organization':
//       return <CorporateFareIcon sx={{ color: 'rgb(114, 229, 218)' }} />
//     case 'contact':
//       return <PersonIcon sx={{ color: 'rgb(255, 123, 187)' }} />
//     default:
//       return null
//   }
// }

// const EntitySearch = () => {
//   const [searchTerm, setSearchTerm] = useState('')
//   const [selectedEntity, setSelectedEntity] = useState(null)
//   const [debouncedSearchTerm] = useDebounce(searchTerm, 300) // ✅ Debounce API calls
//   const { data: allEntities = [], isFetching } = useEntities(true, debouncedSearchTerm)

//   const { entityId } = useParams()
//   const navigate = useNavigate()
//   const location = useLocation()

//   // ✅ Valid tabs for each entity type
//   const validTabs = {
//     deal: ['details', 'reviewdeal', 'agreement', 'tasks', 'strategy', 'timeline', 'invoicing'],
//     organization: ['details', 'deals', 'tasks', 'billing', 'notes', 'integration'],
//     contact: ['details', 'activity', 'emails', 'notes'],
//   }

//   // ✅ Memoized entities to avoid unnecessary re-renders
//   const memoizedEntities = useMemo(() => {
//     return allEntities.map(entity => ({
//       ...entity,
//       type: entity.type === 'deals' ? 'deal' : entity.type === 'organizations' ? 'organization' : 'contact',
//       entityId: entity.entityId,
//       icon: renderEntityIcon(entity.type),
//     }))
//   }, [allEntities])

//   // ✅ Ensure the correct entity is selected from the URL, but don't override free typing
//   useEffect(() => {
//     if (entityId) {
//       const foundEntity = memoizedEntities.find(e => e.entityId === entityId) || null
//       setSelectedEntity(foundEntity) // ✅ Set selection without overriding free typing
//       if (foundEntity && !searchTerm) setSearchTerm(foundEntity.name) // ✅ Only update if searchTerm is empty
//     }
//   }, [entityId, memoizedEntities])

//   // ✅ Handles selection and navigation
//   const handleChangeItem = (_, newItem) => {
//     if (!newItem) {
//       setSelectedEntity(null)
//       setSearchTerm('')
//       return
//     }

//     setSelectedEntity(newItem)
//     setSearchTerm(newItem.name)

//     const pathSegments = location.pathname.split('/')
//     const currentTab = pathSegments.length > 3 ? pathSegments[3] : 'details'

//     let newPath = `/${newItem.type}s/${newItem.entityId}/${currentTab}`
//     navigate(newPath)
//   }

//   return (
//     <Autocomplete
//       multiple={false}
//       value={selectedEntity} // ✅ Correctly binds selected item
//       inputValue={searchTerm}
//       onInputChange={(_, newValue) => setSearchTerm(newValue)}
//       options={memoizedEntities}
//       getOptionLabel={option => option.name || ''}
//       isOptionEqualToValue={(option, value) => option.entityId === value?.entityId}
//       onChange={handleChangeItem}
//       renderOption={(props, option) => (
//         <Box component='li' {...props} key={option.entityId} sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
//           {option.icon}
//           <Typography>{option.name}</Typography>
//         </Box>
//       )}
//       renderInput={params => (
//         <TextField
//           {...params}
//           placeholder='Search Deals, Organizations, Contacts...'
//           InputProps={{
//             ...params.InputProps,
//             endAdornment: isFetching ? <CircularProgress color='inherit' size={18} /> : null,
//           }}
//         />
//       )}
//       fullWidth
//       freeSolo
//     />
//   )
// }

// export default EntitySearch
