import { Progress } from 'antd'

export const statusFilters = [
  { key: 'dealPage-status', label: 'Prospect', value: 'Prospect', color: 'rgb(93, 79, 251)' },
  { key: 'dealPage-status', label: 'Onboarding', value: 'Onboarding', color: 'rgb(92, 179, 253)' },
  { key: 'dealPage-status', label: 'In-Progress', color: 'rgb(110, 156, 222)' },
  { key: 'dealPage-status', label: 'Active', value: 'Active', color: 'rgb(152, 214, 129)' },
  { key: 'dealPage-status', label: 'Active (SEO)', color: 'rgb(152, 98, 161)' },
  { key: 'dealPage-status', label: 'Active (Creative)', color: 'rgb(147, 111, 218)' },
  { key: 'dealPage-status', label: 'Active (Website)', color: 'rgb(102, 69, 169)' },
  { key: 'dealPage-status', label: 'Active (Past Due)', color: 'rgb(255, 131, 88)' },
  { key: 'dealPage-status', label: 'Active (LTP)', color: 'rgb(51, 158, 205)' },
  { key: 'dealPage-status', label: '30 day notice', value: '30 day notice', color: 'rgb(235, 77, 77)' },
  { key: 'dealPage-status', label: 'Cancelled', value: 'Cancelled', color: 'rgb(0,0,0)' },
  { key: 'dealPage-status', label: 'Inactive / Paused', color: 'rgb(255, 145, 145)' },
]
export const contractStatusFilters = [
  { key: 'dealPage-contractStatus', label: 'Active', value: 'Active', color: 'rgb(75, 185, 114)' },
  {
    key: 'dealPage-contractStatus',
    label: 'Approval Requested',
    value: 'Approval Requested',
    color: 'rgba(255, 148, 112, 1)',
  },
  { key: 'dealPage-contractStatus', label: 'Completed', value: 'Completed', color: 'rgb(80, 200, 120)' },
  {
    key: 'dealPage-contractStatus',
    label: 'Contract Approved',
    value: 'Contract Approved',
    color: 'rgb(255, 144, 69)',
  },
  { key: 'dealPage-contractStatus', label: 'Created', value: 'Created', color: 'rgb(112, 224, 213)' },
  {
    key: 'dealPage-contractStatus',
    label: 'Completed Internally',
    value: 'Completed Internally',
    color: 'rgba(22, 160, 133, 1)',
  },
  {
    key: 'dealPage-contractStatus',
    label: 'Month to Month',
    value: 'Month to Month',
    color: 'rgb(23, 127, 217)',
  },
  {
    key: 'dealPage-contractStatus',
    label: 'Needs Approval',
    value: 'Needs Approval',
    color: 'rgb(251, 193, 30)',
  },
  {
    key: 'dealPage-contractStatus',
    label: 'Sent For Approval',
    value: 'Sent For Approval',
    color: 'rgb(163, 96, 205)',
  },
  { key: 'dealPage-contractStatus', label: 'Saved', value: 'Saved', color: 'rgb(34, 121, 145)' },
  { key: 'dealPage-contractStatus', label: 'Sent', value: 'Sent', color: 'rgb(76, 68, 198)' },
  { key: 'dealPage-contractStatus', label: 'Set Status', value: 'Set Status', color: 'rgb(178, 190, 181)' },
  {
    key: 'dealPage-contractStatus',
    label: 'Strategy Approved',
    value: 'Strategy Approved',
    color: 'rgb(6, 255, 167)',
  },
  { key: 'dealPage-contractStatus', label: 'Viewed', value: 'Viewed', color: 'rgb(213, 102, 201)' },
]
export const dealStatusFilters = [
  {
    key: 'dealPage-dealStatus',
    label: 'Month To Month',
    value: 'Month To Month',
    color: 'rgb(22, 138, 239)',
  },
  {
    key: 'dealPage-dealStatus',
    label: 'Active',
    value: 'Active',
    color: 'rgb(80, 200, 120)',
  },
  {
    key: 'dealPage-dealStatus',
    label: 'Active (system)',
    value: 'Active (system)',
    color: 'rgb(40, 249, 144)',
  },
  {
    key: 'dealPage-dealStatus',
    label: 'In Progress',
    value: 'In Progress',
    color: 'rgb(253, 188, 100)',
  },
  {
    key: 'dealPage-dealStatus',
    label: '30 Day Notice',
    value: '30 Day Notice',
    color: 'rgb(255, 123, 208)',
  },
  { label: 'dealPage-cancelled', label: 'Cancelled', value: 'Cancelled', color: 'rgb(0,0,0)' },
  // {
  //   key: 'dealPage-dealStatus',
  //   label: 'Expired',
  //   value: 'Expired',
  //   color: 'rgba(220, 20, 60, 0.8)',
  // },
  {
    key: 'dealPage-endedsystem',
    label: 'Ended (system)',
    value: 'Ended (system)',
    color: 'rgb(241, 9, 207)',
  },
  {
    key: 'dealPage-dealStatus',
    label: 'Ended',
    value: 'Ended',
    color: 'rgba(220, 20, 60, 0.8)',
  },
]

export const dealStageFilters = [
  { label: 'Discovery', value: 'Discovery', color: 'rgb(25, 117, 255)' },
  {
    label: 'Pending Approval',
    value: 'Pending Approval',
    color: 'rgba(241, 130, 141, 1)',
  },
  { label: 'Proposal', value: 'Proposal', color: 'rgba(178, 94, 178, 1)' },
  {
    label: 'Contract Created',
    value: 'Contract Created',
    color: 'rgb(11, 127, 171, 1)',
  },
  {
    label: 'Contract Sent',
    value: 'Contract Sent',
    color: 'rgb(217, 169, 102, 1)',
  },
  { label: 'Deal Won', value: 'Deal Won', color: 'rgba(80, 200, 120, 1)' },
  { label: 'Cold', value: 'Cold', color: 'rgb(168, 202, 255)' },
  { label: 'Lost', value: 'Purgatory', color: '#ffdc85' },
]
export const dealStageOptions = [
  { label: 'Discovery', value: 'Discovery', color: 'rgb(25, 117, 255)' },
  {
    label: 'Pending Approval',
    value: 'Pending Approval',
    color: 'rgba(241, 130, 141, 1)',
  },
  { label: 'Proposal', value: 'Proposal', color: 'rgba(178, 94, 178, 1)' },
  {
    label: 'Contract Created',
    value: 'Contract Created',
    color: 'rgb(11, 127, 171, 1)',
  },
  {
    label: 'Contract Sent',
    value: 'Contract Sent',
    color: 'rgb(217, 169, 102, 1)',
  },
  { label: 'Deal Won', value: 'Deal Won', color: 'rgba(80, 200, 120, 1)' },
  { label: 'Deal Lost', value: 'Deal Lost', color: '#ffb5ac' },
  { label: 'Cold', value: 'Cold', color: 'rgb(168, 202, 255)' },
  { label: 'Lost', value: 'Lost', color: '#ffdc85' },
]
export const ncoStatusFilters = [
  { label: 'Staging', value: 'Staging', color: 'rgb(238, 111, 141)' },
  { label: 'Onboarding', value: 'Onboarding', color: 'rgb(110, 193, 232)' },
  { label: 'Awaiting Kickoff', value: 'Awaiting Kickoff', color: 'rgb(147, 111, 218)' },
  { label: 'Awaiting Dependencies', value: 'Awaiting Dependencies', color: 'rgb(59, 152, 245)' },
  { label: 'Dependencies Received', value: 'Dependencies Received', color: 'rgb(245, 124, 79)' },
  { label: 'Handoff Call', value: 'Handoff Call', color: 'rgba(106, 65, 192, 1)' },
  { label: 'Launched', value: 'Launched', color: 'rgb(52, 211, 145)' },
]
export const creativeStatusFilters = [
  { label: 'Staging', value: 'Staging', color: 'rgb(238, 111, 141)' },
  { label: 'In Progress', value: 'In Progress', color: 'rgb(253, 188, 100)' },
  { label: 'Needs AM Review', value: 'Needs AM Review', color: 'rgb(255, 131, 88)' },
  { label: 'Needs Revision', value: 'Needs Revision', color: 'rgb(213, 197, 103)' },
  { label: 'Sent for Approval', value: 'Sent for Approval', color: 'rgb(147, 111, 218)' },
  { label: 'Ready to Launch', value: 'Ready to Launch', color: 'rgb(121, 175, 253)' },
  { label: 'Done', value: 'Done', color: 'rgb(53, 153, 112)' },
  { label: 'Blocked', value: 'Blocked', color: 'rgb(133, 214, 255)' },
  { label: 'On Hold', value: 'On Hold', color: 'rgb(238, 40, 99)' },
  { label: 'Needs Copy', value: 'Needs Copy', color: 'rgb(113, 214, 209)' },
  { label: 'Needs CD Review', value: 'Needs CD Review', color: 'rgb(51, 211, 145)' },
  { label: 'CD Approved', value: 'CD Approved', color: 'rgb(255, 68, 161)' },
  { label: 'N/A', value: 'N/A', color: 'rgb(121, 126, 147)' },
  { label: 'Need Creative Attention', value: 'Need Creative Attention', color: 'rgb(51, 158, 205)' },
  { label: 'New Project Review', value: 'New Project Review', color: 'rgb(78, 115, 167)' },
  { label: 'Needs PM Attention', value: 'Needs PM Attention', color: 'rgb(255, 213, 51)' },
  { label: 'With Freelancer', value: 'With Freelancer', color: 'rgb(205, 146, 130)' },
]
export const creativeTypeOptions = [
  { label: 'Social Ad', value: 'Social Ad', color: 'rgb(51, 211, 145)' },
  { label: 'Display Ads', value: 'Display Ads', color: 'rgb(253, 188, 100)' },
  { label: 'Social & Display Ads', value: 'Social & Display Ads', color: 'rgb(133, 214, 255)' },
  { label: 'Logo Design', value: 'Logo Design', color: 'rgb(51, 158, 205)' },
  { label: 'Brand Design', value: 'Brand Design', color: 'rgb(181, 125, 227)' },
  { label: 'Video Design', value: 'Video Design', color: 'rgb(53, 153, 112)' },
  { label: 'Website Build', value: 'Website Build', color: 'rgb(121, 175, 253)' },
  { label: 'Troubleshooting', value: 'Troubleshooting', color: 'rgb(213, 197, 103)' },
  { label: 'Marketing Collateral', value: 'Marketing Collateral', color: 'rgb(92, 92, 92)' },
  { label: 'Photography', value: 'Photography', color: 'rgb(255, 213, 51)' },
  { label: 'Website Edits', value: 'Website Edits', color: 'rgb(201, 92, 118)' },
  { label: 'Organic Social', value: 'Organic Social', color: 'rgb(255, 68, 161)' },
  { label: 'Video Ads', value: 'Video Ads', color: 'rgb(255, 123, 208)' },
]
export const creativeMasterStatusOptions = [
  { label: 'In Progress', value: 'In Progress', color: 'rgb(253, 188, 100)' },
  { label: 'Needs DM Attention', value: 'Needs DM Attention', color: 'rgb(255, 131, 88)' },
  { label: 'Needs AM Attention', value: 'Needs AM Attention', color: 'rgb(51, 158, 205)' },
  { label: 'Needs Review', value: 'Needs Review', color: 'rgb(255, 68, 161)' },
  { label: 'Need Client Feedback', value: 'Need Client Feedback', color: 'rgb(133, 214, 255)' },
  { label: 'Needs Zach Review', value: 'Needs Zach Review', color: 'rgb(33, 110, 223)' },
  { label: 'Done', value: 'Done', color: 'rgb(51, 211, 145)' },
  { label: 'Needs SCS Attention', value: 'Needs SCS Attention', color: 'rgb(134, 180, 202)' },
  { label: 'Postdated (AM)', value: 'Postdated (AM)', color: 'rgb(181, 125, 227)' },
  { label: 'Postdated (DMS)', value: 'Postdated (DMS)', color: 'rgb(147, 111, 218)' },
  { label: 'Launch Campaigns', value: 'Launch Campaigns', color: 'rgb(78, 115, 167)' },
  { label: 'On Hold', value: 'On Hold', color: 'rgb(201, 92, 118)' },
  { label: 'Needs Setup', value: 'Needs Setup', color: 'rgb(119, 122, 229)' },
  { label: 'Ready to Launch', value: 'Ready to Launch', color: 'rgb(53, 153, 112)' },
  { label: 'Waiting on Creative', value: 'Waiting on Creative', color: 'rgb(92, 92, 92)' },
  { label: 'Need AM Attention', value: 'Need AM Attention', color: 'rgb(255, 123, 208)' },
  { label: 'Awaiting Integrations', value: 'Awaiting Integrations', color: 'rgb(121, 175, 253)' },
]
export const websiteTypeOptions = [
  { label: 'Large Site', value: 'Large Site', color: 'rgb(253, 188, 100)' },
  { label: 'New Site', value: 'New Site', color: 'rgb(51, 211, 145)' },
  { label: 'Website Rebuild', value: 'Website Rebuild', color: 'rgb(51, 158, 205)' },
]
export const ncoStatusOptions = [
  { value: 'Staging', label: 'Staging' },
  { value: 'On Hold', label: 'On Hold' },
  { value: 'Onboarding', label: 'Onboarding' },
  { value: 'Awaiting Dependencies', label: 'Awaiting Dependencies' },
  { value: 'Dependencies Received', label: 'Dependencies Received' },
  { value: 'Handoff Call', label: 'Handoff Call' },
  { value: 'Launched', label: 'Launched' },
]
export const seoStatusOptions = [
  { label: 'Staging', value: 'Staging', color: 'rgb(238, 111, 141)' },
  { value: 'On Hold', label: 'On Hold', color: 'rgb(238, 40, 99)' },
  { label: 'Onboarding', value: 'Onboarding', color: 'rgb(110, 193, 232)' },
  { value: 'Stuck', label: 'Stuck', color: 'rgb(232, 105, 125)' },
  { value: 'No Onboarding Sheet', label: 'No Onboarding Sheet', color: 'rgb(255, 213, 51)' },
  { value: 'Paused', label: 'Paused', color: 'rgb(255, 145, 145)' },
  { value: 'Onboarding Completed', label: 'Onboarding Completed', color: 'rgb(52, 211, 145)' },
]
export const seoGmbOptions = [
  { label: 'In-Verification', value: 'In-Verification', color: 'rgb(253, 188, 100)' },
  { label: 'Stuck - Video', value: 'Stuck - Video', color: 'rgb(255, 131, 88)' },
  { label: 'Full Access', value: 'Full Access', color: 'rgb(51, 211, 145)' },
  { label: 'Correct - No Access', value: 'Correct - No Access', color: 'rgb(51, 158, 205)' },
  { label: 'Waiting on AM', value: 'Waiting on AM', color: 'rgb(121, 175, 253)' },
  { label: 'Not Started', value: 'Not Started', color: 'rgb(255, 145, 145)' },
  { label: 'Duplicate Listing', value: 'Duplicate Listing', color: 'rgb(181, 125, 227)' },
  { label: 'Integrations Access', value: 'Integrations Access', color: 'rgb(176, 220, 81)' },
  { label: 'Incorrect - No Access', value: 'Incorrect - No Access', color: 'rgb(213, 197, 103)' },
  { label: 'Missing Listing', value: 'Missing Listing', color: 'rgb(23, 90, 99)' },
  { label: 'Access Lost', value: 'Access Lost', color: 'rgb(255, 68, 161)' },
]
// export const taskMasterStatusOptions = [
//   {
//     value: 'Select Status',
//     label: 'Select Status',
//     color: 'rgb(121, 126, 147)',
//   },
//   { label: 'Awaiting Assignment', value: 'Awaiting Assignment', color: 'rgb(238, 111, 141)' },

//   {
//     value: 'Waiting On Google',
//     label: 'Waiting On Google',
//     color: 'rgb(161, 227, 246)',
//   },
//   {
//     value: 'In Progress',
//     label: 'In Progress',
//     color: 'rgb(253, 188, 100)',
//   },
//   {
//     value: 'DMS Attention',
//     label: 'DMS Attention',
//     color: 'rgb(255, 131, 88)',
//   },
//   {
//     value: 'AM Attention',
//     label: 'AM Attention',
//     color: 'rgb(51, 158, 205)',
//   },
//   {
//     value: 'Need Client Feedback',
//     label: 'Need Client Feedback',
//     color: 'rgb(133, 214, 255)',
//   },
//   {
//     value: 'Done',
//     label: 'Done',
//     color: 'rgb(51, 211, 145)',
//   },
//   {
//     value: 'AM Follow Up',
//     label: 'AM Follow Up',
//     color: 'rgb(43, 118, 229)',
//   },
//   {
//     value: 'Ready To Launch',
//     label: 'Ready To Launch',
//     color: 'rgb(121, 175, 253)',
//   },
//   {
//     value: 'Postdated (AM)',
//     label: 'Postdated (AM)',
//     color: 'rgb(181, 125, 227)',
//   },
//   {
//     value: 'Postdated (DMS)',
//     label: 'Postdated (DMS)',
//     color: 'rgb(147, 111, 218)',
//   },
//   {
//     value: 'On Hold',
//     label: 'On Hold',
//     color: 'rgb(201, 92, 118)',
//   },
//   {
//     value: 'Waiting on Creative',
//     label: 'Waiting on Creative',
//     color: 'rgb(92, 92, 92)',
//   },
//   {
//     value: 'DMS SLA Change',
//     label: 'DMS SLA Change',
//     color: 'rgb(119, 122, 229)',
//   },
//   {
//     value: 'AM SLA Change',
//     label: 'AM SLA Change',
//     color: 'rgb(53, 153, 112)',
//   },
//   {
//     value: 'Ready to QA',
//     label: 'Ready to QA',
//     color: 'rgb(113, 214, 209)',
//   },
//   {
//     value: 'SEO Attention',
//     label: 'SEO Attention',
//     color: 'rgb(232, 105, 125)',
//   },
//   {
//     value: 'PM Attention',
//     label: 'PM Attention',
//     color: 'rgb(78, 115, 167)',
//   },
//   {
//     value: 'Needs MI Attention',
//     label: 'Needs MI Attention',
//     color: 'rgb(157, 153, 185)',
//   },
//   {
//     value: 'Waiting On Ad Approvals',
//     label: 'Waiting On Ad Approvals',
//     color: 'rgb(255, 68, 161)',
//   },
//   {
//     value: 'Churn',
//     label: 'Churn',
//     color: 'rgb(255, 123, 208)',
//   },
//   {
//     value: 'Ready to QA - Google Search',
//     label: 'Ready to QA - Google Search',
//     color: 'rgb(251, 180, 244)',
//   },
//   {
//     value: 'Ready to QA - FB/IG',
//     label: 'Ready to QA - FB/IG',
//     color: 'rgb(255, 189, 189)',
//   },
//   {
//     value: 'Ready to QA - GDN',
//     label: 'Ready to QA - GDN',
//     color: 'rgb(217, 116, 176)',
//   },
//   {
//     value: 'Ready to QA - Simplifi',
//     label: 'Ready to QA - Simplifi',
//     color: 'rgb(189, 168, 249)',
//   },
//   {
//     value: 'Ready to QA - TikTok',
//     label: 'Ready to QA - TikTok',
//     color: 'rgb(255, 145, 145)',
//   },
//   {
//     value: 'Ready to QA - Other',
//     label: 'Ready to QA - Other',
//     color: 'rgb(169, 190, 232)',
//   },
//   {
//     value: 'N/A',
//     label: 'N/A',
//     color: 'rgb(121, 126, 147)',
//   },
// ]

export const taskMasterStatusOptions = [
  { value: 'Waiting', label: 'Waiting', color: 'rgb(238, 111, 141)' },
  { value: 'In Progress', label: 'In Progress', color: 'rgb(253, 188, 100)' },
  { value: 'On Hold', label: 'On Hold', color: 'rgb(201, 92, 118)' },
  { value: 'Need More Info', label: 'Need More Info', color: 'rgb(78, 115, 167)' },
  { value: 'Done', label: 'Done', color: 'rgb(51, 211, 145)' },
]

export const taskTypeList = [
  // {
  //   value: 'Update Display Ads',
  //   label: 'Update Display Ads',
  //   color: 'rgb(51, 158, 205)',
  // },
  // {
  //   value: 'Update Social Ads',
  //   label: 'Update Social Ads',
  //   color: 'rgb(181, 125, 227)',
  // },
  // { value: 'Conversion Tracking', label: 'Conversion Tracking', color: 'rgb(53, 153, 112)' },
  // {
  //   value: 'Search Updates',
  //   label: 'Search Updates',
  //   color: 'rgb(121, 175, 253)',
  // },
  // {
  //   value: 'Social Updates',
  //   label: 'Social Updates',
  //   color: 'rgb(213, 197, 103)',
  // },
  // {
  //   value: 'Display Updates',
  //   label: 'Display Updates',
  //   color: 'rgb(92, 92, 92)',
  // },
  // { value: 'Other Channel Updates', label: 'Other Channel Updates', color: 'rgb(201, 92, 118)' },
  // { value: 'Search Review', label: 'Search Review', color: 'rgb(255, 68, 161)' },
  // { value: 'Social Review', label: 'Social Review', color: 'rgb(255, 123, 208)' },
  // {
  //   value: 'Display Review',
  //   label: 'Display Review',
  //   color: 'rgb(147, 111, 218)',
  // },
  // {
  //   value: 'Other Review ',
  //   label: 'Other Review ',
  //   color: 'rgb(255, 131, 88)',
  // },
  // { value: 'Campaign Updates', label: 'Campaign Updates' },
  { value: 'Updates', label: 'Updates' },
  // { value: 'Conversion Tracking', label: 'Conversion Tracking' },
  { value: 'Review / Audit', label: 'Review / Audit' },
  { value: 'Budget', label: 'Budget' },
  { value: 'Create / Setup', label: 'Create / Setup' },
]
