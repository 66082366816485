import { calculateTaskDays, workday } from '../../../components/Tasks/taskUtil'
import { usePostTask } from './usePostTask.ts'
import { useCreateGoogleFolder } from '../../google/useCreateGoogleFolder.ts'
import { formatDayJsDateTime } from '../../../components/DataGrid/createColumns/renderDealsColumns.js'
import { useValidateGoogleToken } from '../../google/useValidateGoogleToken.ts'
import dayjs from 'dayjs'

type NewTaskProps = {
  formData: Record<string, any> // ✅ Task fields are dynamic, so we use Record<string, any>
  setIsCreating: (isCreating: boolean) => void // ✅ Function to toggle loading state
  onClose: () => void // ✅ Callback function for closing UI after success
  parentFolderId: string // ✅ Google Drive folder ID
}

export const useSubmitTask = () => {
  const { mutate: createTask } = usePostTask()
  const { mutateAsync: createTaskFolder } = useCreateGoogleFolder() // ✅ Async mutation for folder creation
  const { validateToken } = useValidateGoogleToken()

  const submitNewTask = async ({ formData, setIsCreating, onClose, parentFolderId }: NewTaskProps) => {
    console.log('📌 FORM DATA:', formData)
    if (!formData.name || !formData.orgId) {
      console.warn('Name of folder and organization id required')
      return
    }
    try {
      setIsCreating(true)

      // ✅ 1. Validate Google Token before making folder request
      const googleAccessToken = localStorage.getItem('google_access_token')
      const userId = localStorage.getItem('slack_user_id')
      const tokenResponse = await new Promise<{ isValid: boolean; accessToken: string | null }>(resolve => {
        validateToken(
          { accessToken: googleAccessToken, userId },
          {
            onSuccess: resolve,
            onError: error => {
              console.error('❌ Google Token Validation Failed:', error)
              resolve({ isValid: false, accessToken: null })
            },
          }
        )
      })

      if (!tokenResponse.isValid || !tokenResponse.accessToken) {
        console.error('❌ Invalid Google Token, cannot create folder.')
        setIsCreating(false)
        return
      }

      // ✅ 2. Create Google Folder First
      const createdTime = dayjs().toISOString()
      const formattedCreatedTime = formatDayJsDateTime(createdTime)

      const newFolder = await createTaskFolder({
        folderName: `${formData?.name} - ${formattedCreatedTime}`,
        parentFolderId, // ✅ Corrected to use function parameter
      })

      if (!newFolder?.folderId) {
        console.error('❌ Failed to create task folder in Google Drive')
        setIsCreating(false)
        return
      }

      console.log('✅ Google Folder Created:', newFolder.folderId)

      // ✅ 3. Create Task with the new Folder ID
      const taskDays = formData?.type ? calculateTaskDays(formData?.type) : 0
      const newDateDue = formData?.startDate && taskDays ? workday(formData?.startDate, taskDays) : null

      const updatedTask = {
        ...formData,
        PK: `ORG_${formData?.orgId}`,
        orgId: formData?.orgId,
        taskType: formData?.label,
        type: formData?.type,
        taskDays,
        dateDue: newDateDue,
        masterStatus: formData?.masterStatus ?? 'Awaiting Assignment',
        createdTime,
        taskFolderId: newFolder.folderId, // ✅ Save Folder ID in task
        parentTaskFolderId: parentFolderId, // ✅ Keep reference to the parent folder
      }

      console.log('✅ Creating Task:', updatedTask)

      createTask(updatedTask, {
        onSuccess: () => {
          console.log('✅ Successfully created task')
          setIsCreating(false)
          onClose()
        },
        onError: error => {
          console.error('❌ Error creating task:', error)
          setIsCreating(false)
        },
      })
    } catch (error) {
      console.error('❌ Error in submitNewTask:', error)
      setIsCreating(false)
    }
  }

  return { submitNewTask }
}

// import { calculateTaskDays, workday } from '../../../components/Tasks/taskUtil'
// import { useCreateTask } from '../useCreateTask'
// import dayjs from 'dayjs'
// import { usePostTask } from './usePostTask.ts'
// import { useCreateGoogleFolder } from '../../google/useCreateGoogleFolder.ts'
// import { formatDayJsDateTime } from '../../../components/DataGrid/createColumns/renderDealsColumns.js'

// export const useSubmitTask = () => {
//   const { mutate: createTask } = usePostTask()
//   const { mutate: createTaskFolder } = useCreateGoogleFolder()
//   const submitNewTask = async (formData, setIsCreating, onClose) => {
//     console.log('FORM DATA: ', formData)
//     try {
//       const taskDays = formData?.type ? calculateTaskDays(formData?.type) : 0
//       const newDateDue = formData?.startDate && taskDays ? workday(formData?.startDate, taskDays) : null
//       console.log('taskDays', taskDays)
//       console.log('newDateDue', newDateDue)
//       const createdTime = dayjs().toISOString()
//       const formattedCreatedTime = formatDayJsDateTime(createdTime)
//       const updatedTask = {
//         ...formData,
//         PK: `ORG#${formData?.orgId}`,
//         // SK: `TASK#` // Handled server side
//         orgId: formData?.orgId, // Include the entire project object
//         taskType: formData?.label,
//         taskDays: taskDays,
//         dateDue: newDateDue,
//         masterStatus: formData?.masterStatus ?? 'Awaiting Assignment',
//         createdTime: dayjs().toISOString(),
//         // Add any other fields required for task creation
//       }
//       console.log('updatedTask', updatedTask)
//       createTask(updatedTask, {
//         onSuccess: data => {
//           console.log('Successfully created task')
//           setIsCreating(false)
//           createTaskFolder({
//             folderName: `${formData?.name} - ${formattedCreatedTime}`,
//             parentFolderId: formData?.tasksFolderId,
//           })
//           onClose()
//         },
//         onError: error => {
//           console.error('Error creating new task', error)
//           setIsCreating(false)
//         },
//       })
//     } catch (error) {
//       console.error('Error creating task', error)
//     }
//   }
//   return { submitNewTask }
// }
