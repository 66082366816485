import { Box, Typography, Grid } from '@mui/material'
import React from 'react'
import FieldComponent from './FieldComponent.tsx'
import { FieldConfig, MarketingFormData } from './types'
import { renderColorByString } from '../DataGrid/renderColorByString'

interface RenderTypeFieldsProps {
  channel: string
  channelFields: Record<string, FieldConfig>
  formData: MarketingFormData
  onChange: (event: {
    target: {
      name: string
      value: unknown
    }
  }) => void
}

const RenderTypeFields: React.FC<RenderTypeFieldsProps> = ({ formData, channel, channelFields, onChange }) => {
  return (
    <>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'center',
          gap: 1,
        }}
      >
        <Typography sx={{ color: renderColorByString(channel) }}>{channel}</Typography>
        {/* <Typography variant='subtitle1'>Details</Typography> */}
      </Box>
      <Grid container spacing={2} p={0.5}>
        {Object.entries(channelFields).map(([key, config]) => (
          <Grid item xs={6} key={key}>
            <FieldComponent name={key} value={formData[key] || ''} onChange={onChange} {...config} />
          </Grid>
        ))}
      </Grid>
    </>
  )
}

export default RenderTypeFields
