import React from 'react'
import { Box, Typography, CircularProgress, Grid, InputLabel, useTheme, List, ListItem } from '@mui/material'
import TaskRichText from '../Tasks/TaskRichText'
import SaveIconButton from '../ui/save-icon-button'
import { handleFormatTypesString } from '../Forms/types.ts'
import { renderColorByString } from '../DataGrid/renderColorByString.js'

const GROUPS = ['search', 'social', 'display', 'video', 'integration', 'seo']
const FIELD_ORDER: Record<string, string[]> = {
  search: ['searchPlatform', 'searchBudget', 'searchUpdate', 'searchReview', 'searchCreate'],
  social: ['socialPlatform', 'socialBudget', 'socialUpdate', 'socialReview', 'socialCreate'],
  display: ['displayPlatform', 'displayBudget', 'displayUpdate', 'displayReview', 'displayCreate'],
  video: ['videoPlatform', 'videoBudget', 'videoUpdate', 'videoReview', 'videoCreate'],
  integration: [
    'integrationOptions',
    'integrationBudget',
    'integrationUpdate',
    'integrationReview',
    'integrationCreate',
  ],
  seo: ['seoSpecifics', 'seoBudget', 'seoUpdate', 'seoReview', 'seoCreate'],
}

const prettifyKey = (key: string) => {
  const parts = key.match(/[A-Z][a-z]+|[a-z]+/g)
  return parts?.slice(1).join(' ') || key
}

const TaskDetails = ({ task, isTaskLoading, onNavigate, onChange, onSave, addtlNotes, expanded }) => {
  const theme = useTheme()

  const handleAddtlNotesChange = newValue => {
    onChange('addtlNotes', newValue)
  }

  return (
    <>
      {isTaskLoading ? (
        <CircularProgress />
      ) : (
        <>
          <Grid
            container
            p={1}
            spacing={0}
            sx={{
              bgcolor: theme.palette.background.section,
            }}
          >
            <Grid item xs={6}>
              <Typography
                sx={{
                  color: renderColorByString(handleFormatTypesString(task?.type)),
                  fontSize: 20,
                  fontWeight: 'bold',
                  textAlign: 'left',
                }}
              >
                {handleFormatTypesString(task?.type)}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                <SaveIconButton hasErrors={false} onSave={onSave} />
              </Box>
            </Grid>
          </Grid>
          <Box
            sx={{
              display: 'flex',
              flexDirection: { xs: 'column', md: 'row' },
              gap: 2,
              padding: 2,
              height: { xs: 'auto', md: 'auto' }, // ✅ Force height for layout to stretch
              maxHeight: '100%',
              overflow: 'hidden',
              width: '100%',
            }}
          >
            {/* Scrollable Grouped Fields */}
            <Box
              sx={{
                flex: 1,
                overflowY: 'auto',
                pr: 2,
                borderRight: { md: `1px solid ${theme.palette.divider}` },
                maxHeight: { xs: 'auto', md: expanded ? 'calc(100vh - 425px)' : 'calc(100vh - 295px)' },
              }}
            >
              {GROUPS.map(group => {
                // Filter out keys that don't exist on the task
                const existingKeys = FIELD_ORDER[group].filter(key => task?.[key] !== undefined && task?.[key] !== null)

                // Only render the section if there's at least one existing key
                if (existingKeys.length === 0) return null

                return (
                  <Box
                    key={group}
                    sx={{
                      mb: 3,
                      width: '100%',
                      position: 'relative',
                      borderBottom: `1px solid ${theme.palette.text.primary}`,
                    }}
                  >
                    <Box
                      sx={{
                        position: 'sticky',
                        top: 0,
                        zIndex: 1,
                        backgroundColor: theme.palette.background.section,
                        py: 0.5,
                      }}
                    >
                      <Typography
                        sx={{ color: theme.palette.text.warning, fontWeight: 'bold', textAlign: 'left', fontSize: 18 }}
                      >
                        {group.charAt(0).toUpperCase() + group.slice(1)}
                      </Typography>
                    </Box>

                    {existingKeys.map(key => {
                      const value = task[key]
                      return (
                        <Box
                          key={key}
                          sx={{
                            mb: 1,
                            textAlign: 'left',
                            display: 'flex',
                            alignItems: key === 'seoSpecifics' ? 'left' : 'center',
                            flexDirection: key === 'seoSpecifics' ? 'column' : 'row',
                          }}
                        >
                          <Typography sx={{ fontSize: 16, color: theme.palette.text.secondary }} fontWeight={600}>
                            {['Platform', 'Options', 'Specifics'].some(substr => key.includes(substr))
                              ? prettifyKey(key)
                              : `What to ${prettifyKey(key)}?`}
                          </Typography>

                          {Array.isArray(value) ? (
                            value.map((item, idx) => (
                              <Typography key={idx} sx={{ ml: 1 }}>
                                {item}
                              </Typography>
                            ))
                          ) : (
                            <Typography variant='body2' sx={{ ml: 1 }}>
                              {value}
                            </Typography>
                          )}
                        </Box>
                      )
                    })}
                  </Box>
                )
              })}
            </Box>

            {/* Rich Text Notes */}
            <Box
              sx={{
                flex: 1,
                pl: { md: 2 },
                maxHeight: { xs: 'auto', md: '100%' },
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              <InputLabel sx={{ mb: 1 }}>Description</InputLabel>
              <Box sx={{ flexGrow: 1 }}>
                <TaskRichText
                  content={addtlNotes}
                  onContentChange={handleAddtlNotesChange}
                  maxHeight='100%'
                  sx={{ height: '100%' }}
                />
              </Box>
            </Box>
          </Box>
        </>
      )}
    </>
  )
}

export default TaskDetails
