import React, { useEffect, useState, useRef } from 'react'
import { GridToolbarContainer, GridToolbarDensitySelector, useGridApiContext } from '@mui/x-data-grid-pro'
import { Badge, Box, Button, IconButton, Tooltip, Typography, useTheme } from '@mui/material'
import { gridToolbarContainerStyles, StyledGridToolbarQuickFilter } from './style'
import CustomStatusFilter from '../DataGrid/createColumns/CustomStatusFilter'
import { useActiveUser } from '../../api/slack/useActiveUser'
import { dealStatusFilters, dealStageFilters, contractStatusFilters } from '../DataGrid/filterArrays'
import CustomStageFilter from '../DataGrid/createColumns/CustomStageFilter'
import ViewKanbanIcon from '@mui/icons-material/ViewKanban'
import { useNavigate } from 'react-router-dom'
import ResetFiltersButton from '../ui/reset-filters-button'
import ResetSortingButton from '../ui/reset-sorting-button'
import ResetColumnsButton from '../ui/reset-columns-button'
import OpenColumnsMenu from '../ui/open-columns-menu'
import OpenFiltersMenu from '../ui/open-filters-menu'
import ExportCsvButton from '../ui/export-csv-button'
import FilterMyDataButton from '../ui/filter-my-data-button.tsx'
import AttachMoneyIcon from '@mui/icons-material/AttachMoney'
import { slotProps } from '../DataGrid/style.js'
import CustomFiltersModal from '../DataGrid/components/CustomFiltersModal.tsx'
import CustomFiltersMenu from '../ui/custom-filters-menu-button.tsx'

const initialSortModel = [
  { field: 'creation_timestamp', sort: 'desc' },
  { field: 'name', sort: 'asc' },
  { field: 'organizations', sort: 'asc' },
  { field: 'status', sort: 'asc' },
]

const initialVisibilityState = {
  implementationFee: false,
  lastModified: false,
  type: false,
  endDate: false,
  creation_timestamp: false,
  isGoogleDisplay: false,
  isGoogleSearch: false,
  isLinkedIn: false,
  isMetaAdvertising: false,
  isMicrosoftAdvertising: false,
  isSimplifi: false,
  isSnapchat: false,
  isSpotify: false,
  isTikTok: false,
  isYoutube: false,
  isSeo1: false,
  isSeo2: false,
  isSeo3: false,
  isSeo4: false,
  seoTier: false,
}
const isSortModelEqual = (sortModel1, sortModel2) => {
  if (sortModel1.length !== sortModel2.length) return false
  return sortModel1.every((sortItem, index) => {
    const initialSortItem = sortModel2[index]
    return sortItem.field === initialSortItem.field && sortItem.sort === initialSortItem.sort
  })
}
const CustomDealsGridToolbar = ({
  filterModel,
  visibilityModel,
  sortModel,
  setFilterModel,
  onClearSorting,
  onClearVisibility,
  context,
}) => {
  const theme = useTheme()
  const navigate = useNavigate()
  const quickFilterRef = useRef(null) // Create a ref for the quick filter
  const [filterStatus, setFilterStatus] = useState('')
  const [filterDealStatus, setFilterDealStatus] = useState('')
  const [filterStage, setFilterStage] = useState('')
  const [anchorEl, setAnchorEl] = useState(null)
  const [isCustomFiltersOpen, setCustomFiltersOpen] = useState(false)
  const { activeUser, isActiveUserLoading, isActiveUserError } = useActiveUser()
  const activeUserName =
    activeUser && activeUser.user && activeUser.user.profile ? activeUser.user.profile.real_name : null
  // 🔹 State for Density Menu Popover
  /////////////////////// KanBan navigation ///////////////////////////////
  const handleNavigateToKanban = () => {
    navigate('/deals/pipeline')
  }
  const isSortModelChanged = !isSortModelEqual(sortModel, initialSortModel)
  ///////////////////////////////////////////////////////////////////////////\
  // Utility function to check if two sort models are deeply equal
  const apiRef = useGridApiContext() // Get DataGrid API

  // 🔹 Toggle Columns Visibility Menu
  const handleOpenColumnsMenu = event => {
    apiRef.current.showPreferences('columns')
  }

  // 🔹 Toggle Filter Panel
  const handleOpenFilterPanel = () => {
    apiRef.current.showFilterPanel()
  }

  // 🔹 Export Data
  const handleExport = () => {
    apiRef.current.exportDataAsCsv()
  }
  // Handle keydown event to focus the quick filter on Ctrl+Shift+F or Cmd+Shift+F
  useEffect(() => {
    const handleKeyDown = event => {
      if ((event.ctrlKey || event.metaKey) && event.key === 'f') {
        event.preventDefault()
        if (quickFilterRef.current) {
          quickFilterRef.current.focus() // Focus the quick filter input
        }
      }
    }

    window.addEventListener('keydown', handleKeyDown)
    return () => {
      window.removeEventListener('keydown', handleKeyDown)
    }
  }, [])
  const handleFilterMyData = () => {
    setFilterModel({
      ...filterModel,
      quickFilterValues: [activeUserName],
    })
  }

  // const handleSetFilter = (fieldName, newValue) => {
  //   const updatedFilters = filterModel.items.filter(item => item.field !== fieldName)

  //   updatedFilters.push({
  //     id: `${fieldName}-${Date.now()}`, // 🔥 Ensure unique ID
  //     field: fieldName,
  //     operator: 'contains',
  //     value: newValue,
  //   })

  //   setFilterModel({
  //     ...filterModel,
  //     items: updatedFilters,
  //   })
  // }
  const handleSetFilter = (fieldName, selectedValues) => {
    setFilterModel(prevModel => {
      // Remove existing filter for the same field
      let updatedFilters = prevModel.items.filter(item => item.field !== fieldName)

      // If selectedValues is empty, we remove the filter
      if (selectedValues && selectedValues.length > 0) {
        updatedFilters.push({
          id: `${fieldName}-${Date.now()}`, // Ensure unique ID
          field: fieldName,
          operator: 'isAnyOf', // 🔥 Multi-selection operator
          value: selectedValues,
        })
      }

      return {
        ...prevModel,
        items: updatedFilters,
      }
    })
  }

  const handleClearFilters = e => {
    e.preventDefault()
    setFilterModel({ items: [], quickFilterValues: [] })
    setFilterStatus('')
    setFilterDealStatus('')
    setFilterStage('')
  }
  const handleOpenCustomFilters = event => {
    setAnchorEl(event.currentTarget) // Attach the popover to the button
    setCustomFiltersOpen(true)
  }

  const handleCloseCustomFilters = () => {
    setAnchorEl(null) // Clear the anchor when closing
    setCustomFiltersOpen(false)
  }
  return (
    <GridToolbarContainer sx={gridToolbarContainerStyles}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          alignContent: 'center',
          justifyContent: 'space-between',
          width: '100%',
        }}
      >
        <Box sx={{ display: 'flex', flexDirection: 'row', gap: 2, ml: 2 }}>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
            }}
          >
            {/* <Typography sx={{ color: textColor }}>{'Kanban View'}</Typography> */}
            <Tooltip title='View KanBan'>
              <IconButton onClick={handleNavigateToKanban}>
                <ViewKanbanIcon sx={{ height: 30, width: 30, color: 'rgb(251, 193, 30)' }} />
              </IconButton>
            </Tooltip>
          </Box>
          {/* <GridToolbarDensitySelector slotProps={slotProps(theme)} />
          <GridToolbarColumnsButton slotProps={slotProps(theme)} />
          <GridToolbarFilterButton slotProps={slotProps(theme)} />
          <GridToolbarExport slotProps={slotProps(theme)} /> */}
          {/* Density Menu (Now Opens in Popover) */}
          <GridToolbarDensitySelector
            slotProps={{
              ...slotProps(theme),
              fontSize: 0,
              '& .MuiSvgIcon-root': { fontSize: '35px' }, // Ensure the icon remains visible
            }}
          />
          <OpenColumnsMenu onOpenColumns={handleOpenColumnsMenu} />
          <OpenFiltersMenu
            onOpenFilters={handleOpenFilterPanel}
            filterModel={filterModel}
            badgeColor={theme.palette.icon.deals}
          />
          <ExportCsvButton onExport={handleExport} />
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
            gap: 2,
          }}
        >
          <>
            <CustomFiltersMenu
              onOpenCustomFilters={handleOpenCustomFilters}
              count={filterModel?.items?.length ?? 0}
              badgeColor={theme.palette.icon.deals}
            />

            {/* Custom Filters Popover */}
            <CustomFiltersModal
              isOpen={isCustomFiltersOpen}
              anchorEl={anchorEl}
              handleCloseCustomFilters={handleCloseCustomFilters}
            >
              <CustomStatusFilter
                context={'dealStatus'}
                options={dealStatusFilters}
                setterFn={setFilterDealStatus}
                filterModel={filterModel}
                value={filterDealStatus}
                onChange={handleSetFilter}
              />
              <CustomStatusFilter
                context={'stage'}
                options={dealStageFilters}
                setterFn={setFilterStage}
                filterModel={filterModel}
                value={filterStage}
                onChange={handleSetFilter}
              />
              <CustomStatusFilter
                context={'status'}
                options={contractStatusFilters}
                setterFn={setFilterStatus}
                filterModel={filterModel}
                value={filterStatus}
                onChange={handleSetFilter}
              />
            </CustomFiltersModal>
            <FilterMyDataButton
              icon={<AttachMoneyIcon sx={{ fontSize: '30px' }} />}
              filterModel={filterModel}
              onClick={handleFilterMyData}
              label={'My Deals'}
            />
            <ResetColumnsButton
              visibilityModel={visibilityModel}
              onClearVisibility={onClearVisibility}
              initialVisibilityState={initialVisibilityState}
            />
            <ResetSortingButton isSortModelChanged={isSortModelChanged} onClearSorting={onClearSorting} />
            <ResetFiltersButton filterModel={filterModel} handleClearFilters={handleClearFilters} />

            <Badge
              badgeContent={
                <>
                  {filterModel &&
                  filterModel.quickFilterValues &&
                  Array.isArray(filterModel.quickFilterValues) &&
                  filterModel.quickFilterValues.length > 0 ? (
                    <Typography
                      sx={{
                        fontSize: 12,
                        bgcolor: 'rgb(251, 193, 30)',
                        p: 0.5,
                        height: '20px',
                        width: '20px',
                        borderRadius: '50%',
                        display: 'flex', // Flexbox centering
                        alignItems: 'center', // Vertically center
                        justifyContent: 'center', // Horizontally center
                        color: theme.palette.mode === 'dark' ? 'rgba(0,0,0,1)' : 'rgba(0,0,0,1)',
                        fontWeight: 'bold',
                      }}
                    >
                      {filterModel && filterModel.quickFilterValues && Array.isArray(filterModel.quickFilterValues)
                        ? filterModel.quickFilterValues.length
                        : null}
                    </Typography>
                  ) : null}
                </>
              }
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
            >
              <StyledGridToolbarQuickFilter placeholder={'ctrl+f or cmd+f to search'} inputRef={quickFilterRef} />
            </Badge>
          </>
        </Box>
      </Box>
    </GridToolbarContainer>
  )
}

export default CustomDealsGridToolbar
