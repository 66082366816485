export const handleFormatTypesString = (type: string) => {
  switch (type) {
    case 'campaignUpdates':
      return 'Campaign Updates'
    case 'updates':
      return 'Updates'
    case 'conversionTracking':
      return 'Conversion Tracking'
    case 'reviewAudit':
    case 'review':
      return 'Review / Audit'
    case 'budget':
      return 'Budget'
    case 'createSetup':
      return 'Create / Setup'
    default:
      return type
  }
}
export interface FormData {
  name: string
  website: string
  assigner: User
  assignees: User[]
  AM: User[]
  DMS: User[]
  creativeLead: User[]
  priority: string
  startDate: string | null // Allow null values
  endDate: string | null // Allow null values
  deadline: string
  existingClient: boolean
  taskTypes: Array<
    | 'socialAd'
    | 'displayAds'
    | 'organicSocial'
    | 'photography'
    | 'printDesign'
    | 'website'
    | 'websiteDesignOverview'
    | 'websiteStructure'
    | 'video'
    | 'fullBrandBuild'
    | 'logoDesign'
  >
  requestType: string
  taskType: string | ''
  type: string[] | []
  creativeType: string | ''
  createdTime: string
  addtlNotes: string
  creativeStatus: string
  channel: string
}
export interface MarketingFormData {
  name: string
  website: string
  assigner: User
  assignees: User[]
  AM: User[]
  DMS: User[]
  priority: string
  startDate: string | null // Allow null values
  endDate: string | null // Allow null values
  deadline: string
  existingClient: boolean
  createdTime: string
  taskType: string | ''
  type: string | ''
  addtlNotes: string
  requestType: string
  requestedBy: string
  typeDesignation: string
  typeDesignationSpecifics: string
  channel: string[]
  creativeTasksFolderId: string
  googleDriveFolderId: string
  tasksFolderId: string
}
export interface User {
  user_uuid: string
  id: string
  firstName: string
  lastName: string
  name: string
  image_512: string
  role: string
  title: string
}
export interface SocialAdFields {
  dmsAssignment: string
  social_accessToCreative: string
  socialAdTypes: string[] // ✅ Array to store multiple selections
  sameOrNewTemplate: string
  prevRequestLink: string
  reasonForRequestChange: string
  promotingDetails: string
  clientToExclude: string
  callToAction: string
  vibe: string
}

export interface DisplayAdsFields {
  dmsAssignment: string
  display_accessToCreative: string
  displayAdTypes: string[] // ✅ Array to store multiple selections
  sameOrNewTemplate: string
  prevRequestLink: string
  reasonForRequestChange: string
  promotingDetails: string
  clientToExclude: string
  callToAction: string
  vibe: string
}
export interface OrganicSocialFields {
  promotingDetails: string
  callToAction: string
  vibe: string
}
export interface PhotographyFields {
  photographyType: string // Lifestyle, Commercial, Drone, Headshots, Other
  locations_photography: string
  photoUsage: string // Print, Web, Both
}
export interface PrintDesignFields {
  printProjectType: string // Brochure/Booklet, Billboard Ad, Banner/Sign, Vinyl Wrap, Merchandise, Other, Magazine Ad, Sell Sheet/Flyer, Stationary, Fence Screens
  print_dimensions: string
  print_pages: string
  print_creative_asset_link: string
  stockImages: string // Yes, No, Designers Choice
  print_phone: string
  print_address: string
  print_website: string
}
export interface WebsiteFields {
  websiteType: string // Full Website Build, Landing Page, Shopify, Other
  website_existing_domain: string // FieldLabel: Existing Website Domain
  website_new_domain_input: string
  google_analytics_access: string
  website_new_domain: string // FieldLabel: New Website Domain, Options: Have Access, Need Access, Need To Purchase, Not Sure - defer to dev
  website_domain_access: string //FieldLabel: Domain Access, options:  // Have Access, Need Access, Need To Purchase
  website_ssl_cert: string // FieldLabel: SSL Certificate, Options: // Yes, No, Designers Choice
  website_google_analytics: string //FieldLabel: Google Analytics, Options: // Have Access, Need Access, Need To Create
  website_content: string // FieldLabel: Content, Options: // Migrate Content, Create New Content, Migrate & Create
  website_street: string
  website_phone: string
  website_city: string
  website_state: string
  website_bus_email: string
}
export interface WebsiteDesignFields {
  /// Website Design Overview ///
  website_brand_style: string // FieldLabel: Modern or Traditional, Options: // Modern, Traditional, Other
  website_creative_asset_link: string
  use_stock_images: string //FieldLabel: Use Stock Images?, Options // Yes, No, Defer to creative team
  google_map_site: string // FieldLabel: Need Google Map (On Site), Options // Yes, No, Defer to creative team
  need_contact_form: string // FieldLabel: Need Contract Form?, Options // Yes, No, Defer to creative team
  badge_compliance_images: string // FieldLabel: Badges / Compliance Images Needed
  inspiration_links: string // Multiline, helpertext: Ask the client for websites that they like, and paste the links here for inspiration.
  hero_image_headline: string //FieldLabel: Hero Image Headline
  hero_image_cta: string //FieldLabel: Hero Image CTA
}
export interface WebsiteStructureFields {
  website_pages: number
  main_nav_links: string // FieldLabel: Main Navigation Links, Helpertext: Common Links: Home | About | Floorplans | Gallery | Tour | Location | Contact | Apply
  main_nav_cta: string // FieldLabel Main Navigation CTA, Helpertext: Typically, this is "Contact Us" or "Apply Now"
  footer_nav_links: string
  website_addtl_info: string
}
export interface VideoFields {
  videoType: string // FieldLabel: Type of Video, Options: Lifestyle, Interview, Advertisement, Hype Video Ad, Drone
  goal_of_video: string
  video_target_audience: string
  video_projected_hours: number
  video_locations: string
  video_personell: string //FieldLabel: Who is in the video? (Personell)
  req_text_logo: string //FieldLabel: Please list or link any required text or logos that need to be included
  specific_shots: string // FieldLabel: Did the client request any specific shots? If so, specify below:
  output_channels: string // FieldLabel: Output Channels FieldType: Checkbox, Options: Social Ads, Website, Streaming / TV, Youtube / Display, Other
  video_sizes: string // FieldLabel: Video Sizes FieldType: Checkbox, Options: 16:9 (1920 x 1080 or 4k 3840 x 2160), 1:1, 9:16 (Vertical for Snapchat or Story ads)
  video_min_duration: string
  video_max_duration: string
}
export interface BrandDesignFields {
  brand_deliverables: string // FieldLabel: Contract Deliverables FieldType: Checkbox, Options: Logo, Visual ID, Brand Guide
  brand_target_audience: string
  brand_use_colors: string // FieldLabel:  Colors to Use
  brand_avoid_colors: string // FieldLabel:  Colors to Avoid
  brand_style: string // FieldLabel: Modern or Traditional, Options: // Modern, Traditional, Other
  brand_logo_inspiration: string
  brand_color_picker1: string //FieldLabel Color 1, FieldType: color picker component?
  brand_color_picker2: string //FieldLabel Color 2, FieldType: color picker component?
  brand_color_picker3: string //FieldLabel Color 3, FieldType: color picker component?
}
export interface LogoDesignFields {
  logo_deliverables: string // FieldLabel: Contract Deliverables FieldType: Checkbox, Options: Logo, Visual ID, Brand Guide
  logo_target_audience: string
  logo_use_colors: string // FieldLabel:  Colors to Use
  logo_avoid_colors: string // FieldLabel:  Colors to Avoid
  logo_brand_style: string // FieldLabel: Modern or Traditional, Options: // Modern, Traditional, Other
  logo_inspiration: string
  logo_color_picker1: string //FieldLabel Color 1, FieldType: color picker component?
  logo_color_picker2: string //FieldLabel Color 2, FieldType: color picker component?
  logo_color_picker3: string //FieldLabel Color 3, FieldType: color picker component?
}
export interface TaskTypeOptions {
  socialAd: SocialAdFields
  displayAds: DisplayAdsFields
  organicSocial: OrganicSocialFields
  photography: PhotographyFields
  printDesign: PrintDesignFields
  website: WebsiteFields
  websiteDesignOverview: WebsiteDesignFields
  websiteStructure: WebsiteStructureFields
  video: VideoFields
  fullBrandBuild: BrandDesignFields
  logoDesign: LogoDesignFields
}
export interface TaskTypeOptions {
  updates: UpdateFields
  // conversionTracking: ConversionTrackingFields
  reviewAudit: ReviewFields
  budget: BudgetFields
  createSetup: CreationFields
}

export enum FieldType {
  TEXT = 'text',
  SELECT = 'select',
  CHECKBOX = 'checkbox',
  MULTISELECT = 'multiselect',
  COLOR_PICKER = 'color_picker',
  NUMBER = 'number',
  TEXTAREA = 'textarea',
  DATE = 'date',
  AUTOCOMPLETE = 'autocomplete',
}
export interface FieldConfig {
  label: string
  type: FieldType
  board?: string
  options?: string[] // Used for select/multiselect fields
  placeholder?: string
  helperText?: string
  required?: boolean
}
export const FormConfig: Record<keyof TaskTypeOptions, Record<string, FieldConfig>> = {
  socialAd: {
    social_accessToCreative: {
      label: 'Access to Creative',
      type: FieldType.MULTISELECT,
      options: ['Brand Guide', 'Photography', 'Website Assets'],
      placeholder: 'What type of creative assets do we have access to?',
    },
    socialAdTypes: {
      label: 'Social Ad Types',
      type: FieldType.MULTISELECT,
      options: ['Static (1:1 and 9:16)', 'Static Carousel {Set of 3)', 'Video (1:1 and 9:16'],
    },

    sameOrNewTemplate: {
      label: 'Same or New Template (if applicable)?',
      type: FieldType.SELECT,
      options: ['Same', 'New'],
    },
    prevRequestLink: { label: 'Previous Request Link', type: FieldType.TEXT },
    reasonForRequestChange: { label: 'Reason for Request Change', type: FieldType.TEXTAREA },
    promotingDetails: { label: 'Promoting Details', type: FieldType.TEXTAREA, placeholder: 'What are we promoting?' },
    clientToExclude: {
      label: 'Client to Exclude',
      type: FieldType.TEXT,
      placeholder: 'Has the client specified anything they do not want in the creative?',
    },
    callToAction: { label: 'Call to Action', type: FieldType.TEXT },
    vibe: {
      label: 'Vibe',
      type: FieldType.MULTISELECT,
      options: ['Youthful/Fun', 'Upscale', 'Edgy', 'Minimalistic', 'Modern'],
    },
  },
  displayAds: {
    display_accessToCreative: {
      label: 'Access to Creative',
      type: FieldType.MULTISELECT,
      options: ['Brand Guide', 'Photography', 'Website Assets'],
    },
    displayAdTypes: {
      label: 'Display Ad Types',
      type: FieldType.MULTISELECT,
      options: [
        'Standard Ad Set (160x600, 300x250, 300x600, 300x50, 728x90, 320x50)',
        'Responsive Ad Set (1200x628, 1200x1200)',
      ],
    },
    sameOrNewTemplate: { label: 'Same or New Template?', type: FieldType.SELECT, options: ['Same', 'New'] },
    prevRequestLink: { label: 'Previous Request Link', type: FieldType.TEXT },
    reasonForRequestChange: {
      label: 'Reason for Request Change',
      type: FieldType.TEXTAREA,
      placeholder:
        'Please be specific about the reason for the ad change. Is the leasing season changing? Is it for a new special? Did the client provide new photography? Please provide any and all details you can about this request.',
      required: true,
    },
    promotingDetails: { label: 'Promoting Details', type: FieldType.TEXTAREA, placeholder: 'What are we promoting?' },
    clientToExclude: {
      label: 'Client to Exclude',
      type: FieldType.TEXT,
      placeholder: 'Has the client specified anything they do not want in the creative?',
    },
    callToAction: { label: 'Call to Action', type: FieldType.TEXT },
    vibe: {
      label: 'Vibe',
      type: FieldType.MULTISELECT,
      options: ['Youthful/Fun', 'Upscale', 'Edgy', 'Minimalistic', 'Modern'],
    },
  },
  organicSocial: {
    promotingDetails: { label: 'Promoting Details', type: FieldType.TEXTAREA },
    callToAction: { label: 'Call to Action', type: FieldType.TEXT },
    vibe: {
      label: 'Vibe',
      type: FieldType.MULTISELECT,
      options: ['Youthful/Fun', 'Upscale', 'Edgy', 'Minimalistic', 'Modern'],
    },
  },
  photography: {
    photographyType: {
      label: 'Photography Type',
      type: FieldType.SELECT,
      options: ['Lifestyle', 'Commercial', 'Drone', 'Headshots', 'Other'],
    },
    locations_photography: { label: 'Locations for Photography', type: FieldType.TEXT },
    photoUsage: { label: 'Photo Usage', type: FieldType.SELECT, options: ['Print', 'Web', 'Both'] },
  },
  printDesign: {
    printProjectType: {
      label: 'Print Project Type',
      type: FieldType.SELECT,
      options: ['Brochure', 'Billboard', 'Merchandise', 'Magazine Ad', 'Other'],
    },
    print_dimensions: { label: 'Print Dimensions', type: FieldType.TEXT },
    print_pages: { label: 'Number of Pages', type: FieldType.NUMBER },
    print_creative_asset_link: { label: 'Creative Asset Link', type: FieldType.TEXT },
    stockImages: { label: 'Stock Images', type: FieldType.SELECT, options: ['Yes', 'No', 'Designer’s Choice'] },
    print_phone: { label: 'Phone', type: FieldType.TEXT },
    print_address: { label: 'Address', type: FieldType.TEXT },
    print_website: { label: 'Website', type: FieldType.TEXT },
  },
  website: {
    websiteType: {
      label: 'Website Type',
      type: FieldType.SELECT,
      board: 'website',
      options: ['Full Website', 'Landing Page', 'Shopify', 'Other'],
    },
    website_existing_domain: { label: 'Domain (Existing Website)', type: FieldType.TEXT, board: 'website' },
    website_new_domain_input: { label: 'Domain (New Website)', type: FieldType.TEXT, board: 'website' },
    website_new_domain: {
      label: 'Access (New Website Domain)',
      type: FieldType.SELECT,
      board: 'website',
      options: ['Have Access', 'Need Access', 'Need to Purchase', 'Not Sure'],
    },
    website_domain_access: {
      label: 'Access (Existing Website)',
      type: FieldType.SELECT,
      board: 'website',
      options: ['Have Access', 'Need Access', 'Need to Purchase'],
    },
    google_analytics_access: {
      label: 'Access (Google Analytics)',
      type: FieldType.SELECT,
      board: 'website',
      options: ['Have Access', 'Need Access', 'Need to Create'],
    },
    website_content: {
      label: 'Content',
      type: FieldType.SELECT,
      board: 'website',
      options: ['Migrate Content', 'Create new content', 'Migrate & Create'],
    },
    website_ssl_cert: {
      label: 'SSL Certificate',
      type: FieldType.SELECT,
      options: ['Yes', 'No', 'Designer’s Choice'],
      board: 'website',
    },
  },
  websiteDesignOverview: {
    use_stock_images: {
      label: 'Use Stock Images?',
      type: FieldType.SELECT,
      board: 'website',
      options: ['Yes', 'No', 'Defer to creative team'],
    },
    google_map_site: {
      label: 'Need Google map (on site)',
      type: FieldType.SELECT,
      board: 'website',
      options: ['Yes', 'No', 'Defer to creative team'],
    },
    need_contact_form: {
      label: 'Need Contact Form?',
      type: FieldType.SELECT,
      board: 'website',
      options: ['Yes', 'No', 'Defer to creative team'],
    },
    website_brand_style: {
      label: 'Brand Style',
      type: FieldType.MULTISELECT,
      options: [
        'Minimal & Modern',
        'Bold & Geometric',
        'Classic & Elegant',
        'Handwritten/Script',
        'Illustrative & Detailed',
      ],
    },
    website_creative_asset_link: { label: 'Creative Assets Link', type: FieldType.TEXT, board: 'website' },
    badge_compliance_images: { label: 'Badges / Compliance Images Needed', type: FieldType.TEXT },
    inspiration_links: { label: 'Inspiration Links', type: FieldType.TEXT },
    hero_image_headline: { label: 'Hero Image Headline', type: FieldType.TEXT },
    hero_image_cta: { label: 'Hero Image CTA', type: FieldType.TEXT },
  },
  websiteStructure: {
    website_pages: { label: '# of Pages', type: FieldType.NUMBER },
    main_nav_links: { label: 'Main Nav Links', type: FieldType.TEXTAREA, board: 'website' },
    main_nav_cta: { label: 'Main Nav CTA', type: FieldType.TEXTAREA, board: 'website' },
    footer_nav_links: { label: 'Footer Nav Links', type: FieldType.TEXTAREA, board: 'website' },
    website_addtl_info: { label: 'Addtl Info', type: FieldType.TEXTAREA, board: 'website' },
  },
  video: {
    videoType: {
      label: 'Type of Video',
      type: FieldType.SELECT,
      options: ['Lifestyle', 'Interview', 'Advertisement', 'Hype Video', 'Drone'],
    },
    goal_of_video: { label: 'Goal of Video', type: FieldType.TEXT },
    video_target_audience: { label: 'Target Audience', type: FieldType.TEXT },
    video_projected_hours: { label: 'Projected Hours', type: FieldType.NUMBER },
    video_locations: { label: 'Video Locations', type: FieldType.TEXTAREA },
    req_text_logo: { label: 'Required Text or Logos', type: FieldType.TEXT },
    specific_shots: { label: 'Specific Shots Requested', type: FieldType.TEXTAREA },
    output_channels: {
      label: 'Output Channels',
      type: FieldType.MULTISELECT,
      options: ['Social Ads', 'Website', 'Streaming', 'YouTube'],
    },
  },
  fullBrandBuild: {
    deliverables: {
      label: 'Contract Deliverables',
      type: FieldType.CHECKBOX,
      options: ['Logo', 'Visual ID', 'Brand Guide', 'Collateral'],
    },
    target_audience: { label: 'Target Audience', type: FieldType.TEXT },
    use_colors: { label: 'Colors to Use', type: FieldType.TEXT },
    avoid_colors: { label: 'Colors to Avoid', type: FieldType.TEXT },
    logo_inspiration: { label: 'Logo Inspiration', type: FieldType.TEXT },
    brand_style: {
      label: 'Brand Style',
      type: FieldType.MULTISELECT,
      options: [
        'Minimal & Modern',
        'Bold & Geometric',
        'Classic & Elegant',
        'Handwritten/Script',
        'Illustrative & Detailed',
      ],
    },
    primary_color: { label: 'Primary Color', type: FieldType.COLOR_PICKER },
    secondary_color: { label: 'Secondary Color', type: FieldType.COLOR_PICKER },
    accent_color: { label: 'Accent Color', type: FieldType.COLOR_PICKER },
  },
  logoDesign: {
    deliverables: {
      label: 'Contract Deliverables',
      type: FieldType.CHECKBOX,
      options: ['Logo', 'Visual ID', 'Brand Guide'],
    },
    target_audience: { label: 'Target Audience', type: FieldType.TEXT },
    use_colors: { label: 'Colors to Use', type: FieldType.TEXT },
    avoid_colors: { label: 'Colors to Avoid', type: FieldType.TEXT },
    logo_inspiration: { label: 'Logo Inspiration', type: FieldType.TEXT },

    logo_brand_style: {
      label: 'Brand Style',
      type: FieldType.MULTISELECT,
      options: [
        'Minimal & Modern',
        'Bold & Geometric',
        'Classic & Elegant',
        'Handwritten/Script',
        'Illustrative & Detailed',
      ],
    },
    primary_color: { label: 'Primary Color', type: FieldType.COLOR_PICKER },
    secondary_color: { label: 'Secondary Color', type: FieldType.COLOR_PICKER },
    accent_color: { label: 'Accent Color', type: FieldType.COLOR_PICKER },
  },
}
export const creativeTaskTypeLabels: Record<keyof TaskTypeOptions, string> = {
  socialAd: 'Social Ads',
  displayAds: 'Display Ads',
  organicSocial: 'Organic Social',
  photography: 'Photography',
  printDesign: 'Print Design',
  website: 'Website',
  websiteDesignOverview: 'Website Design Overview',
  websiteStructure: 'Website Structure',
  video: 'Video',
  fullBrandBuild: 'Full Brand Build',
  logoDesign: 'Logo Design',
}

const requestTypeOptions = [
  'Social Ads',
  'Display Ads',
  'Organic Social',
  'Photography',
  'Print Design',
  'Website',
  'Video',
  'Full Brand Build',
  'Logo Design',
]

// Ad Options
export const socialAdOptions = ['Static (1:1 and 9:16)', 'Static Carousel (Set of 3)', 'Video (1:1 and 9:16)']
export const displayAdOptions = [
  'Standard Ad Set (160x600, 300x250, 300x600, 300x50, 728x90, 320x50)',
  'Responsive Ad Set (1200x628, 1200x1200)',
]
export const existingClientOptions = [
  { key: 'existingClient-true', label: 'True', value: true },
  { key: 'existingClient-false', label: 'False', value: false },
]

export const initialFormData: FormData = {
  name: '',
  website: '',
  assigner: {
    id: '',
    user_uuid: '',
    firstName: '',
    lastName: '',
    name: '',
    image_512: '',
    role: '',
    title: '',
  },
  assignees: [],
  AM: [],
  DMS: [],
  creativeLead: [],
  priority: '',
  startDate: '',
  endDate: '',
  deadline: '',
  existingClient: false,
  taskTypes: [],
  requestType: '',
  taskType: '',
  createdTime: '',
  addtlNotes: '',
  type: [],
  creativeType: '',
  creativeStatus: '',
}
export const initialMarketingFormData: MarketingFormData = {
  name: '',
  website: '',
  assigner: {
    id: '',
    user_uuid: '',
    firstName: '',
    lastName: '',
    name: '',
    image_512: '',
    role: '',
    title: '',
  }, // ✅ Ensure assigner matches expected structure
  assignees: [],
  creativeTasksFolderId: '',
  googleDriveFolderId: '',
  tasksFolderId: '',
  AM: [],
  DMS: [],
  priority: '',
  startDate: '',
  endDate: '',
  deadline: '',
  existingClient: false,
  taskType: '',
  requestType: '',
  createdTime: '',
  addtlNotes: '',
  type: '',
}

export interface CreativeIntakeProps {
  open: boolean
  onClose: () => void
}
export interface MarketingFormProps {
  open: boolean
  onClose: () => void
}

// Initialize fields for each request type
const getInitialFields = <T>(defaultValues: T): T => ({ ...defaultValues })

export const initialRequestFields: TaskTypeOptions = {
  socialAd: getInitialFields<SocialAdFields>({
    dmsAssignment: '',
    social_accessToCreative: '',
    socialAdTypes: [],
    sameOrNewTemplate: '',
    prevRequestLink: '',
    reasonForRequestChange: '',
    promotingDetails: '',
    clientToExclude: '',
    callToAction: '',
    vibe: '',
  }),
  displayAds: getInitialFields<DisplayAdsFields>({
    dmsAssignment: '',
    display_accessToCreative: '',
    displayAdTypes: [],
    sameOrNewTemplate: '',
    prevRequestLink: '',
    reasonForRequestChange: '',
    promotingDetails: '',
    clientToExclude: '',
    callToAction: '',
    vibe: '',
  }),
  organicSocial: getInitialFields<OrganicSocialFields>({
    promotingDetails: '',
    callToAction: '',
    vibe: '',
  }),
  photography: getInitialFields<PhotographyFields>({
    photographyType: '',
    locations_photography: '',
    photoUsage: '',
  }),
  printDesign: getInitialFields<PrintDesignFields>({
    printProjectType: '',
    print_dimensions: '',
    print_pages: '',
    print_creative_asset_link: '',
    stockImages: '',
    print_phone: '',
    print_address: '',
    print_website: '',
  }),
  website: getInitialFields<WebsiteFields>({
    websiteType: '',
    website_existing_domain: '',
    website_new_domain_input: '',
    google_analytics_access: '',
    website_new_domain: '',
    website_domain_access: '',
    website_ssl_cert: '',
    website_google_analytics: '',
    website_content: '',
    website_street: '',
    website_phone: '',
    website_city: '',
    website_state: '',
    website_bus_email: '',
    website_brand_style: '',
    website_creative_asset_link: '',
    use_stock_images: '',
    google_map_site: '',
    need_contact_form: '',
    badge_compliance_images: '',
    inspiration_links: '',
    hero_image_headline: '',
    hero_image_cta: '',
    website_pages: 0,
    main_nav_links: '',
    main_nav_cta: '',
    footer_nav_links: '',
    website_addtl_info: '',
  }),
  video: getInitialFields<VideoFields>({
    videoType: '',
    goal_of_video: '',
    video_target_audience: '',
    video_projected_hours: 0,
    video_locations: '',
    video_personell: '',
    req_text_logo: '',
    specific_shots: '',
    output_channels: '',
    video_sizes: '',
    video_min_duration: '',
    video_max_duration: '',
  }),
  fullBrandBuild: getInitialFields<BrandDesignFields>({
    brand_deliverables: '',
    brand_target_audience: '',
    brand_use_colors: '',
    brand_avoid_colors: '',
    brand_style: '',
    brand_logo_inspiration: '',
    brand_color_picker1: '',
    brand_color_picker2: '',
    brand_color_picker3: '',
  }),
  logoDesign: getInitialFields<LogoDesignFields>({
    logo_deliverables: '',
    logo_target_audience: '',
    logo_use_colors: '',
    logo_avoid_colors: '',
    logo_brand_style: '',
    logo_inspiration: '',
    logo_color_picker1: '',
    logo_color_picker2: '',
    logo_color_picker3: '',
  }),
}
////////////////// Marketing field type definitions and mappings /////////////////////////
// export interface ConversionTrackingFields {
//   defaultField1: string
//   defaultField2: string
// }
export interface CampaignUpdateFields {
  defaultField1: string
  defaultField2: string
}
export interface UpdateFields {
  channel: string[]
  platform: string[]
}
export interface BudgetFields {
  channel: string[]
  platform: string[]
}
export interface CreationFields {
  channel: string[]
  platform: string[]
}
export interface ReviewFields {
  channel: string[]
  platform: string[]
}
export interface SeoFields {
  channel: string[]
  platform: string[]
}
export const MarketingFormConfig: Record<keyof TaskTypeOptions, Record<string, FieldConfig>> = {
  budget: {
    channel: {
      label: 'Channel',
      type: FieldType.MULTISELECT,
      options: ['Search', 'Social', 'Display', 'Video', 'SEO', 'Integration'],
    },
  },
  createSetup: {
    channel: {
      label: 'Channel',
      type: FieldType.MULTISELECT,
      options: ['Search', 'Social', 'Display', 'Video', 'SEO', 'Integration'],
    },
  },
  updates: {
    channel: {
      label: 'Channel',
      type: FieldType.MULTISELECT,
      options: ['Search', 'Social', 'Display', 'Video', 'SEO', 'Integration'],
    },
  },
  // conversionTracking: {
  //   defaultField1: {
  //     label: 'Field Label 1',
  //     type: FieldType.TEXT,
  //     placeholder: 'Enter text here...',
  //   },
  //   defaultField2: {
  //     label: 'Field Label 2',
  //     type: FieldType.COLOR_PICKER,
  //     placeholder: 'Pick a color',
  //   },
  // },
  reviewAudit: {
    channel: {
      label: 'Channel',
      type: FieldType.MULTISELECT,
      options: ['Search', 'Social', 'Display', 'Video', 'SEO', 'Integration'],
    },

    // platform: {
    //   label: 'Platform',
    //   type: FieldType.MULTISELECT,
    //   options: ['TikTok', 'LinkedIn', 'Snapchat', 'Meta', 'Other'],
    // },
  },
}

export const taskTypeLabels: Record<keyof TaskTypeOptions, string> = {
  updates: 'Updates',
  // conversionTracking: 'Conversion Tracking',
  reviewAudit: 'Review / Audit',
  budget: 'Budget',
  createSetup: 'Create / Setup',
}

export const reviewChannelFields: Record<string, Record<string, FieldConfig>> = {
  Search: {
    searchPlatform: {
      label: 'Search Platform',
      type: FieldType.MULTISELECT,
      options: ['Microsoft', 'Google'],
    },
    searchReview: {
      label: 'What to review',
      type: FieldType.MULTISELECT,
      options: ['Performance', 'Ad Copy', 'Website', 'Keywords', 'Search Terms', 'Campaign Settings', 'Other'],
    },
  },
  Social: {
    socialPlatform: {
      label: 'Social Platform',
      type: FieldType.MULTISELECT,
      options: ['Meta', 'Instagram', 'Snapchat', 'LinkedIn', 'TikTok'],
    },
    socialReview: {
      label: 'What to review',
      type: FieldType.MULTISELECT,
      options: [
        'Performance',
        'Ad Copy',
        'Website',
        'Audience',
        'Targeting',
        'Campaign Settings',
        'Placements',
        'Other',
      ],
    },
  },
  Display: {
    displayPlatform: {
      label: 'Display Platform',
      type: FieldType.MULTISELECT,
      options: ['Google', 'Simplifi'],
    },
    displayReview: {
      label: 'What to review',
      type: FieldType.MULTISELECT,
      options: ['Performance', 'Audience', 'Targeting', 'Campaign Settings', 'Placements', 'Other'],
    },
  },
  Video: {
    videoPlatform: {
      label: 'Video Platform',
      type: FieldType.MULTISELECT,
      options: ['YouTube', 'TikTok', 'Simplifi'],
    },
    videoReview: {
      label: 'What to review',
      type: FieldType.MULTISELECT,
      options: ['Performance', 'Audience', 'Targeting', 'Campaign Settings', 'Placements', 'Other'],
    },
  },
  SEO: {
    // seoSpecifics: {
    //   label: 'SEO Specifics',
    //   type: FieldType.MULTISELECT,
    //   options: [
    //     'SEO Tier Upgrade ',
    //     'SEO Tier Downgrade',
    //     'SEO Audit',
    //     'SEO Inquiry',
    //     'Google Business Profile ',
    //     'Competitor Analysis Audit',
    //     'Keyword Analysis Audit',
    //     'SEO Client Offboard',
    //   ],
    // },
    seoReview: {
      label: 'What to review',
      type: FieldType.MULTISELECT,
      options: ['GMB', 'SE Ranking', 'Vendasta', 'Website', 'Google', 'Keywords', 'Competitor Analysis'],
    },
  },
  Integration: {
    // integrationOptions: {
    //   label: 'Integration Options',
    //   type: FieldType.MULTISELECT,
    //   options: [
    //     'Form Fill',
    //     'Review / Audit',
    //     'SEO Inquiry',
    //     'Google Business Profile ',
    //     'Competitor Analysis Audit',
    //     'Keyword Analysis Audit',
    //     'SEO Client Offboard',
    //   ],
    // },
    integrationReview: {
      label: 'What to review',
      type: FieldType.MULTISELECT,
      options: ['Performance', 'Audience', 'Targeting', 'Campaign Settings', 'Placements', 'Other'],
    },
  },
}
export const updateChannelFields: Record<string, Record<string, FieldConfig>> = {
  Search: {
    searchPlatform: {
      label: 'Search Platform',
      type: FieldType.MULTISELECT,
      options: ['Microsoft', 'Google'],
    },
    searchUpdate: {
      label: 'What to update',
      type: FieldType.MULTISELECT,
      options: ['Performance', 'Ad Copy', 'Website', 'Keywords', 'Search Terms', 'Campaign Settings', 'Other'],
    },
  },
  Social: {
    socialPlatform: {
      label: 'Social Platform',
      type: FieldType.MULTISELECT,
      options: ['Meta', 'Instagram', 'Snapchat', 'LinkedIn', 'TikTok'],
    },
    socialUpdate: {
      label: 'What to update',
      type: FieldType.MULTISELECT,
      options: [
        'Performance',
        'Ad Copy',
        'Website',
        'Audience',
        'Targeting',
        'Campaign Settings',
        'Placements',
        'Other',
      ],
    },
  },
  Display: {
    displayPlatform: {
      label: 'Display Platform',
      type: FieldType.MULTISELECT,
      options: ['Google', 'Simplifi'],
    },
    displayUpdate: {
      label: 'What to update',
      type: FieldType.MULTISELECT,
      options: ['Performance', 'Audience', 'Targeting', 'Campaign Settings', 'Placements', 'Other'],
    },
  },
  Video: {
    videoPlatform: {
      label: 'Video Platform',
      type: FieldType.MULTISELECT,
      options: ['YouTube', 'TikTok', 'Simplifi'],
    },
    videoUpdate: {
      label: 'What to update',
      type: FieldType.MULTISELECT,
      options: ['Performance', 'Audience', 'Targeting', 'Campaign Settings', 'Placements', 'Other'],
    },
  },
  SEO: {
    seoSpecifics: {
      label: 'SEO Specifics',
      type: FieldType.MULTISELECT,
      options: [
        'SEO Tier Upgrade ',
        'SEO Tier Downgrade',
        'SEO Audit',
        'SEO Inquiry',
        'Google Business Profile ',
        'Competitor Analysis Audit',
        'Keyword Analysis Audit',
        'SEO Client Offboard',
      ],
    },
    seoUpdate: {
      label: 'What to update',
      type: FieldType.MULTISELECT,
      options: ['Performance', 'Audience', 'Targeting', 'Campaign Settings', 'Placements', 'Other'],
    },
  },
  Integration: {
    integrationOptions: {
      label: 'Integration Options',
      type: FieldType.MULTISELECT,
      options: [
        'Form Fill',
        'Review / Audit',
        'SEO Inquiry',
        'Google Business Profile ',
        'Competitor Analysis Audit',
        'Keyword Analysis Audit',
        'SEO Client Offboard',
      ],
    },
    integrationUpdate: {
      label: 'What to update',
      type: FieldType.MULTISELECT,
      options: ['Performance', 'Audience', 'Targeting', 'Campaign Settings', 'Placements', 'Other'],
    },
  },
}
export const budgetChannelFields: Record<string, Record<string, FieldConfig>> = {
  Search: {
    searchPlatform: {
      label: 'Search Platform',
      type: FieldType.MULTISELECT,
      options: ['Microsoft', 'Google'],
    },
    searchBudget: {
      label: 'What to budget',
      type: FieldType.MULTISELECT,
      options: ['Performance', 'Ad Copy', 'Website', 'Keywords', 'Search Terms', 'Campaign Settings', 'Other'],
    },
  },
  Social: {
    socialPlatform: {
      label: 'Social Platform',
      type: FieldType.MULTISELECT,
      options: ['Meta', 'Instagram', 'Snapchat', 'LinkedIn', 'TikTok'],
    },
    socialBudget: {
      label: 'What to budget',
      type: FieldType.MULTISELECT,
      options: [
        'Performance',
        'Ad Copy',
        'Website',
        'Audience',
        'Targeting',
        'Campaign Settings',
        'Placements',
        'Other',
      ],
    },
  },
  Display: {
    displayPlatform: {
      label: 'Display Platform',
      type: FieldType.MULTISELECT,
      options: ['Google', 'Simplifi'],
    },
    displayBudget: {
      label: 'What to budget',
      type: FieldType.MULTISELECT,
      options: ['Performance', 'Audience', 'Targeting', 'Campaign Settings', 'Placements', 'Other'],
    },
  },
  Video: {
    videoPlatform: {
      label: 'Video Platform',
      type: FieldType.MULTISELECT,
      options: ['YouTube', 'TikTok', 'Simplifi'],
    },
    videoBudget: {
      label: 'What to budget',
      type: FieldType.MULTISELECT,
      options: ['Performance', 'Audience', 'Targeting', 'Campaign Settings', 'Placements', 'Other'],
    },
  },
  SEO: {
    seoSpecifics: {
      label: 'SEO Specifics',
      type: FieldType.MULTISELECT,
      options: [
        'SEO Tier Upgrade ',
        'SEO Tier Downgrade',
        'SEO Audit',
        'SEO Inquiry',
        'Google Business Profile ',
        'Competitor Analysis Audit',
        'Keyword Analysis Audit',
        'SEO Client Offboard',
      ],
    },
    seoBudget: {
      label: 'What to budget',
      type: FieldType.MULTISELECT,
      options: ['Vendasta', 'SE Ranking'],
    },
  },
  Integration: {
    integrationOptions: {
      label: 'Integration Options',
      type: FieldType.MULTISELECT,
      options: [
        'Form Fill',
        'Review / Audit',
        'SEO Inquiry',
        'Google Business Profile ',
        'Competitor Analysis Audit',
        'Keyword Analysis Audit',
        'SEO Client Offboard',
      ],
    },
    integrationBudget: {
      label: 'What to budget',
      type: FieldType.MULTISELECT,
      options: ['Performance', 'Audience', 'Targeting', 'Campaign Settings', 'Placements', 'Other'],
    },
  },
}
export const createSetupChannelFields: Record<string, Record<string, FieldConfig>> = {
  Search: {
    searchPlatform: {
      label: 'Search Platform',
      type: FieldType.MULTISELECT,
      options: ['Microsoft', 'Google'],
    },
    searchCreate: {
      label: 'What to create / setup',
      type: FieldType.MULTISELECT,
      options: ['Performance', 'Ad Copy', 'Website', 'Keywords', 'Search Terms', 'Campaign Settings', 'Other'],
    },
  },
  Social: {
    socialPlatform: {
      label: 'Social Platform',
      type: FieldType.MULTISELECT,
      options: ['Meta', 'Instagram', 'Snapchat', 'LinkedIn', 'TikTok'],
    },
    socialCreate: {
      label: 'What to create / setup',
      type: FieldType.MULTISELECT,
      options: [
        'Performance',
        'Ad Copy',
        'Website',
        'Audience',
        'Targeting',
        'Campaign Settings',
        'Placements',
        'Other',
      ],
    },
  },
  Display: {
    displayPlatform: {
      label: 'Display Platform',
      type: FieldType.MULTISELECT,
      options: ['Google', 'Simplifi'],
    },
    displayCreate: {
      label: 'What to create / setup',
      type: FieldType.MULTISELECT,
      options: ['Performance', 'Audience', 'Targeting', 'Campaign Settings', 'Placements', 'Other'],
    },
  },
  Video: {
    videoPlatform: {
      label: 'Video Platform',
      type: FieldType.MULTISELECT,
      options: ['YouTube', 'TikTok', 'Simplifi'],
    },
    videoCreate: {
      label: 'What to create / setup',
      type: FieldType.MULTISELECT,
      options: ['Performance', 'Audience', 'Targeting', 'Campaign Settings', 'Placements', 'Other'],
    },
  },
  SEO: {
    seoSpecifics: {
      label: 'SEO Specifics',
      type: FieldType.MULTISELECT,
      options: [
        'SEO Tier Upgrade ',
        'SEO Tier Downgrade',
        'SEO Audit',
        'SEO Inquiry',
        'Google Business Profile ',
        'Competitor Analysis Audit',
        'Keyword Analysis Audit',
        'SEO Client Offboard',
      ],
    },
    seoCreate: {
      label: 'What to create / setup',
      type: FieldType.MULTISELECT,
      options: ['Performance', 'Audience', 'Targeting', 'Campaign Settings', 'Placements', 'Other'],
    },
  },
  Integration: {
    integrationOptions: {
      label: 'Integration Options',
      type: FieldType.MULTISELECT,
      options: [
        'Form Fill',
        'Review / Audit',
        'SEO Inquiry',
        'Google Business Profile ',
        'Competitor Analysis Audit',
        'Keyword Analysis Audit',
        'SEO Client Offboard',
      ],
    },
    integrationCreate: {
      label: 'What to create / setup',
      type: FieldType.MULTISELECT,
      options: ['Performance', 'Audience', 'Targeting', 'Campaign Settings', 'Placements', 'Other'],
    },
  },
}
