import React, { useState } from 'react'
import {
  Box,
  Button,
  Typography,
  IconButton,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from '@mui/material'
import AddIcon from '@mui/icons-material/Add'
import RemoveIcon from '@mui/icons-material/Remove'
import { useBatchPostChecklists } from '../../../api/aws/checklists/useBatchPostChecklists.ts'
import { createCustomChecklist, createArrayList } from '../../../api/aws/checklists/lists.ts'
import UserImageComponent from '../../Users/UserImageComponent.js'

const CustomChecklistModal = ({ open, onClose, deal, checklistTitle, assignedUser, context }) => {
  console.log('[CustomChecklistModal] assignedUser', assignedUser)
  const { mutate: postChecklists } = useBatchPostChecklists()
  const [tasks, setTasks] = useState(['']) // Start with one empty task

  /** ✅ Add a new input field */
  const addTaskField = () => {
    setTasks([...tasks, ''])
  }

  /** ✅ Remove a specific input field */
  const removeTaskField = index => {
    setTasks(tasks.filter((_, i) => i !== index))
  }

  /** ✅ Handle input changes */
  const handleTaskChange = (index, value) => {
    const newTasks = [...tasks]
    newTasks[index] = value
    setTasks(newTasks)
  }

  /** 🔹 Generate and Save Checklist */
  const handleCreateChecklist = () => {
    const taskList = tasks.filter(task => task.trim() !== '') // Remove empty values
    if (!taskList.length) return

    const formattedTasks = createArrayList(taskList, `${context}Progress`)
    const newChecklist = createCustomChecklist(
      { deal_uuid: deal?.deal_uuid },
      formattedTasks,
      checklistTitle,
      assignedUser,
      context
    )

    postChecklists([newChecklist], {
      onSuccess: () => {
        console.log('✅ Checklist created successfully:', newChecklist)
      },
      onError: error => {
        console.error('❌ Failed to create checklist:', error)
      },
    })

    onClose() // Close modal after submission
  }

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth='sm'>
      <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', px: 2 }}>
        <DialogTitle>{checklistTitle ?? 'Custom Checklist'}</DialogTitle>
        <UserImageComponent imageUrl={assignedUser?.[0]?.image_512} />
      </Box>
      <DialogContent>
        {tasks.map((task, index) => (
          <Box key={index} sx={{ display: 'flex', alignItems: 'center', gap: 1, pt: 2 }}>
            <TextField
              fullWidth
              variant='outlined'
              label={`Task ${index + 1}`}
              value={task}
              onChange={e => handleTaskChange(index, e.target.value)}
            />
            {tasks.length > 1 && (
              <IconButton onClick={() => removeTaskField(index)} color='error'>
                <RemoveIcon />
              </IconButton>
            )}
          </Box>
        ))}
        <Button onClick={addTaskField} startIcon={<AddIcon />} sx={{ mt: 1 }}>
          Add Another Task
        </Button>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button onClick={handleCreateChecklist} variant='contained'>
          Create
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default CustomChecklistModal
