import React, { useState, useEffect, useMemo } from 'react'
import { Box, Typography } from '@mui/material'
import { DataGridPro, useGridApiRef } from '@mui/x-data-grid-pro'
import { useTheme } from '@mui/material'
import { useSnackbarContext } from '../../context/SnackbarProvider/SnackbarProvider'
import { useDeleteMutation } from '../../api/aws/useDeleteMutation'
import { renderAccountingColumns } from '../DataGrid/createColumns/renderAccountingColumns'
import { filterPanelProps, gridContainerStyles } from './style'
import { useOrganizations } from '../../api/aws/useOrganizations'
import { useUsers } from '../../api/aws/useUsers'
import { DealDetailsProvider, useDealDetailsContext } from '../../context/DealsDetailsProvider/DealsDetailsProvider'
import CustomAccountingGridToolbar from './CustomAccountingGridToolbar'
import AccountingDrawer from './AccountingDrawer'
import { useUpdateDeal } from '../../api/aws/useUpdateDeal'
import { AccountingGridFooter } from './AccountingGridFooter'
import { useUpdateItems } from '../../api/aws/useUpdateItems'
import { useUpdateMassItems } from '../../api/aws/useUpdateMassItems'
import LoadingOverlay from '../Loading/LoadingOverlay'
import RenderRowCount from '../DataGrid/RenderRowCount'
import { useContacts } from '../../api/aws/useContacts'
import { debounce } from 'lodash'
import { useDeals } from '../../api/aws/useDeals'
import { invoiceViewState, churnViewState, billingViewState } from './viewStates'
import { useQueryClient } from '@tanstack/react-query'
import { useLayoutContext } from '../../context/LayoutProvider/LayoutProvider'
import { useNavigate, Outlet, useParams } from 'react-router-dom'

const initialSortModel = [
  { field: 'lastModified', sort: 'desc' },
  { field: 'name', sort: 'asc' },
  { field: 'organizations', sort: 'asc' },
  { field: 'status', sort: 'asc' },
]

const AccountingPage = () => {
  const { pinned } = useLayoutContext()
  const { dealId } = useParams()
  const navigate = useNavigate()
  const { deals: cachedDeals, isLoading: cachedDealsLoading } = useDeals(true, ['accounting', 'deals'])
  const { contacts, isContactsLoading, isContactsError } = useContacts(true, ['accounting', 'contacts'])
  const {
    data: organizations,
    isLoading: isOrganizationsLoading,
    isError: isOrganizationsError,
  } = useOrganizations(true, ['accounting', 'organizations'])
  ///////////////////////////////////////////////////////////
  ////////////////////////Debugging/////////////////////////
  // const findMissingValues = organizations => {
  //   organizations.forEach((org, index) => {
  //     Object.keys(org).forEach(key => {
  //       if (org[key] === null || org[key] === undefined) {
  //         console.log(`❌ Missing Value in Row ${index + 1} → Key: "${key}" | Value:`, org[key])
  //       }
  //     })
  //   })
  // }
  // useEffect(() => {
  //   if (organizations) {
  //     findMissingValues(organizations)
  //   }
  // }, [organizations])
  // Example Usage
  ///////////////////////////////////////////////////////////
  ///////////////////////////////////////////////////////////

  // const cachedDeals = useQueryClient().getQueryData(['deals'])
  // const { data: organizations } = useQueryClient().getQueryData(['organizations'])
  // const contacts = useQueryClient().getQueryData(['contacts'])
  const { users, isUsersLoading, isUsersError } = useUsers(true, ['accounting', 'users'])
  const { resetState, drawerOpen, handleDrawerOpen, handleDrawerClose, findContactById } = useDealDetailsContext()
  const [selectedDeal, setSelectedDeal] = useState(null)
  const [selectionModel, setSelectionModel] = useState([])
  const { mutate: updateItems } = useUpdateItems()
  const { mutate: updateMassItems } = useUpdateMassItems(['deals'])
  const [densityModel, setDensityModel] = useState(() => {
    const savedDensity = localStorage.getItem('accountingDensityModel')
    return savedDensity ?? 'standard'
  })
  const [density, setDensity] = useState(() => {
    const savedDensity = localStorage.getItem('accountingDensityModel')
    return savedDensity ?? 'standard'
  })
  const [selectedOrganization, setSelectedOrganization] = useState()
  const { mutate: updateDeal } = useUpdateDeal()
  const [isUpdating, setIsUpdating] = useState(false)
  const { showSnackbar } = useSnackbarContext()
  const theme = useTheme()
  const filterMenuBorder =
    theme.palette.mode === 'dark' ? '1px solid rgba(255,255,255,0.5)' : '1px solid rgba(0,0,0,0.5)'
  const [isDeleting, setIsDeleting] = useState(false)
  const [filterModel, setFilterModel] = useState(() => {
    const savedFilters = localStorage.getItem('accountingFilterModel')
    return savedFilters ? JSON.parse(savedFilters) : { items: [], quickFilterValues: [] }
  })
  const [columnVisibilityModel, setColumnVisibilityModel] = useState(() => {
    const savedVisibility = localStorage.getItem('accountingColumnVisibilityModel')
    return savedVisibility
      ? JSON.parse(savedVisibility)
      : {
          ...invoiceViewState,
        }
  })
  const [sortModel, setSortModel] = useState(() => {
    const savedSortModel = localStorage.getItem('accountingSortModel')
    return savedSortModel ? JSON.parse(savedSortModel) : initialSortModel
  })
  const [isContractDialogOpen, setIsContractDialogOpen] = useState(false)
  const { mutate: deleteItem, isLoading } = useDeleteMutation()
  const [updateData, setUpdateData] = useState({})
  const [massUpdateData, setMassUpdateData] = useState({})
  const [anchorEl, setAnchorEl] = useState(null)
  const [deals, setDeals] = useState([])
  const apiRef = useGridApiRef()
  const [visibleRowCount, setVisibleRowCount] = useState(deals ? deals.length : 0)
  const [rowCount, setRowCount] = useState(deals ? deals.length : 0)
  const [selectedView, setSelectedView] = useState(() => {
    const savedView = localStorage.getItem('accountingSelectedView')
    return savedView ? JSON.parse(savedView) : 'invoice'
  })
  // const { data: billingContact, isLoading: billingContactLoading, isError: billingContactError } = useContact(
  //   organization && Array.isArray(organization.billingContact) ? organization.billingContact[0] : null
  // )

  useEffect(() => {
    if (filterModel) {
      // Save the filter model to localStorage when it changes
      localStorage.setItem('accountingFilterModel', JSON.stringify(filterModel))
    }
  }, [filterModel])
  useEffect(() => {
    if (massUpdateData) {
      // Save the filter model to localStorage when it changes
      console.log('massUpdateData', massUpdateData)
    }
  }, [massUpdateData])
  useEffect(() => {
    if (density) {
      // Save the filter model to localStorage when it changes.0
      localStorage.setItem('accountingDensityModel', density)
    }
  }, [density])
  const handleDeleteClick = event => {
    setAnchorEl(event.currentTarget)
  }
  useEffect(() => {
    if (selectionModel.length === 1) {
      const newDeal = deals.find(deal => deal.deal_uuid === selectionModel[0])
      setSelectedOrganization(newDeal)
      setMassUpdateData({})
    } else {
      setSelectedOrganization(null)
    }
  }, [selectionModel, deals])
  // Memoized processed deals to avoid unnecessary recomputations
  const processedDeals = useMemo(() => {
    if (!cachedDeals || !organizations) return []
    return cachedDeals
      .map(deal => {
        const organization = organizations.find(
          org => org.id === (Array.isArray(deal.organizations) ? deal.organizations[0] : null)
        )
        const contractedById =
          organization && Array.isArray(organization.contractedBy) ? organization.contractedBy[0] : null
        const primaryContact = contacts?.find(contact => contact.contacts_uuid === organization?.primaryContact?.[0])
        const billingContact = contacts?.find(contact => contact.contacts_uuid === organization?.billingContact?.[0])
        return {
          ...deal,
          contractedBy: organization?.contractedBy?.[0] || null,
          amId: users.find(user => user?.user_uuid === organization?.AM?.[0]),
          contractedBy: [contractedById],
          primaryContact: primaryContact ?? null,
          billingContact: billingContact ?? null,
        }
      })
      .filter(deal => deal.stage === 'Deal Won')
  }, [cachedDeals, organizations, users, contacts])

  useEffect(() => {
    if (processedDeals && !isContactsLoading && !isUsersLoading) {
      // if (processedDeals && !isUsersLoading) {
      setDeals(processedDeals)
      setRowCount(processedDeals?.length)
    }
  }, [processedDeals, rowCount])
  const handleDealClick = deal => {
    // console.log('Deal Clicked! ', deal)
    setSelectedDeal(deal)
    handleDrawerOpen()
    navigate(`/accounting/${deal?.deal_uuid}/details`)
  }
  const handleClose = () => {
    handleDrawerClose()
    setSelectedDeal(null)
    navigate(`/accounting`)
  }
  const columns = useMemo(() => {
    return renderAccountingColumns(organizations, users, deals, density, handleDealClick, theme)
  }, [organizations, users, deals, density, handleDealClick])
  useEffect(() => {
    if (selectedDeal) {
      const selectedOrgId =
        selectedDeal.organizations && Array.isArray(selectedDeal.organizations) ? selectedDeal.organizations[0] : []
      setSelectedOrganization(organizations.find(org => org.id === selectedOrgId))
    }
  }, [selectedDeal, setSelectedOrganization])

  // Cleanup function for when deals component unmounts
  useEffect(() => {
    return () => {
      setSelectionModel([])
      setSelectedDeal(null)
    }
  }, [])

  const handleConfirmDelete = async () => {
    setIsDeleting(true)
    if (selectedDeal) {
      const params = {
        endpoint: `/aws/delete/deals/${selectedDeal.id}`,
        table: 'deals',
      }
      deleteItem(params, {
        onSuccess: message => {
          setSelectedDeal(null)
          showSnackbar(message, 'success')
          setIsDeleting(false)
        },
        onError: error => {
          showSnackbar(error.message, 'error')
          setIsDeleting(false)
        },
      })
    }
  }

  const handleClosePopover = () => {
    setAnchorEl(null)
    if (selectionModel.length > 0) {
      selectionModel.forEach(id => {
        apiRef.current.selectRow(id, false)
      })
      setSelectionModel([])
      setSelectedDeal(null)
    }
  }

  const handleCloseSnackbar = () => {
    if (selectionModel.length > 0) {
      selectionModel.forEach(id => {
        apiRef.current.selectRow(id, false)
      })
      setSelectionModel([])
      setSelectedDeal(null)
      handleClosePopover()
    }
  }

  const handleCloneDeal = () => {
    console.log('This icon will be used to clone the selected deal.')
  }

  const handleEditClick = () => {
    if (selectedDeal) {
      setIsContractDialogOpen(true)
    }
  }

  const open = Boolean(anchorEl)
  const id = open ? 'simple-popover' : undefined

  const handleMassUpdateChange = e => {
    const { name, value } = e.target
    setMassUpdateData(prev => ({ ...prev, [name]: value }))
  }

  const handleColumnResizeStop = () => {
    if (apiRef.current) {
      apiRef.current.updateColumns()
    }
  }

  // useEffect(() => {
  //   const handleFilterModelChange = () => {
  //     const filteredRowsLookup = apiRef.current.state.filter.filteredRowsLookup
  //     const filteredRowsCount = Object.values(filteredRowsLookup).filter(isVisible => isVisible).length
  //     setVisibleRowCount(filteredRowsCount)
  //   }
  //   const api = apiRef.current
  //   const unsubscribe = api.subscribeEvent('filterModelChange', handleFilterModelChange)

  //   // Initialize the visible row count when the component mounts
  //   handleFilterModelChange()

  //   return () => {
  //     unsubscribe()
  //   }
  // }, [apiRef])
  const computeVisibleRows = () => {
    if (apiRef.current) {
      const filteredRowsLookup = apiRef.current.state.filter.filteredRowsLookup
      const allRows = apiRef.current.getAllRowIds()
      const rows = allRows.filter(rowId => filteredRowsLookup[rowId])
      return rows
    }
    return []
  }

  const handleFilterModelChange = () => {
    const rows = computeVisibleRows()
    setRowCount(rows)
    setVisibleRowCount(rows.length)
  }

  // Debounce the filter model change handler for better performance
  const debouncedFilterModelChange = useMemo(() => debounce(handleFilterModelChange, 300), [])

  useEffect(() => {
    const api = apiRef.current
    if (!api) return

    const unsubscribe = api.subscribeEvent('filterModelChange', debouncedFilterModelChange)

    // Initialize visible rows and count on mount
    handleFilterModelChange()

    return () => {
      unsubscribe()
      debouncedFilterModelChange.cancel() // Cleanup debounce
    }
  }, [apiRef, debouncedFilterModelChange])

  const totalRowCount = deals ? deals.length : 0
  const handleDensityChange = newDensity => {
    setDensity(newDensity)
    localStorage.setItem('accountingDensityModel', newDensity)
  }

  const handleClearSorting = e => {
    e.preventDefault()
    setSortModel([...initialSortModel]) // Reset the sort model
    localStorage.setItem('accountingSortModel', JSON.stringify(initialSortModel)) // Also update localStorage
  }
  const handleClearVisibility = e => {
    e.preventDefault()
    const initialVisibilityState = {
      ...invoiceViewState,
    }
    setColumnVisibilityModel(initialVisibilityState)
    localStorage.setItem('accountingColumnVisibilityModel', JSON.stringify(initialVisibilityState))
  }

  ////////////////// handleUpdate ////////////////////////
  const handleUpdate = async (dealId, updatedRow, updatedData) => {
    try {
      // Call the mutation with the deal ID and the updated data
      updateDeal(
        { dealId, dealData: updatedData },
        {
          onSuccess: () => {
            showSnackbar(
              <Box sx={{ display: 'flex', alignItems: 'center' }} gap={1}>
                <Typography sx={{ color: theme.palette.text.primary, fontWeight: 'bold' }}>
                  {updatedRow?.name || ''}
                </Typography>
                <Typography>Updated successfully</Typography>
              </Box>,
              'success'
            )
            console.log('Successfully updated deal')
          },
          onError: error => {
            console.error('Error updating deal', error)
          },
        }
      )

      // Optionally show a success message or perform other actions
      console.log(`Deal ${dealId} updated successfully with`, updatedData)
    } catch (error) {
      // Handle errors (e.g., show an error message)
      console.error(`Failed to update deal ${dealId}:`, error)
    }
  }
  const handleMassUpdate = () => {
    setIsUpdating(true)
    const updates = []

    if (massUpdateData.invoiceStatus) {
      selectionModel.forEach(id => {
        updates.push({
          primaryKey: 'deal_uuid',
          tableName: 'deals',
          itemId: id,
          fieldName: 'invoiceStatus',
          newValue: massUpdateData.invoiceStatus,
        })
      })
    }
    if (massUpdateData.billingStatus) {
      selectionModel.forEach(id => {
        updates.push({
          primaryKey: 'deal_uuid',
          tableName: 'deals',
          itemId: id,
          fieldName: 'billingStatus',
          newValue: massUpdateData.billingStatus,
        })
      })
    }

    if (selectionModel.length === 1) {
      updateItems(
        { updates: updates }, // Pass only the first update object
        {
          onSuccess: () => {
            setIsUpdating(false)
            handleClosePopover()
            showSnackbar('Deal updated successfully', 'success')
          },
          onError: () => {
            setIsUpdating(false)
            showSnackbar('Deal update failed', 'error')
          },
        }
      )
    } else {
      // Else use batch update logic
      updateMassItems(
        { updates },
        {
          onSuccess: () => {
            setIsUpdating(false)
            handleClosePopover()
            showSnackbar(`${selectionModel ? selectionModel.length : ''} Deals updated successfully`, 'success')
          },
          onError: () => {
            setIsUpdating(false)
            showSnackbar('Deals update failed', 'error')
          },
        }
      )
    }
  }
  const handleChangeView = newView => {
    if (newView === 'invoice') {
      console.log('VIEW: invoice')
      const newInvoiceFilters = [
        { id: `Active-${Date.now()}`, field: 'dealStatus', operator: 'contains', value: 'Active' },
        { id: `MonthToMonth-${Date.now()}`, field: 'dealStatus', operator: 'contains', value: 'Month To Month' },
        { id: `30DayNotice-${Date.now()}`, field: 'dealStatus', operator: 'contains', value: '30 Day Notice' },
        { id: `InProgress-${Date.now()}`, field: 'dealStatus', operator: 'contains', value: 'In Progress' },
      ]
      // Set the new filter model replacing any previous filters
      setFilterModel({ logicOperator: 'or', items: newInvoiceFilters })

      setColumnVisibilityModel(invoiceViewState)
      localStorage.setItem('accountingColumnVisibilityModel', JSON.stringify(invoiceViewState))
    } else if (newView === 'billing') {
      console.log('VIEW: billing')
      // For billing, clear filters or set billing-specific ones
      setFilterModel({ items: [], quickFilterValues: [] })
      setColumnVisibilityModel(billingViewState)
      localStorage.setItem('accountingColumnVisibilityModel', JSON.stringify(billingViewState))
    } else if (newView === 'churn') {
      console.log('VIEW: Churned')
      const newChurnFilters = [
        { id: `Cancelled-${Date.now()}`, field: 'dealStatus', operator: 'contains', value: 'Cancelled' },
        { id: `30DayNotice-${Date.now()}`, field: 'dealStatus', operator: 'contains', value: '30 Day Notice' },
        { id: `Churned-${Date.now()}`, field: 'dealStatus', operator: 'contains', value: 'Churned' },
        { id: `Ended-${Date.now()}`, field: 'dealStatus', operator: 'contains', value: 'Ended' },
      ]
      // Replace existing filters with churn-specific filters
      setFilterModel({ logicOperator: 'or', items: newChurnFilters })
      setColumnVisibilityModel(churnViewState)
      localStorage.setItem('accountingColumnVisibilityModel', JSON.stringify(churnViewState))
    }
  }

  ///////////////////////////////////////////////////////////////////
  ///////////////////////////////////////////////////////////////////
  ///////////////////////////////////////////////////////////////////

  return (
    <>
      <Box
        sx={{
          position: 'relative',
          ...gridContainerStyles,
          pt: pinned ? '60px' : 0.8,
          transition: 'all 0.3s ease', // or .5s, etc.
          height: '98%',
          // height: pinned ? 'calc(100vh - 80px)' : 'calc(100vh - 20px)',
        }}
      >
        <DataGridPro
          initialState={{
            sorting: {
              sortModel: [
                { field: 'lastModified', sort: 'desc' },
                { field: 'name', sort: 'asc' },
                { field: 'organizations', sort: 'asc' },
                { field: 'status', sort: 'asc' },
              ],
            },
            filter: {
              filterModel: filterModel,
            },
            columns: {
              columnVisibilityModel: {
                ...invoiceViewState,
              },
            },
          }}
          rows={deals}
          columns={columns}
          processRowUpdate={async (updatedRow, oldRow) => {
            if (updatedRow.invoiceStatus !== oldRow.invoiceStatus) {
              // Call the centralized update function
              await handleUpdate(updatedRow.id, updatedRow, { invoiceStatus: updatedRow.invoiceStatus })
            } else if (updatedRow.billingStatus !== oldRow.billingStatus) {
              await handleUpdate(updatedRow.id, updatedRow, { billingStatus: updatedRow.billingStatus })
            }

            // Optimistically update the state
            const updatedDeals = deals.map(row =>
              row.id === updatedRow.id
                ? { ...row, invoiceStatus: updatedRow.invoiceStatus, billingStatus: updatedRow.billingStatus }
                : row
            )
            setDeals(updatedDeals)

            return updatedRow // Return the updated row for the grid
          }}
          onProcessRowUpdateError={error => {
            console.error('Error updating row:', error)
          }}
          isCellEditable={params => {
            // Check if the column is `invoiceStatus`
            if (params.field === 'invoiceStatus') {
              // Allow editing only if one or no rows are selected
              return selectionModel.length <= 1
            }
            if (params.field === 'billingStatus') {
              return selectionModel.length <= 1
            }
            return true // Default for other columns
          }}
          rowHeight={density === 'compact' ? 40 : density === 'comfortable' ? 60 : 52}
          getRowId={row => row.deal_uuid || row.id || row.deal.id}
          checkboxSelection
          disableRowSelectionOnClick
          selectionModel={selectionModel}
          // onRowSelectionModelChange={newSelectionModel => handleSelectionModelChange(newSelectionModel)}
          onRowSelectionModelChange={newSelectionModel => setSelectionModel(newSelectionModel)}
          columnVisibilityModel={columnVisibilityModel}
          onColumnVisibilityModelChange={newModel => {
            setColumnVisibilityModel(newModel)
            localStorage.setItem('accountingColumnVisibilityModel', JSON.stringify(newModel))
          }}
          sortModel={sortModel}
          onSortModelChange={newModel => {
            setSortModel(newModel)
            localStorage.setItem('accountingSortModel', JSON.stringify(newModel))
          }}
          onRowCountChange={count => setRowCount(count)}
          filterModel={filterModel}
          // onFilterModelChange={model => {
          //   console.log('MODEL: ', model)
          // }}
          onFilterModelChange={model => {
            setFilterModel({ ...model, quickFilterValues: model.quickFilterValues })
          }}
          apiRef={apiRef}
          slots={{
            toolbar: CustomAccountingGridToolbar,
            footer: RenderRowCount,
          }}
          slotProps={{
            toolbar: {
              densityModel: density,
              filterModel: filterModel,
              visibilityModel: columnVisibilityModel,
              sortModel: sortModel,
              setFilterModel: setFilterModel,
              onClearSorting: handleClearSorting,
              onClearVisibility: handleClearVisibility,
              onChangeView: handleChangeView,
              selectedView: selectedView,
              setSelectedView: setSelectedView,
              context: 'deals',
            },
            footer: {
              // deals: deals,
              deals: processedDeals,
              visibleRowCount: visibleRowCount,
              rowCount: rowCount,
              selectionModel: selectionModel,
            },
            panel: {
              sx: {
                border: filterMenuBorder,
                borderRadius: '4px',
              },
            },
            filterPanel: {
              sx: {
                ...filterPanelProps,
              },
            },
          }}
          onColumnResizeStop={handleColumnResizeStop}
          disableExtendRowFullWidth
          onDensityChange={handleDensityChange}
          loading={isOrganizationsLoading}
        />

        <AccountingGridFooter
          selectionModel={selectionModel}
          totalRowCount={totalRowCount}
          visibleRowCount={visibleRowCount}
          rowCount={rowCount}
          massUpdateData={massUpdateData}
          onMassUpdateChange={handleMassUpdateChange}
          handleMassUpdate={handleMassUpdate}
          onChangeView={handleChangeView}
          deals={deals}
          anchorEl={anchorEl}
          handleDeleteClick={handleDeleteClick}
          handleConfirmDelete={handleConfirmDelete}
          handleClosePopover={handleClosePopover}
          handleEditClick={handleEditClick}
          handleClone={handleCloneDeal}
          selectedData={selectedDeal}
        />
        <DealDetailsProvider dealData={selectedDeal}>
          {drawerOpen && <AccountingDrawer open={drawerOpen} onClose={handleClose} />}
          <Outlet />
        </DealDetailsProvider>
      </Box>

      <LoadingOverlay open={isUpdating} message={'Updating Deals'} />
    </>
  )
}

export default AccountingPage
