import React, { useEffect, useState, useRef } from 'react'
import { GridToolbarContainer, GridToolbarDensitySelector, useGridApiContext } from '@mui/x-data-grid-pro'
import { Badge, Box, IconButton, Tooltip, Typography, useTheme } from '@mui/material'
import { gridToolbarContainerStyles, StyledGridToolbarQuickFilter } from './style'
import { useActiveUser } from '../../api/slack/useActiveUser.js'
import { useNavigate } from 'react-router-dom'
import ResetFiltersButton from '../ui/reset-filters-button'
import ResetSortingButton from '../ui/reset-sorting-button'
import ResetColumnsButton from '../ui/reset-columns-button'
import OpenColumnsMenu from '../ui/open-columns-menu'
import OpenFiltersMenu from '../ui/open-filters-menu'
import ExportCsvButton from '../ui/export-csv-button.js'
import PersonIcon from '@mui/icons-material/Person'
import FilterMyDataButton from '../ui/filter-my-data-button.tsx'
import { slotProps } from '../DataGrid/style.js'

const initialVisibilityState = {
  implementationFee: false,
  lastModified: false,
  type: false,
  endDate: false,
}
const isSortModelEqual = (sortModel1, sortModel2) => {
  if (sortModel1.length !== sortModel2.length) return false
  return sortModel1.every((sortItem, index) => {
    const initialSortItem = sortModel2[index]
    return sortItem.field === initialSortItem.field && sortItem.sort === initialSortItem.sort
  })
}
const ContactsGridToolbar = ({
  initialSortModel,
  filterModel,
  visibilityModel,
  sortModel,
  setFilterModel,
  onClearSorting,
  onClearVisibility,
  context,
}) => {
  const theme = useTheme()
  const navigate = useNavigate()
  const quickFilterRef = useRef(null) // Create a ref for the quick filter
  const [filterStatus, setFilterStatus] = useState('')
  const [filterDealStatus, setFilterDealStatus] = useState('')
  const [filterStage, setFilterStage] = useState('')
  const { activeUser, isActiveUserLoading, isActiveUserError } = useActiveUser()
  const activeUserName =
    activeUser && activeUser.user && activeUser.user.profile ? activeUser.user.profile.real_name : null
  // 🔹 State for Density Menu Popover
  const [densityAnchorEl, setDensityAnchorEl] = useState(null)
  /////////////////////// KanBan navigation ///////////////////////////////
  const handleNavigateToKanban = () => {
    navigate('/deals/pipeline')
  }
  const isSortModelChanged = !isSortModelEqual(sortModel, initialSortModel)
  ///////////////////////////////////////////////////////////////////////////\
  // Utility function to check if two sort models are deeply equal
  const apiRef = useGridApiContext() // Get DataGrid API
  // 🔹 Open/Close Handlers for Density Menu
  const handleOpenDensityMenu = event => {
    setDensityAnchorEl(event.currentTarget)
  }
  const handleCloseDensityMenu = () => {
    setDensityAnchorEl(null)
  }

  // 🔹 Toggle Columns Visibility Menu
  const handleOpenColumnsMenu = event => {
    apiRef.current.showPreferences('columns')
  }

  // 🔹 Toggle Filter Panel
  const handleOpenFilterPanel = () => {
    apiRef.current.showFilterPanel()
  }

  // 🔹 Export Data
  const handleExport = () => {
    apiRef.current.exportDataAsCsv()
  }
  // Handle keydown event to focus the quick filter on Ctrl+Shift+F or Cmd+Shift+F
  useEffect(() => {
    const handleKeyDown = event => {
      if ((event.ctrlKey || event.metaKey) && event.key === 'f') {
        event.preventDefault()
        if (quickFilterRef.current) {
          quickFilterRef.current.focus() // Focus the quick filter input
        }
      }
    }

    window.addEventListener('keydown', handleKeyDown)
    return () => {
      window.removeEventListener('keydown', handleKeyDown)
    }
  }, [])
  const handleFilterMyData = () => {
    setFilterModel({
      ...filterModel,
      quickFilterValues: [activeUserName],
    })
  }

  const handleSetFilter = (fieldName, selectedValues) => {
    setFilterModel(prevModel => {
      // Remove existing filter for the same field
      let updatedFilters = prevModel.items.filter(item => item.field !== fieldName)

      // If selectedValues is empty, we remove the filter
      if (selectedValues && selectedValues.length > 0) {
        updatedFilters.push({
          id: `${fieldName}-${Date.now()}`, // Ensure unique ID
          field: fieldName,
          operator: 'isAnyOf', // 🔥 Multi-selection operator
          value: selectedValues,
        })
      }

      return {
        ...prevModel,
        items: updatedFilters,
      }
    })
  }

  const handleClearFilters = e => {
    e.preventDefault()
    setFilterModel({ items: [], quickFilterValues: [] })
    setFilterStatus('')
    setFilterDealStatus('')
    setFilterStage('')
  }

  return (
    <GridToolbarContainer sx={gridToolbarContainerStyles}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          alignContent: 'center',
          justifyContent: 'space-between',
          width: '100%',
        }}
      >
        <Box sx={{ display: 'flex', flexDirection: 'row', gap: 2, ml: 2 }}>
          <GridToolbarDensitySelector slotProps={slotProps(theme)} />
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: 2,
            }}
          >
            <OpenColumnsMenu onOpenColumns={handleOpenColumnsMenu} />
            <OpenFiltersMenu
              onOpenFilters={handleOpenFilterPanel}
              filterModel={filterModel}
              badgeColor={theme.palette.icon[context]}
            />
            <ExportCsvButton onExport={handleExport} />
          </Box>
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
            gap: 2,
          }}
        >
          <>
            <FilterMyDataButton
              icon={<PersonIcon sx={{ fontSize: '30px' }} />}
              filterModel={filterModel}
              onClick={handleFilterMyData}
              label={'My Contacts'}
            />
            <ResetColumnsButton
              visibilityModel={visibilityModel}
              onClearVisibility={onClearVisibility}
              initialVisibilityState={initialVisibilityState}
            />
            <ResetSortingButton isSortModelChanged={isSortModelChanged} onClearSorting={onClearSorting} />
            <ResetFiltersButton filterModel={filterModel} handleClearFilters={handleClearFilters} />

            <Badge
              badgeContent={
                <>
                  {filterModel &&
                  filterModel.quickFilterValues &&
                  Array.isArray(filterModel.quickFilterValues) &&
                  filterModel.quickFilterValues.length > 0 ? (
                    <Typography
                      sx={{
                        fontSize: 12,
                        bgcolor: theme.palette.icon[context],
                        p: 0.5,
                        height: '20px',
                        width: '20px',
                        borderRadius: '50%',
                        display: 'flex', // Flexbox centering
                        alignItems: 'center', // Vertically center
                        justifyContent: 'center', // Horizontally center
                        color: theme.palette.mode === 'dark' ? 'rgba(0,0,0,1)' : 'rgba(0,0,0,1)',
                        fontWeight: 'bold',
                      }}
                    >
                      {filterModel && filterModel.quickFilterValues && Array.isArray(filterModel.quickFilterValues)
                        ? filterModel.quickFilterValues.length
                        : null}
                    </Typography>
                  ) : null}
                </>
              }
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
            >
              <StyledGridToolbarQuickFilter placeholder={'ctrl+f or cmd+f to search'} inputRef={quickFilterRef} />
            </Badge>
          </>
        </Box>
      </Box>
    </GridToolbarContainer>
  )
}

export default ContactsGridToolbar
