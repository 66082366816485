import {
  Box,
  Chip,
  IconButton,
  Typography,
  Tooltip,
  Link as MuiLink,
  Avatar,
  Select,
  MenuItem,
  TextField,
  AvatarGroup,
  Stack,
  InputLabel,
} from '@mui/material'
import dayjs from 'dayjs'
import { GridStatusChip, GridStageChip, NcoStatusContainer, NcoChannelContainer } from '../../../style/styleElements'
import { Link } from 'react-router-dom'
import PandaDocLinkIcon from '../../Icons/PandaDockLinkIcon'
import UserImageComponent from '../../Users/UserImageComponent'
import { getDensityStyles, userImageStyles, DisabledIconButton, fieldStyles } from '../style'
import RenderVertical from '../../Organizations/RenderVertical'
import { getGridStringOperators } from '@mui/x-data-grid-pro'
import RenderContractedByColumn from '../RenderContractedByColumn'
import { formatCurrency } from '../../DealsKanban/aggregateStageTotals'
import StrategyProgress from '../../Icons/StrategyProgress'
import { analyzeStrategy } from '../../Strategy/analyzeStrategy'
import PandaDocViewer from '../../PaymentSchedule/PandDocViewer'
import EditSelectCell from '../../NCO/EditSelectCell'
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers-pro'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import EditableDatePicker from '../../NCO/EditableDatePicker'
import { renderColorByString } from '../renderColorByString'
import { ncoStatusOptions, seoGmbOptions } from '../filterArrays'
import MetaLightSvg from '../../../images/icons/meta (1).svg'
import GoogleDisplaySvg from '../../../images/icons/Google Display.png'
import GoogleSearchSvg from '../../../images/icons/Google Ads.png'
import LinkedInSvg from '../../../images/icons/linkedin.svg'
import MicrosoftSvg from '../../../images/icons/bing.png'
import SimplifiSvg from '../../../images/icons/simplifi_logo.svg'
import SnapchatSvg from '../../../images/icons/snapchat.svg'
import SpotifySvg from '../../../images/icons/spotify.svg'
import TikTokSvg from '../../../images/icons/tik-tok.png'
import YoutubeSvg from '../../../images/icons/youtube.svg'
import Seo1Svg from '../../../images/icons/seotier1.svg'
import Seo2Svg from '../../../images/icons/seotier2.svg'
import Seo3Svg from '../../../images/icons/seotier3.svg'
import Seo4Svg from '../../../images/icons/seo.png'
import { formatDateString } from '../../Tasks/taskUtil'
import { customNotContainsOperator, stringAvatar } from '../gridOperators'
import { seoStatusOptions, ncoStatusFilters, creativeStatusFilters } from '../filterArrays'
import { formatDayjsModifiedTime } from '../../../utility/formatDayjsDateTime'
import SeoDisplayIcon from '../../ui/seo-display-icon'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import { renderMarketingTeamColumns } from './renderMarketingTeamColumns'
import GoogleSearchIcon from '../../../images/icons/google-search-icon.svg'
import GoogleSeoIcon from '../../../images/icons/google-search-icon.svg'
import RenderOnboardTeam from '../components/RenderOnboardTeam'
import CircularStatusBar from '../components/CircularStatusBar.tsx'
import MiniPieChart from '../components/MiniPieChart.tsx'
import ContactCell from '../components/ContactCell.tsx'

export const capitalizeFirstLetter = string => {
  return string.charAt(0).toUpperCase() + string.slice(1)
}
const boxStyles = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: '100%',
  cursor: 'pointer',
}
const leftBoxStyles = {
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'left',
  alignItems: 'center',
  height: '100%',
}
export const renderMtbColumns = (
  organizations,
  allOrganizations,
  users,
  deals,
  density = 'standard',
  // handleDealClick,
  handleOrgClick,
  handleUserChange,
  selectionModel,
  handleSelectUserRow
) => {
  const boxRenderStyle = {
    display: 'flex',
    flexDirection: 'row',
    alignContent: 'center',
    justifyContent: 'space-between',
    gap: 2,
    width: '100%',
  }

  const handleClickEditStart = params => {
    if (selectionModel?.length <= 1) {
      params.api.startCellEditMode({
        id: params.id,
        field: params.field,
      })
    } else {
      return
    }
  }
  const densityStyles = getDensityStyles(density)
  const roleArray = ['AM', 'PM', 'DMS', 'MIA', 'QA']
  const channelsArray = [
    {
      field: 'isGoogleDisplay',
      headerName: 'Google Display',
      src: GoogleDisplaySvg,
      type: 'srcIcon',
      width: 30,
      flex: 0.5,
    },
    {
      field: 'isGoogleSearch',
      headerName: 'Google Search',
      src: GoogleSearchSvg,
      type: 'srcIcon',
      width: 30,
      flex: 0.5,
    },
    { field: 'isLinkedIn', headerName: 'LinkedIn', src: LinkedInSvg, type: 'srcIcon', width: 30, flex: 0.5 },
    {
      field: 'isMetaAdvertising',
      headerName: 'Meta Advertising',
      src: MetaLightSvg,
      type: 'srcIcon',
      width: 30,
      flex: 0.5,
    },
    {
      field: 'isMicrosoftAdvertising',
      headerName: 'Microsoft',
      src: MicrosoftSvg,
      type: 'srcIcon',
      width: 30,
      flex: 0.5,
    },
    { field: 'isSimplifi', headerName: 'Simplifi', src: SimplifiSvg, type: 'srcIcon', width: 30, flex: 0.5 },
    { field: 'isSnapchat', headerName: 'Snapchat', src: SnapchatSvg, type: 'srcIcon', width: 30, flex: 0.5 },
    { field: 'isSpotify', headerName: 'Spotify', src: SpotifySvg, type: 'srcIcon', width: 30, flex: 0.5 },
    { field: 'isTikTok', headerName: 'TikTok', src: TikTokSvg, type: 'srcIcon', width: 30, flex: 0.5 },
    { field: 'isYoutube', headerName: 'Youtube', src: YoutubeSvg, type: 'srcIcon', width: 30, flex: 0.5 },
    { field: 'isSeo1', headerName: 'SEO - Tier 1', tier: 1, src: Seo1Svg, type: 'seoIcon', width: 30, flex: 0.5 },
    { field: 'isSeo2', headerName: 'SEO - Tier 2', tier: 2, src: Seo2Svg, type: 'seoIcon', width: 30, flex: 0.5 },
    { field: 'isSeo3', headerName: 'SEO - Tier 3', tier: 3, src: Seo3Svg, type: 'seoIcon', width: 30, flex: 0.5 },
    { field: 'isSeo4', headerName: 'SEO - Tier 4', tier: 4, src: Seo4Svg, type: 'seoIcon', width: 30, flex: 0.5 },
  ]

  const renderChannels = () =>
    channelsArray?.map(channel => ({
      field: channel?.field,
      headerName: channel?.headerName,
      renderHeader: () => {
        return (
          <>
            {channel?.type === 'srcIcon' ? (
              <Tooltip title={channel?.headerName}>
                <img src={channel?.src} alt={channel?.headerName} style={{ height: 24 }} />
              </Tooltip>
            ) : (
              <SeoDisplayIcon title={channel?.headerName} tier={channel?.tier} />
            )}
          </>
        )
      },
      width: 150,
      flex: 0.5,
      headerAlign: 'center',
      disableColumnMenu: true,
      valueGetter: params => {
        return params ? 'Yes' : 'No'
      },
      renderCell: params => (
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            height: '100%',
            width: '100%',
            cursor: 'pointer',
            // bgcolor: renderColorByString(params.value),
          }}
        >
          {params?.value === 'Yes' ? (
            <Box sx={{ display: 'flex', alignItems: 'center', height: '100%' }}>
              <CheckCircleIcon sx={{ height: '100%', color: renderColorByString(params.value) }} />
            </Box>
          ) : (
            <Box sx={{ display: 'flex', alignItems: 'center', height: '100%' }}>
              <CheckCircleIcon sx={{ height: '100%', color: 'rgb(96, 94, 94)', opacity: '0.2' }} />
            </Box>
          )}
        </Box>
      ),
    }))

  return [
    {
      field: 'name',
      headerName: 'Organization Name',
      width: 100,
      flex: 1,
      headerAlign: 'left',
      filterOperators: [...getGridStringOperators(), customNotContainsOperator],
      valueGetter: params => {
        return params
      },
      renderCell: params => {
        return (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'left',
              alignItems: 'center',
              height: '100%',
              padding: densityStyles?.padding,
              gap: densityStyles?.gap,
              cursor: 'pointer',
            }}
            onClick={() => handleOrgClick(params.row)}
          >
            {params.row.photoUrl ? (
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'left',
                  alignItems: 'center',
                  height: '100%',
                  gap: densityStyles?.gap,
                }}
              >
                <Avatar
                  src={params.row.photoUrl}
                  sx={{
                    width: densityStyles?.avatarSize,
                    height: densityStyles?.avatarSize,
                    objectFit: 'cover',
                    objectPosition: 'center',
                  }}
                  alt={params.value}
                />

                <Typography
                  sx={{
                    fontSize: densityStyles?.fontSize,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    width: '100%',
                    height: '100%',
                  }}
                >
                  {params.value}
                </Typography>
              </Box>
            ) : (
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'left',
                  alignItems: 'center',
                  height: '100%',
                  gap: densityStyles?.gap,
                }}
              >
                <Avatar
                  {...stringAvatar(params.value, densityStyles)}
                  sx={{
                    backgroundColor: 'rgb(114, 229, 218)',
                    width: densityStyles?.avatarSize,
                    height: densityStyles?.avatarSize,
                  }}
                  alt={params.value}
                />
                <Typography
                  sx={{
                    fontSize: densityStyles?.fontSize,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    width: '100%',
                    height: '100%',
                  }}
                >
                  {params.value}
                </Typography>
              </Box>
            )}
          </Box>
        )
      },
    },
    {
      field: 'activeCount',
      headerName: 'Active Deals',
      width: 100,
      flex: 0.8,
      headerAlign: 'center',
      valueGetter: params => {
        return params?.total
      },
      renderCell: params => {
        // Destructure the activeCount object directly
        const { active = 0, inProgress = 0, monthToMonth = 0, dayNotice = 0 } = params?.row?.activeCount || {}
        const chartData = [
          { label: 'active', value: active, color: 'rgb(80, 200, 120)' },
          { label: 'inProgress', value: inProgress, color: 'rgb(249, 167, 49)' },
          { label: 'monthToMonth', value: monthToMonth, color: 'rgb(22, 138, 239)' },
          { label: 'dayNotice', value: dayNotice, color: 'rgb(235, 77, 77)' },
        ]

        return (
          <Box
            key={params?.row?.id}
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              width: '100%',
              height: '100%',
            }}
          >
            <Tooltip
              title={
                <Stack sx={{ textAlign: 'left' }}>
                  <Box sx={boxRenderStyle}>
                    <InputLabel sx={{ color: 'rgba(80, 200, 120, 0.6)' }}>Active:</InputLabel>
                    <Typography>{active}</Typography>
                  </Box>
                  <Box sx={boxRenderStyle}>
                    <InputLabel sx={{ color: 'rgba(249, 167, 49, 0.6)' }}>In Progress:</InputLabel>
                    <Typography>{inProgress}</Typography>
                  </Box>
                  <Box sx={boxRenderStyle}>
                    <InputLabel sx={{ color: 'rgba(22, 138, 239, 0.6)' }}>Month To Month:</InputLabel>
                    <Typography>{monthToMonth}</Typography>
                  </Box>
                  <Box sx={boxRenderStyle}>
                    <InputLabel sx={{ color: 'rgba(235, 77, 77, 0.6)' }}>30 Day Notice:</InputLabel>
                    <Typography>{dayNotice}</Typography>
                  </Box>
                </Stack>
              }
            >
              <div>
                <MiniPieChart rowId={params?.row?.id} data={chartData} size={densityStyles?.svgSize} strokeWidth={4} />
              </div>
            </Tooltip>
          </Box>
        )
      },
    },
    {
      field: 'billingContact',
      headerName: 'Contact',
      width: 100,
      flex: 0.8,
      headerAlign: 'center',
      valueGetter: params => {
        // Make sure to return a valid ID or undefined
        const id = params
        return typeof id === 'string' && id.trim() !== '' ? id : undefined
      },
      renderCell: params => {
        const contactId = params?.value
        return (
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              width: '100%',
              height: '100%',
            }}
          >
            {contactId ? <ContactCell contactId={contactId} /> : <Typography>–</Typography>}
          </Box>
        )
      },
    },

    {
      field: 'seoFormCounts',
      headerName: 'SEO Progress',
      width: 100,
      flex: 0.8,
      headerAlign: 'center',
      valueGetter: params => {
        return params && params ? Math.round(params * 100) : 0
      },
      renderCell: params => {
        return (
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              width: '100%',
              height: '100%',
            }}
          >
            <CircularStatusBar progress={params?.value || 0} />
          </Box>
        )
      },
    },
    {
      field: 'amProgress',
      headerName: 'AM Progress',
      width: 100,
      flex: 0.8,
      headerAlign: 'center',
      valueGetter: params => {
        return params && params ? Math.round(params * 100) : 0
      },
      renderCell: params => {
        return (
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              width: '100%',
              height: '100%',
            }}
          >
            <CircularStatusBar progress={params?.value || 0} />
          </Box>
        )
      },
    },
    {
      field: 'dmsProgress',
      headerName: 'DMS Progress',
      width: 100,
      flex: 0.8,
      headerAlign: 'center',
      valueGetter: params => {
        return params && params ? Math.round(params * 100) : 0
      },
      renderCell: params => {
        return (
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              width: '100%',
              height: '100%',
            }}
          >
            <CircularStatusBar progress={params?.value || 0} />
          </Box>
        )
      },
    },
    {
      field: 'dealStatus',
      headerName: 'Deal Status',
      width: 100,
      flex: 0.8,
      headerAlign: 'center',
      valueGetter: params => {
        return params
      },
      renderCell: params => {
        return (
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              width: '100%',
              cursor: 'pointer',
              bgcolor: renderColorByString(params.value),
            }}
          >
            <NcoStatusContainer
              size='small'
              type={params.value}
              sx={{ bgcolor: renderColorByString(params.value), textAlign: 'center' }}
            >
              {params.value}
            </NcoStatusContainer>
          </Box>
        )
      },
    },

    {
      field: 'completedCounts',
      headerName: 'NCO Progress',
      width: 100,
      flex: 0.8,
      headerAlign: 'center',
      valueGetter: params => {
        return params && params?.onboardingprogress ? Math.round(params?.onboardingprogress * 100) : 0
      },
      renderCell: params => {
        return (
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              width: '100%',
              height: '100%',
            }}
          >
            <CircularStatusBar progress={params?.value || 0} />
          </Box>
        )
      },
    },
    {
      field: 'optsPercentage',
      headerName: 'Opts Progress',
      width: 100,
      flex: 0.8,
      headerAlign: 'center',
      valueGetter: params => {
        return params
      },
      renderCell: params => {
        return (
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              width: '100%',
              height: '100%',
            }}
          >
            <CircularStatusBar progress={params?.value || 0} />
          </Box>
        )
      },
    },
    {
      field: 'totalQualifiedOpts',
      headerName: 'Qual. Opts',
      width: 75,
      flex: 0.5,
      headerAlign: 'center',
      valueGetter: params => {
        return params
      },
      renderCell: params => {
        return (
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              width: '100%',
              height: '100%',
            }}
          >
            {params?.value}
          </Box>
        )
      },
    },
    ...renderMarketingTeamColumns(
      ['SEO'],
      users,
      selectionModel,
      handleUserChange,
      densityStyles,
      handleClickEditStart,
      handleSelectUserRow
    ),
    {
      field: 'opts',
      headerName: 'Opts',
      width: 55,
      flex: 0.5,
      headerAlign: 'center',
      valueGetter: params => {
        return params
      },
      renderCell: params => {
        return (
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              width: '100%',
            }}
          >
            {params?.value}
          </Box>
        )
      },
    },
    {
      field: 'seoStatus',
      headerName: 'SEO Status',
      width: 100,
      flex: 0.8,
      headerAlign: 'center',
      editable: true,
      valueGetter: params => {
        return params ?? 'Paused'
      },
      renderCell: params => {
        const handleClick = () => {
          params.api.startCellEditMode({
            id: params.id,
            field: params.field,
          })
        }
        return (
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              width: '100%',
              cursor: 'pointer',
              bgcolor: renderColorByString(params.value),
            }}
            onClick={handleClick}
          >
            <NcoStatusContainer
              size='small'
              type={params.value}
              sx={{ bgcolor: renderColorByString(params.value), textAlign: 'center' }}
            >
              {params.value}
            </NcoStatusContainer>
          </Box>
        )
      },
      renderEditCell: params => <EditSelectCell params={params} options={seoStatusOptions} />,
    },
    {
      field: 'products',
      headerName: 'SEO Contract',
      width: 100,
      flex: 0.5,
      headerAlign: 'center',
      valueGetter: params => {
        const seoProduct = params?.find(product => product.category === 'SEO')
        return seoProduct ? `$${seoProduct.price}` : 'N/A'
      },
      renderCell: params => {
        return (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: '100%',
              fontWeight: 'bold',
            }}
          >
            {params.value}
          </Box>
        )
      },
    },
    {
      field: 'onboardingStatus',
      headerName: 'Master Status',
      width: 100,
      flex: 0.8,
      headerAlign: 'center',
      editable: true,
      valueGetter: params => {
        return params ?? 'Staging'
      },
      renderCell: params => {
        const handleClick = () => {
          params.api.startCellEditMode({
            id: params.id,
            field: params.field,
          })
        }
        return (
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              width: '100%',
              cursor: 'pointer',
              bgcolor: renderColorByString(params.value),
            }}
            onClick={handleClick}
          >
            <NcoStatusContainer
              size='small'
              type={params.value}
              sx={{ bgcolor: renderColorByString(params.value), textAlign: 'center' }}
            >
              {params.value}
            </NcoStatusContainer>
          </Box>
        )
      },
      renderEditCell: params => <EditSelectCell params={params} options={ncoStatusOptions} />,
    },
    {
      field: 'seoGmb',
      headerName: 'GMB',
      width: 100,
      flex: 0.8,
      headerAlign: 'center',
      editable: true,
      valueGetter: params => {
        return params ?? 'Not Started'
      },
      renderCell: params => {
        const handleClick = () => {
          params.api.startCellEditMode({
            id: params.id,
            field: params.field,
          })
        }
        return (
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              width: '100%',
              cursor: 'pointer',
              bgcolor: params?.color ?? renderColorByString(params.value),
            }}
            onClick={handleClick}
          >
            <NcoStatusContainer
              size='small'
              type={params.value}
              sx={{ bgcolor: params?.color ?? renderColorByString(params.value), textAlign: 'center' }}
            >
              {params.value}
            </NcoStatusContainer>
          </Box>
        )
      },
      renderEditCell: params => <EditSelectCell params={params} options={seoGmbOptions} />,
    },
    {
      field: 'type',
      headerName: 'Type',
      width: 100,
      flex: 0.8,
      headerAlign: 'center',
      valueGetter: params => {
        return params
      },
      renderCell: params => {
        return (
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              width: '100%',
            }}
          >
            <GridStatusChip size='small' type={params.value} label={params.value} />
          </Box>
        )
      },
    },

    {
      field: 'timeCompleted',
      headerName: 'Date Closed',
      width: 100,
      flex: 0.65,
      valueGetter: params => {
        return params ? formatDateString(params) : null
      },
      renderCell: params => {
        return (
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              width: '100%',
              height: '100%',
              cursor: 'pointer',
            }}
          >
            {params && params.value ? (
              params.value
            ) : (
              <Typography
                sx={{
                  fontSize: densityStyles.fontSize,
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  width: '100%',
                  height: '100%',
                }}
              >
                {'—'}
              </Typography>
            )}
          </Box>
        )
      },
    },
    ...renderChannels(),
    {
      field: 'seoTier',
      headerName: 'Tier',
      renderHeader: () => {
        return (
          <Box>
            <Tooltip title='SEO Tier'>
              <img src={GoogleSearchIcon} alt='seoColumnIcon' style={{ height: 28, width: 28 }} />
            </Tooltip>
          </Box>
        )
      },
      width: 75,
      flex: 0.5,
      headerAlign: 'center',
      valueGetter: params => {
        return params && params?.label ? params?.label : ''
      },
      renderCell: params => {
        return (
          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100%' }}>
            {params.value ? (
              <NcoStatusContainer sx={{ bgcolor: renderColorByString(params?.value), textAlign: 'center' }}>
                {params?.value}
              </NcoStatusContainer>
            ) : (
              <CheckCircleIcon sx={{ height: '100%', color: 'rgb(96, 94, 94)', opacity: '0.2' }} />
            )}
          </Box>
        )
      },
    },
    {
      field: 'dependenciesDueDate',
      headerName: 'Dependencies Due',
      width: 100,
      flex: 0.65,
      editable: true,
      valueGetter: params => {
        return params ? formatDateString(params) : null
      },
      renderCell: params => {
        const handleClick = () => {
          params.api.startCellEditMode({
            id: params.id,
            field: params.field,
          })
        }
        return (
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              width: '100%',
              cursor: 'pointer',
            }}
            onClick={handleClick}
          >
            {params && params.value ? params.value : 'Set Date'}
          </Box>
        )
      },
      renderEditCell: params => {
        const handleChange = newValue => {
          params.api.setEditCellValue({
            id: params.id,
            field: params.field,
            value: newValue ? newValue.toISOString() : null, // Save as ISO string
          })
        }

        return <EditableDatePicker value={params.value} onChange={handleChange} />
      },
    },
    {
      field: 'estLaunchDate',
      headerName: 'Estimated Launch Date',
      width: 100,
      flex: 0.65,
      valueGetter: params => {
        const estLaunchDate = params
        return estLaunchDate ? dayjs(estLaunchDate).format('MM/DD/YYYY') : null
      },
      renderCell: params => {
        const estLaunchDate = params.value
        return (
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              width: '100%',
              height: '100%',
              cursor: 'pointer',
            }}
          >
            {estLaunchDate || (
              <Typography
                sx={{
                  fontSize: densityStyles.fontSize,
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  width: '100%',
                  height: '100%',
                }}
              >
                {'—'}
              </Typography>
            )}
          </Box>
        )
      },
    },
    {
      field: 'dateLaunched',
      headerName: 'Date Launched',
      width: 100,
      flex: 0.65,
      editable: true,
      valueGetter: params => {
        return params ? formatDateString(params) : null
      },

      renderCell: params => {
        const dateLaunched = params?.row?.dateLaunched ? formatDateString(params?.row?.dateLaunched) : null

        const handleClick = () => {
          params.api.startCellEditMode({
            id: params.id,
            field: params.field,
          })
        }

        return (
          <>
            {params && params.value ? (
              <Box
                sx={{
                  ...boxStyles,
                }}
                onClick={handleClick}
              >
                {params.value}
              </Box>
            ) : dateLaunched ? (
              <Box
                sx={{
                  ...boxStyles,
                }}
                onClick={handleClick}
              >
                {dateLaunched}
              </Box>
            ) : (
              <Box
                sx={{
                  ...boxStyles,
                }}
                onClick={handleClick}
              >
                {'Set Date Launched'}
              </Box>
            )}
          </>
        )
      },
      renderEditCell: params => {
        const dateLaunched = params?.row?.dateLaunched ?? null
        const handleChange = newValue => {
          params.api.setEditCellValue({
            id: params.id,
            field: params.field,
            value: newValue ? newValue.toISOString() : null, // Save as ISO string
          })
        }
        return <EditableDatePicker value={params.value || dateLaunched} onChange={handleChange} />
      },
    },
    {
      field: 'startDate',
      headerName: 'Start Date',
      width: 100,
      flex: 0.5,
      headerAlign: 'center',
      valueGetter: params => {
        return params ? formatDateString(params) : null
      },
      renderCell: params => {
        return (
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              width: '100%',
            }}
          >
            {params && params.value ? params.value : ''}
          </Box>
        )
      },
    },
    {
      field: 'endDate',
      headerName: 'End Date',
      width: 100,
      flex: 0.5,
      headerAlign: 'center',
      valueGetter: params => {
        return params ? formatDateString(params) : null
      },
      renderCell: params => {
        return (
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              width: '100%',
            }}
          >
            {params && params.value ? params.value : ''}
          </Box>
        )
      },
    },
    {
      field: 'contractId',
      headerName: 'Contract',
      width: 100,
      flex: 0.45,
      headerAlign: 'center',
      valueGetter: params => {
        return params
      },
      renderCell: params => {
        const previewUrl = `https://app.pandadoc.com/a/#/documents`
        const contractId = params && params.value ? params.value : null
        const contractUrl = previewUrl && contractId ? `${previewUrl}/${contractId}` : null
        return (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
              alignItems: 'center',
              height: '100%',
            }}
          >
            {contractUrl ? (
              <PandaDocViewer contractId={contractId} height={32} width={32} />
            ) : (
              <PandaDocViewer contractId={contractId} height={32} width={32} disabled={true} />
            )}
          </Box>
        )
      },
    },

    {
      field: 'contractedBy',
      headerName: 'Contracted By',
      flex: 0.3,
      headerAlign: 'left',
      valueGetter: params => {
        // console.log('VG[contractedBy] params:', params)
        const contractedById = params && Array.isArray(params) ? params[0] : null
        const contractedBy = allOrganizations?.find(p => p.org_uuid === contractedById)
        return contractedBy ? contractedBy.name : null
        // return contractedBy ? contractedBy.name : 'N/A'
      },
      filterOperators: [...getGridStringOperators(), customNotContainsOperator],
      renderCell: params => {
        const contractedById = params.row.contractedBy?.[0]
        const contractedBy = allOrganizations?.find(p => p.org_uuid === contractedById)
        return contractedById && contractedBy ? (
          <Link to={`/organizations/${contractedById}/details`} style={{ textDecoration: 'none', color: 'inherit' }}>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'left',
                alignItems: 'center',
                height: '100%',
                padding: densityStyles.padding,
                gap: densityStyles.gap,
              }}
            >
              <Box sx={{ display: 'flex', alignItems: 'left', gap: 1 }}>
                <Typography sx={{ fontSize: densityStyles.fontSize }}>{contractedBy.name}</Typography>
              </Box>
            </Box>
          </Link>
        ) : null
      },
    },

    {
      field: 'strategy',
      headerName: 'Strategy',
      width: 100,
      flex: 0.55,
      headerAlign: 'left',
      sortable: true,
      valueGetter: params => {
        return params?.progress ?? ''
      },
      renderCell: params => {
        const status = params.row.status
        const deal = params && params.row ? params.row : null
        const dealId = deal.id
        const orgId = deal.organizations && deal.organizations.length > 0 ? deal.organizations[0] : null
        const selectedOrganization = organizations.find(org => org.id === orgId)
        const selectedVerticals = selectedOrganization ? selectedOrganization.vertical : []
        return (
          <Link
            to={`/deals/${dealId}/strategy`}
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              width: '100%',
              height: '100%',
              textDecoration: 'none',
            }}
          >
            <StrategyProgress dealId={dealId} size={densityStyles.globeSize} />
          </Link>
        )
      },
    },
    {
      field: 'id',
      headerName: 'Vertical',
      flex: 0.7,
      headerAlign: 'center',
      sortable: false,
      valueGetter: params => {
        const deal = deals.find(deal => deal.deal_uuid === params)
        const orgId = deal && Array.isArray(deal.organizations) && deal.organizations[0] ? deal.organizations[0] : []
        const organization = organizations.find(org => org.org_uuid === orgId)
        const verticals = organization && Array.isArray(organization.vertical) ? organization.vertical : []
        const verticalParams = verticals && Array.isArray(verticals) ? verticals[0] : []
        return verticalParams
      },
      filterOperators: [...getGridStringOperators(), customNotContainsOperator],
      renderCell: params => {
        return (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
              alignItems: 'center',
              height: '100%',
              width: '100%',
            }}
          >
            <RenderVertical
              vertical={params.value}
              width={densityStyles.verticalWidth}
              height={densityStyles.verticalHeight}
              size={densityStyles.verticalSize}
            />
          </Box>
        )
      },
    },

    {
      field: 'stage',
      headerName: 'Deal Stage',
      width: 100,
      flex: 1,
      headerAlign: 'center',
      valueGetter: params => {
        return params
      },
      filterOperators: [...getGridStringOperators(), customNotContainsOperator],
      renderCell: params => {
        return (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
              alignItems: 'center',
              height: '100%',
              padding: densityStyles.padding,
              gap: densityStyles.gap,
            }}
          >
            <GridStageChip size='small' stage={params.value} label={params.value} />
          </Box>
        )
      },
    },
    {
      field: 'lastModified',
      headerName: 'Modified',
      width: 100,
      flex: 1,
      headerAlign: 'left',
      valueGetter: params => params,
      renderCell: params => {
        return (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
              alignItems: 'center',
              height: '100%',
              padding: densityStyles.padding,
              gap: densityStyles.gap,
            }}
          >
            {formatDayjsModifiedTime(params && params.value ? params.value : 'No Modified Time')}
          </Box>
        )
      },
    },

    {
      field: 'implementationFee',
      headerName: 'Impl. Fee',
      width: 100,
      flex: 0.5,
      headerAlign: 'center',
      valueGetter: params => params,
      renderCell: params => {
        return (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
              alignItems: 'center',
              height: '100%',
              padding: densityStyles.padding,
              gap: densityStyles.gap,
            }}
          >
            {params.value}
          </Box>
        )
      },
    },
    {
      field: 'status',
      headerName: 'Client Status',
      width: 100,
      flex: 0.5,
      headerAlign: 'center',
      valueGetter: params => params,
      renderCell: params => {
        return (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
              alignItems: 'center',
              height: '100%',
              padding: densityStyles.padding,
              gap: densityStyles.gap,
              color: renderColorByString(params?.value),
            }}
          >
            {params?.value}
          </Box>
        )
      },
    },
    {
      field: 'total',
      headerName: 'Total',
      width: 100,
      flex: 0.5,
      headerAlign: 'center',
      valueGetter: params => params,
      renderCell: params => {
        return (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
              alignItems: 'center',
              height: '100%',
              padding: densityStyles.padding,
              gap: densityStyles.gap,
            }}
          >
            {formatCurrency(params.value)}
          </Box>
        )
      },
    },
    {
      field: 'onboardingTeam',
      headerName: 'Team',
      flex: 0.1,
      minWidth: 100,
      headerAlign: 'center',
      valueGetter: params => {
        const usersArray = Array.isArray(params) ? params : []
        return usersArray.map(user => user.name).join(', ') // Returns names as a string
      },
      renderCell: params => {
        const values = Array.isArray(params?.row?.onboardingTeam) ? params?.row?.onboardingTeam : []
        return (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: '100%',
              flexWrap: 'wrap',
              padding: densityStyles.padding,
              gap: densityStyles.gap,
            }}
            // onClick={() => handleClickEditStart(params)}
            // onClick={() => handleSelectUserRow(params?.rowNode?.id, role)}
          >
            <Tooltip
              // key={`${user.user_uuid || user.id} - ${index}`}
              title={values.map((user, index) => (
                <Stack key={`${user.user_uuid || user.id} - ${index}`}>
                  <Typography sx={{ width: '100%' }}>{user?.role}:</Typography>
                  <Typography>{user?.name}</Typography>
                </Stack>
              ))}
            >
              <AvatarGroup
                max={densityStyles.avatarMax}
                spacing={densityStyles.avatarSpacing}
                slotProps={{
                  additionalAvatar: { sx: { width: densityStyles.avatarSize, height: densityStyles.avatarSize } },
                }}
              >
                {values.length > 0 ? (
                  values.map((user, index) => (
                    // <Tooltip key={`${user.user_uuid || user.id} - ${index}`} title={user?.role}>
                    <Avatar
                      key={`${user.user_uuid || user.id} - ${index}`}
                      src={user.image_512}
                      sx={{ width: densityStyles.avatarSize, height: densityStyles.avatarSize }}
                    />
                    // </Tooltip>
                  ))
                ) : (
                  <>
                    <Avatar sx={{ opacity: 0.1 }} />
                    <Avatar sx={{ opacity: 0.1 }} />
                    <Avatar sx={{ opacity: 0.1 }} />
                  </>
                )}
              </AvatarGroup>
            </Tooltip>
          </Box>
        )
      },
    },
  ]
}
export default renderMtbColumns
/////// renderDataGridColumns:
// 'organizations',
// 'onboardingStatus',
// 'type',
// 'timeCompleted',
// 'dependenciesDueDate',
// 'slaDays',
// 'estLaunchDate',
// 'dateLaunched',
// 'startDate',
// 'endDate',
// 'contractId',
// 'contractedBy',
// 'strategy',
// 'status',
// 'id',
// 'stage',
// 'lastModified',
// 'products',
// 'owner',
// 'implementationFee',
// 'total',

////// renderNcoColumns:
// 'organizations',
// 'onboardingStatus',
// 'type',
// 'timeCompleted',
// 'isGoogleDisplay',
// 'isGoogleSearch',
// 'isLinkedIn',
// 'isMetaAdvertising',
// 'isMicrosoftAdvertising',
// 'isSimplifi',
// 'isSnapchat',
// 'isSpotify',
// 'isTikTok',
// 'isYoutube',
// 'dependenciesDueDate',
// 'slaDays',
// 'estLaunchDate',
// 'dateLaunched',
// 'startDate',
// 'endDate',
// 'contractId',
// 'contractedBy',
// 'strategy',
// 'id',
// 'stage',
// 'lastModified',
// 'owner',
// 'implementationFee',
// 'total',
