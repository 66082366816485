import React, { useState, useEffect } from 'react'
import { Box } from '@mui/material'
import { DataGridPro, useGridApiRef, GridColDef, GridFilterModel, GridRowSelectionModel } from '@mui/x-data-grid-pro'
import { useSnackbarContext } from '../../context/SnackbarProvider/SnackbarProvider.js'
import { useUpdateTask } from '../../api/aws/useUpdateTask.js'
import { useBatchUpdateTasks } from '../../api/aws/tasks/useBatchUpdateTasks.js'
import { useUsers } from '../../api/aws/useUsers.js'
import { useTheme } from '@mui/material'
import { useLayoutContext } from '../../context/LayoutProvider/LayoutProvider.js'
import { gridContainerStyles } from './style.js'
import { Outlet, useNavigate, useParams } from 'react-router-dom'
import { calculateTaskDays, formatDateString, workday } from '../Tasks/taskUtil.js'
// Import the mass update hook
import useMassUpdateTasks, { DynamicMassUpdateData } from '../../api/aws/tasks/useMassUpdateTasks.ts'
import './style.css'
import TaskDrawer from '../TaskDrawer/TaskDrawer.js'
import { useDrawerContext } from '../../context/DrawerProvider/DrawerProvider.js'
import { useFilteredTasks } from '../../api/aws/tasks/useFilteredTasks.ts'
import TasksGridFooter from './TasksGridFooter.tsx'
import TasksGridToolbar from './TasksGridToolbar.tsx'
import { useDeleteMutation } from '../../api/aws/useDeleteMutation.js'

export const initialSortModel = [{ field: 'createdTime', sort: 'desc' }]
export interface Task {
  PK: string
  SK: string
  name: string
  type: string
  taskDays: number
  startDate: string | null
  dateDue: string | null
  priority: string
  highPrio: boolean
  assignees: object[]
  AM: any[]
  DMS: any[]
  masterStatus: string
  status: string
  // ... any additional fields such as creativeLead, PM, SEO, MIA, QA
}
interface TasksGridProps {
  filters: object
  route: string
  context: string
  initialVisibilityState: object
  initialSortModel: object
  statusKey: string
  statusLabel: string
  filterOptions: string[]
  boardTheme: string
  renderFunction: (
    theme: any,
    users: any[],
    density: string,
    handleMassUpdate: (data: any, ids: string[]) => void,
    rowSelectionModel: any[],
    handleTaskClick: (event: any, taskData: any) => void,
    handleClickEditStart: (params: any) => void,
    handleHighPrioChange: (taskId: string, isChecked: boolean) => void,
    handleUserChange: (taskId: string, fieldName: string, newValue: object[]) => void
  ) => GridColDef[]
}

const TasksDataGrid: React.FC<TasksGridProps> = ({
  filters,
  route,
  context,
  initialVisibilityState,
  initialSortModel,
  statusKey,
  statusLabel,
  filterOptions,
  boardTheme,
  renderFunction,
}) => {
  const { PK, SK } = useParams()
  const { pinned } = useLayoutContext()
  const { showSnackbar } = useSnackbarContext()
  const { isDrawerOpen, openDrawer, closeDrawer } = useDrawerContext()
  const theme = useTheme()
  const navigate = useNavigate()
  const apiRef = useGridApiRef()
  const { filteredTasks, isFilteredTasksLoading } = useFilteredTasks(true, context, { ...filters })
  const [isDeleting, setIsDeleting] = useState(false)
  const { mutate: deleteItem } = useDeleteMutation()
  const { users, isUsersLoading } = useUsers(true, ['tasks', 'users'])
  const { mutate: updateTask } = useUpdateTask()
  const { mutate: batchUpdateTasks } = useBatchUpdateTasks()
  const [anchorEl, setAnchorEl] = useState(null)

  // Import and use the mass update hook
  const { handleMassUpdate, isUpdating } = useMassUpdateTasks()

  const [tasks, setTasks] = useState<Task[]>([])
  const [rowSelectionModel, setRowSelectionModel] = useState<GridRowSelectionModel>([])
  const [selectedTask, setSelectedTask] = useState<Task | null>(null)
  const [taskDrawerOpen, setTaskDrawerOpen] = useState(false)
  const [selectedUsers, setSelectedUsers] = useState<object[]>([])
  const [density, setDensity] = useState<string>(() => {
    const savedDensity = localStorage.getItem(`${context}DensityModel`)
    return savedDensity ?? 'standard'
  })
  // massUpdateData can be set via a separate form/popover if needed.
  const [massUpdateData, setMassUpdateData] = useState<DynamicMassUpdateData>({})

  const [filterModel, setFilterModel] = useState<GridFilterModel>(() => {
    const savedFilters = localStorage.getItem(`${context}FilterModel`)
    return savedFilters ? JSON.parse(savedFilters) : { items: [], quickFilterValues: [] }
  })

  const [columnVisibilityModel, setColumnVisibilityModel] = useState(() => {
    const savedVisibility = localStorage.getItem(`${context}ColumnVisibilityModel`)
    return savedVisibility
      ? JSON.parse(savedVisibility)
      : {
          MIA: false,
          QA: false,
          creativeLead: false,
          PM: false,
          dueDate: false,
        }
  })

  const [sortModel, setSortModel] = useState(() => {
    const savedSortModel = localStorage.getItem(`${context}SortModel`)
    return savedSortModel ? JSON.parse(savedSortModel) : initialSortModel
  })
  const clearSelections = () => {
    setMassUpdateData({})
  }
  useEffect(() => {
    if (filteredTasks && !isFilteredTasksLoading) {
      setTasks(filteredTasks)
      if (PK && SK) {
        const selected = filteredTasks?.find(t => t?.SK === SK)
        if (selected) {
          setSelectedTask(selected)
          openDrawer(context)
        }
      }
    }
    return () => {
      closeDrawer() // Close the drawer when component unmounts
      setSelectedTask(null) // Reset selected task
    }
  }, [filteredTasks, isFilteredTasksLoading, openDrawer, PK, SK])

  useEffect(() => {
    if (filterModel) {
      // Save the filter model to localStorage when it changes
      localStorage.setItem(`${context}FilterModel`, JSON.stringify(filterModel))
    }
  }, [filterModel])
  useEffect(() => {
    if (sortModel) {
      // Save the filter model to localStorage when it changes
      localStorage.setItem(`${context}SortModel`, JSON.stringify(sortModel))
    }
  }, [sortModel])
  useEffect(() => {
    if (density) {
      // Save the filter model to localStorage when it changes.0
      localStorage.setItem(`${context}DensityModel`, density)
    }
  }, [density])
  useEffect(() => {
    if (tasks?.length && rowSelectionModel.length === 1) {
      const newTask = tasks.find(task => task.SK === rowSelectionModel[0])
      setSelectedTask(newTask ?? null)
      // Optionally, reset mass update data when only one row is selected.
    } else {
      setSelectedTask(null)
    }
  }, [rowSelectionModel, tasks])

  const showTaskDrawer = (task: Task) => {
    setSelectedTask(task)
  }
  const closeTaskDrawer = () => {
    closeDrawer()
    setSelectedTask(null) // Clear selected task
    navigate(`/${route}`)

    // // Delay navigation slightly to prevent immediate re-opening issues
    // setTimeout(() => {
    //   navigate(`/${route}`)
    // }, 100)
  }
  useEffect(() => {
    if (selectedTask) {
      console.log('selectedTask', selectedTask)
    }
  }, [selectedTask])
  const handleTaskClick = (_: any, taskData: Task) => {
    setSelectedTask(taskData)
    // openDrawer(taskData)
    navigate(`/${encodeURIComponent(route)}/${taskData.PK}/${taskData.SK}/main`)
  }
  const handleMassUpdateChange = e => {
    const { name, value } = e.target
    setMassUpdateData(prev => ({ ...prev, [name]: value }))
  }
  const handleTaskUpdate = async (taskIdentifiers: Task[], updatedData: Partial<Task>) => {
    if (!taskIdentifiers.length) return
    // Ensure all task identifiers have required fields
    const validTasks = taskIdentifiers.filter(task => task.PK && task.SK)
    if (!validTasks.length) {
      console.warn('[handleTaskUpdate] No valid tasks to update.')
      return
    }
    const { PK, SK } = validTasks[0]
    const updateData = { taskPK: PK, taskSK: SK, ...updatedData }
    console.log('Data used for update (single update): ', updateData)

    updateTask(
      { taskPK: PK, taskSK: SK, ...updatedData },
      {
        onSuccess: () => {
          setTasks(prev => prev.map(task => (task.PK === PK && task.SK === SK ? { ...task, ...updatedData } : task)))
        },
        onError: error => console.error('[handleTaskUpdate] Single update failed', error),
      }
    )
  }
  const handleClickEditStart = params => {
    if (rowSelectionModel?.length <= 1) {
      params.api.startCellEditMode({
        id: params.id,
        field: params.field,
      })
    } else {
      return
    }
  }
  // This function processes inline edits. If multiple rows are selected, apply changes to all.
  const processRowUpdate = async (updatedRow: Task, oldRow: Task) => {
    const updatedData: Partial<Task> = {}

    // If "type" changed, recalculate taskDays and dateDue.
    if (updatedRow.type !== oldRow.type) {
      const newTaskDays = calculateTaskDays(updatedRow.type)
      const newDueDate = workday(updatedRow.startDate, newTaskDays)
      updatedData.type = updatedRow.type
      updatedData.taskDays = newTaskDays
      updatedData.dateDue = newDueDate ? formatDateString(newDueDate) : null
    }
    // Other fields...
    if (updatedRow.name !== oldRow.name) updatedData.name = updatedRow.name
    if (updatedRow.masterStatus !== oldRow.masterStatus) updatedData.masterStatus = updatedRow.masterStatus
    if (updatedRow.status && updatedRow.status !== oldRow.status) updatedData.status = updatedRow.status
    if (updatedRow.startDate !== oldRow.startDate) updatedData.startDate = updatedRow.startDate
    if (updatedRow.dateDue !== oldRow.dateDue) updatedData.dateDue = updatedRow.dateDue

    // Add highPrio update check
    // if (updatedRow.highPrio !== oldRow.highPrio) updatedData.highPrio = updatedRow.highPrio

    // ... and so on for other fields if needed

    if (Object.keys(updatedData).length > 0) {
      console.log('RUNNING ONPROCESSUPDATE')
      // If multiple rows are selected, trigger mass update.
      if (rowSelectionModel.length > 1) {
        handleMassUpdate(updatedData, rowSelectionModel as string[])
        setTasks(prev => prev.map(task => (rowSelectionModel.includes(task.SK) ? { ...task, ...updatedData } : task)))
        return { ...updatedRow, ...updatedData }
      } else {
        // Single row update logic
        try {
          await handleTaskUpdate([{ PK: updatedRow.PK, SK: updatedRow.SK }], updatedData, false)
          setTasks(prev =>
            prev.map(task =>
              task.PK === updatedRow.PK && task.SK === updatedRow.SK ? { ...task, ...updatedData } : task
            )
          )
          return { ...updatedRow, ...updatedData }
        } catch (error) {
          console.error('Error updating row:', error)
          throw error
        }
      }
    }
    return updatedRow
  }
  const handleHighPrioChange = (taskId: string, isChecked: boolean) => {
    // Find the task by its SK.
    const task = tasks.find(t => t.SK === taskId)
    if (!task) {
      console.warn('[handleHighPrioChange] No valid task found for update.')
      return
    }
    // Optimistically update the local state so the checkbox reflects the change immediately.
    setTasks(prev => prev.map(t => (t.SK === taskId ? { ...t, highPrio: isChecked } : t)))
    // Trigger the single update.
    handleTaskUpdate([{ PK: task.PK, SK: task.SK }], { highPrio: isChecked }, false)
  }
  const handleUserChange = (taskId: string, fieldName: string, newValue: object[]) => {
    // newValue is an array of user objects
    const task = tasks.find(t => t.SK === taskId)
    if (!task) {
      console.warn('[handleUserChange] No valid task found for update.')
      return
    }
    // Optimistically update local state.
    setTasks(prev => prev.map(t => (t.SK === taskId ? { ...t, [fieldName]: newValue } : t)))
    // Trigger the update (using your single update logic)
    handleTaskUpdate([{ PK: task.PK, SK: task.SK }], { [fieldName]: newValue }, false)
  }
  const handleDensityChange = (newDensity: string) => {
    setDensity(newDensity)
    localStorage.setItem(`${context}DensityModel`, newDensity)
  }
  const handleFilterModelChange = (model: GridFilterModel) => {
    setFilterModel(model)
  }
  const handleRowSelectionModelChange = (rowSelectionModel: GridRowSelectionModel) => {
    setRowSelectionModel([...rowSelectionModel])
    if (rowSelectionModel?.length === 1) {
      setRowSelectionModel(rowSelectionModel)

      const selectedRowId = rowSelectionModel?.[0]
      const rowSK = selectedRowId?.split('::')?.[1]
      const task = tasks?.find(task => task?.SK === rowSK)
      if (task?.assignees?.length > 0) {
        setMassUpdateData({ assignees: task?.assignees })
      }
    } else {
      setRowSelectionModel([...rowSelectionModel])
      setMassUpdateData({})
    }
  }
  const handleClearSorting = e => {
    e.preventDefault()
    setSortModel([...initialSortModel]) // Reset the sort model
    localStorage.setItem(`${context}SortModel`, JSON.stringify(initialSortModel)) // Also update localStorage
  }
  const handleClearVisibility = event => {
    event.preventDefault()
    setColumnVisibilityModel(initialVisibilityState)
    localStorage.setItem(`${context}ColumnVisibilityModel`, JSON.stringify(initialVisibilityState))
  }
  const handleClosePopover = () => {
    if (selectedTask) {
      const selectedRowId = `${selectedTask.PK}::${selectedTask.SK}`
      const rowElement = apiRef.current.getRowElement(selectedRowId)

      if (rowElement) {
        rowElement.classList.remove('highlighted-row')
      }
    }
    setAnchorEl(null)
    if (rowSelectionModel.length > 0) {
      rowSelectionModel.forEach(id => {
        apiRef.current.selectRow(id, false)
      })
      setRowSelectionModel([])
      setSelectedTask(null)
    }
  }
  const handleDeleteClick = () => {
    // Get selected row ID from rowSelectionModel
    const selectedRowId = rowSelectionModel?.[0]
    const rowSK = selectedRowId?.split('::')?.[1]
    if (!selectedRowId) return

    // Find the selected task
    const newSelectedTask = tasks.find(task => task.SK === rowSK)
    setSelectedTask(newSelectedTask || null)

    // Get the row DOM element
    const rowElement = apiRef.current.getRowElement(selectedRowId)

    if (rowElement) {
      console.log('rowElement', rowElement.classList)
      // Add a highlight class to make sure it's fully visible
      rowElement.classList.add('highlighted-row')

      // Set popover anchor to the row
      setAnchorEl(rowElement)
    }
  }

  // const handleDeleteClick = event => {
  //   setAnchorEl(event.currentTarget)
  // }
  const handleConfirmDelete = async () => {
    setIsDeleting(true)
    if (selectedTask) {
      const params = {
        endpoint: `/aws/tasks/delete/${selectedTask.PK}/${selectedTask.SK}`,
        table: 'TASKS_TABLE',
      }
      deleteItem(params, {
        onSuccess: message => {
          setSelectedTask(null)
          showSnackbar(message, 'success')
          setIsDeleting(false)
        },
        onError: error => {
          showSnackbar(error.message, 'error')
          setIsDeleting(false)
        },
      })
    }
  }

  const columns: GridColDef[] = renderFunction(
    theme,
    users ?? [],
    density,
    // rowSelectionModel.map(id => String(id)), // Convert to string array
    handleMassUpdate,
    rowSelectionModel,
    handleTaskClick,
    handleClickEditStart,
    handleHighPrioChange,
    handleUserChange
  )

  return (
    <Box
      sx={{
        position: 'relative',
        ...gridContainerStyles,
        pt: pinned ? '60px' : 0.8,
        transition: 'all 0.3s ease',
        height: '98%',
      }}
    >
      <DataGridPro
        initialState={{
          sorting: { sortModel },
          filter: { filterModel },
          columns: { columnVisibilityModel },
        }}
        slots={{
          toolbar: TasksGridToolbar,
          footer: TasksGridFooter,
        }}
        slotProps={{
          toolbar: {
            filterModel: filterModel,
            selectionModel: rowSelectionModel,
            // isTaskView: isTaskView,
            // onChangeBoardView: handleViewState,
            initialVisibilityState: initialVisibilityState,
            visibilityModel: columnVisibilityModel,
            initialSortModel: initialSortModel,
            sortModel: sortModel,
            setFilterModel: setFilterModel,
            onClearSorting: handleClearSorting,
            onClearVisibility: handleClearVisibility,
            statusKey: statusKey,
            statusLabel: statusLabel,
            filterOptions: filterOptions,
            context: context,
            boardTheme: boardTheme,
          },
          footer: {
            users: users,
            selectedData: selectedTask,
            isLoading: isUsersLoading,
            massUpdateData: massUpdateData,
            selectionModel: rowSelectionModel,
            rowCount: tasks?.length,
            onChange: handleMassUpdateChange,
            onUpdate: handleMassUpdate,
            onClickDelete: handleDeleteClick,
            onDelete: handleConfirmDelete,
            isDeleting: isDeleting,
            onClosePopover: handleClosePopover,
            onClear: clearSelections,
            tasks: tasks,
            anchorEl,
            context: context,
          },
        }}
        rows={tasks}
        columns={columns}
        rowHeight={52}
        getRowId={row => `${row.PK}::${row.SK}`}
        checkboxSelection
        onCellEditStart={(params, event) => {
          if (rowSelectionModel.length > 1) {
            event.defaultMuiPrevented = true // Prevent editing
          }
        }}
        disableRowSelectionOnClick
        rowSelectionModel={rowSelectionModel}
        onRowSelectionModelChange={newSelectionModel => handleRowSelectionModelChange(newSelectionModel)}
        columnVisibilityModel={columnVisibilityModel}
        onColumnVisibilityModelChange={newModel => {
          setColumnVisibilityModel(newModel)
          localStorage.setItem(`${context}ColumnVisibilityModel`, JSON.stringify(newModel))
        }}
        sortModel={sortModel}
        onSortModelChange={newModel => {
          setSortModel(newModel)
          localStorage.setItem(`${context}SortModel`, JSON.stringify(newModel))
        }}
        sx={{
          '& .MuiDataGrid-cell': {
            padding: '0px', // You already removed internal padding
            // borderRight: '0.5px solid rgba(0, 0, 0, 0.1)', // Vertical separator between cells
            // borderBottom: '0.5px solid rgba(0, 0, 0, 0.1)', // Horizontal separator between rows
          },
          '& .MuiDataGrid-columnHeaders': {
            borderBottom: 'none',
          },
          '& .MuiDataGrid-columnHeaderTitleContainer': {
            padding: '0px',
          },
        }}
        onDensityChange={handleDensityChange}
        getRowClassName={params => (params.row.highPrio ? 'high-priority-row' : '')}
        filterModel={filterModel}
        onFilterModelChange={handleFilterModelChange}
        apiRef={apiRef}
        processRowUpdate={processRowUpdate}
        onProcessRowUpdateError={error => console.error('Row Update Error:', error)}
        loading={isFilteredTasksLoading}
      />
      {isDrawerOpen && <TaskDrawer closeTaskDrawer={closeTaskDrawer} context={context} />}
    </Box>
  )
}

export default TasksDataGrid
