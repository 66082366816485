import React, { useEffect, useState } from 'react'
import { Box, Chip, Typography, useTheme, FormControl, Select, MenuItem, InputLabel } from '@mui/material'

const renderContextLabel = context => {
  switch (context) {
    case 'dealStatus':
      return 'Deal Status'
    case 'status':
      return 'Status'
    case 'ncoStatus':
      return 'NCO Status'
    case 'stage':
    case 'dealStage':
      return 'Stage'
    case 'type':
      return 'Type'
    case 'billingStatus':
      return 'Billing Status'
    case 'onboardingStatus':
      return 'Onboarding Status'
    case 'masterStatus':
      return 'Master Status'
    default:
      return context
  }
}

const CustomStatusFilter = ({ options, setterFn, filterModel, value, onChange, context, label }) => {
  const theme = useTheme()
  const [selectedValues, setSelectedValues] = useState([])

  useEffect(() => {
    if (filterModel) {
      const currentFilter = filterModel.items.find(item => item.field === context)
      if (currentFilter) {
        setSelectedValues(currentFilter.value || [])
      } else {
        setSelectedValues([])
      }
    }
  }, [filterModel, context])

  const handleStatusChange = event => {
    const selectedOptions = event.target.value.filter(Boolean) // Ensure no null/undefined
    setSelectedValues(selectedOptions)

    setterFn(selectedOptions)
    onChange(context, selectedOptions.length > 0 ? selectedOptions : null) // Remove filter if empty
  }
  return (
    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minWidth: 200, height: 70 }}>
      <FormControl sx={{ display: 'flex' }} fullWidth>
        <InputLabel id={`${context}-select-label`} shrink={true}>
          {renderContextLabel(context)}
        </InputLabel>
        <Select
          sx={{ height: 30, pt: 2.5, pb: 2.5, borderRadius: '4px' }}
          name={context || ''}
          label={renderContextLabel(context) || ''}
          labelId={`${context}-select-label`}
          id={`${context}-select`}
          multiple
          value={selectedValues}
          onChange={handleStatusChange}
          displayEmpty
          renderValue={selected =>
            !selected.length ? (
              <Typography key={'default-value'} sx={{ color: theme.palette.text.secondary }}>
                {'—'}
              </Typography>
            ) : (
              <Box display='flex' flexWrap='wrap' gap={1}>
                {selected.map((value, index) => {
                  const option = options.find(opt => opt.value === value)
                  return (
                    <Chip
                      key={`${index}-${value}`}
                      size='small'
                      style={{
                        backgroundColor: context === 'type' ? '' : option?.color || 'gray',
                        border: context === 'type' ? `2px solid ${option?.color}` : '',
                        color: '#fff',
                        width: 14,
                        height: 14,
                      }}
                    />
                  )
                })}
              </Box>
            )
          }
        >
          {options.map(status => (
            <MenuItem key={`${status?.color}-${status.value}`} value={status.value}>
              <Chip
                size='small'
                style={{
                  backgroundColor: context === 'type' ? '' : status.color,
                  border: context === 'type' ? `2px solid ${status.color}` : '',
                  width: 14,
                  height: 14,
                  borderRadius: '50%',
                  margin: '5px',
                }}
              />
              <Typography variant='subtitle1' ml={1}>
                {status.label}
              </Typography>
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  )
}

export default CustomStatusFilter

// import React, { useEffect, useState } from 'react'
// import { Box, Chip, Typography, useTheme, FormControl, Select, MenuItem, InputLabel } from '@mui/material'

// const renderContextLabel = context => {
//   switch (context) {
//     case 'dealStatus':
//       return 'Deal Status'
//     case 'status':
//       return 'Status'
//     case 'ncoStatus':
//       return 'NCO Status'
//     case 'stage':
//     case 'dealStage':
//       return 'Stage'
//     case 'type':
//       return 'Type'
//     case 'billingStatus':
//       return 'Billing Status'
//     default:
//       return context
//   }
// }

// const CustomStatusFilter = ({ options, setterFn, filterModel, value, onChange, context, label }) => {
//   const theme = useTheme()
//   const [selectedValues, setSelectedValues] = useState([])

//   useEffect(() => {
//     if (filterModel) {
//       const currentFilter = filterModel.items.find(item => item.field === context)
//       if (currentFilter) {
//         setSelectedValues(currentFilter.value || [])
//       } else {
//         setSelectedValues([])
//       }
//     }
//   }, [filterModel, context])

//   const handleStatusChange = event => {
//     const selectedOptions = event.target.value // This is now an array of selected values
//     setSelectedValues(selectedOptions)

//     // Update the parent state & filter model
//     setterFn(selectedOptions)
//     onChange(context, selectedOptions.length > 0 ? selectedOptions : null) // Remove filter if empty
//   }

//   return (
//     <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minWidth: 200, height: 70 }}>
//       <FormControl sx={{ display: 'flex' }} fullWidth>
//         <InputLabel id={`${context}-select-label`} shrink={true}>
//           {renderContextLabel(context) || label}
//         </InputLabel>
//         <Select
//           sx={{ height: 30, pt: 2.5, pb: 2.5, borderRadius: '4px' }}
//           name={context ? context : ''}
//           label={renderContextLabel(context) || ''}
//           labelId={`${context}-select-label`}
//           id={`${context}-select`}
//           multiple
//           value={selectedValues}
//           onChange={handleStatusChange}
//           displayEmpty
//           renderValue={selected =>
//             Array.isArray(selected) && selected.length === 0 ? (
//               <Typography key={'default-value'} sx={{ color: theme.palette.text.secondary }}>
//                 {'—'}
//               </Typography>
//             ) : Array.isArray(selected) && selected.length === 1 ? (
//               <Box display='flex' flexWrap='wrap' gap={1}>
//                 {selected.map(value => (
//                   <Box key={value} display='flex' flexWrap='wrap' alignItems='center' gap={1}>
//                     <Chip
//                       key={value}
//                       size='small'
//                       style={{
//                         backgroundColor:
//                           context === 'type' ? '' : options.find(opt => opt.value === value)?.color || 'gray',
//                         border:
//                           context === 'type' ? `2px solid ${options.find(opt => opt.value === value)?.color}` : '',
//                         color: '#fff',
//                         width: 14,
//                         height: 14,
//                       }}
//                     />
//                     {context === 'type' ? null : <Typography key={`${value}-typography`}>{value}</Typography>}
//                   </Box>
//                 ))}
//               </Box>
//             ) : (
//               <Box display='flex' flexWrap='wrap' gap={1}>
//                 {Array.isArray(selected) &&
//                   selected.map(value => (
//                     <Chip
//                       key={value}
//                       size='small'
//                       style={{
//                         backgroundColor:
//                           context === 'type' ? '' : options.find(opt => opt.value === value)?.color || 'gray',
//                         border:
//                           context === 'type' ? `2px solid ${options.find(opt => opt.value === value)?.color}` : '',
//                         color: '#fff',
//                         width: 14,
//                         height: 14,
//                       }}
//                     />
//                   ))}
//               </Box>
//             )
//           }
//         >
//           {options.map(status => (
//             <MenuItem key={status.label} value={status.value}>
//               <Chip
//                 size='small'
//                 style={{
//                   backgroundColor: context === 'type' ? '' : status.color,
//                   border: context === 'type' ? `2px solid ${status.color}` : '',
//                   width: 14,
//                   height: 14,
//                   borderRadius: '50%',
//                   margin: '5px',
//                 }}
//               />
//               <Typography variant='subtitle1' ml={1}>
//                 {status.label}
//               </Typography>
//             </MenuItem>
//           ))}
//         </Select>
//       </FormControl>
//     </Box>
//   )
// }

// export default CustomStatusFilter
