import React, { useState, useEffect } from 'react'
import TaskDetails from './TaskDetails.tsx'
import TaskMessages from '../Comments/TaskMessages.tsx'
import { Button, CircularProgress, Drawer, Grid, InputLabel, Typography } from '@mui/material'
import { Box, Tabs, useTheme } from '@mui/material'
import { styled } from '@mui/material/styles'
import { Tab as MuiTab } from '@mui/material'
import { throttle } from 'lodash'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { useTask } from '../../api/aws/tasks/useTask.ts'
import FileManagement from '../DataGrid/components/FileManagement.js'
import { useDeal } from '../../api/aws/useDeal.js'
import { useOrganizationById } from '../../api/aws/useOrganization.js'
import DriveFoldersById from '../Google/DriveFoldersById.tsx'
import { useDrawerContext } from '../../context/DrawerProvider/DrawerProvider.js'
import ExpandCollapseButton from '../ui/expand-collapse-button.tsx'
import TaskDrawerHeader from './TaskDrawerHeader.js'
import { useLayoutContext } from '../../context/LayoutProvider/LayoutProvider.js'
import { boardViewMapping } from '../../utility/boardViewMapping.ts'
import { useChangeView } from '../../utility/useChangeView.ts'
import { useUpdateTaskItems } from '../../api/aws/tasks/useUpdateTaskItems.tsx'
import SaveIconButton from '../ui/save-icon-button.js'

export const DRAWER_CONFIG = context => {
  return [
    {
      tabValue: 0,
      label: context === 'creative' ? 'Project Details' : 'Main',
      path: 'main',
      component: TaskDetails,
    },
    {
      tabValue: 1,
      label: context === 'creative' ? 'Project Messages' : 'Messages',
      path: 'messages',
      component: TaskMessages,
    },
    {
      tabValue: 2,
      label: 'File Management',
      path: 'filemgmt',
      component: DriveFoldersById,
    },
  ]
}

const StyledTab = styled(MuiTab)(({ theme }) => ({
  '&.Mui-selected': {
    color: 'rgb(121 212 255)',
  },
}))

const TaskDrawer = ({ closeTaskDrawer, context }) => {
  const { PK, SK } = useParams()
  const { pinned } = useLayoutContext()
  const location = useLocation()
  const navigate = useNavigate()
  const [tabIndex, setTabIndex] = useState(0)
  const [expanded, setExpanded] = useState(true)
  const handleChangeView = useChangeView()
  // Instead of an "updates" state, we use localAddtlNotes
  const [taskDrawerWidth, setTaskDrawerWidth] = useState(null)
  const { task, isTaskLoading, isTaskError } = useTask(PK, SK)
  const { deal, isDealLoading, isDealError } = useDeal(task?.dealId)
  const [localAddtlNotes, setLocalAddtlNotes] = useState(task?.addtlNotes || '')
  const { organization, isOrganizationLoading, isOrganizationError } = useOrganizationById(task?.orgId)
  const { mutate: updateTaskItem } = useUpdateTaskItems()
  const { isDrawerOpen, closeDrawer } = useDrawerContext()
  const theme = useTheme()
  const drawerTransitionDuration = 300

  const handleChange = (event, newValue) => {
    const baseRoute = location.pathname.split('/')[1]
    if (newValue === 1) {
      setTabIndex(newValue)
      navigate(`/${baseRoute}/${PK}/${SK}/messages`)
    } else if (newValue === 2) {
      setTabIndex(newValue)
      navigate(`/${baseRoute}/${PK}/${SK}/filemgmt`)
    } else {
      setTabIndex(newValue)
      navigate(`/${baseRoute}/${PK}/${SK}/main`)
    }
  }

  const handleMouseDown = e => {
    document.body.style.userSelect = 'none'
    document.addEventListener('mousemove', handleMouseMove)
    document.addEventListener('mouseup', handleMouseUp)
  }

  const logWidth = throttle(width => {
    console.log(`Current drawer width: ${width}px`)
  }, 1000)

  const handleMouseMove = e => {
    const newWidth = window.innerWidth - e.clientX
    const minWidth = 200
    const maxWidth = window.innerWidth * 0.8
    const adjustedWidth = Math.min(Math.max(minWidth, newWidth), maxWidth)
    setTaskDrawerWidth(adjustedWidth)
    logWidth(adjustedWidth)
  }

  useEffect(() => {
    if (!isDrawerOpen) {
      const timer = setTimeout(() => {
        if (!isDrawerOpen) {
          setTaskDrawerWidth(window.innerWidth * 0.8)
        }
      }, drawerTransitionDuration)
      return () => clearTimeout(timer)
    }
    const initialWidth = window.innerWidth * 0.8
    setTaskDrawerWidth(initialWidth)
    const handleResize = () => {
      const adjustedWidth = Math.min(Math.max(500, window.innerWidth * 0.8), window.innerWidth * 0.9)
      setTaskDrawerWidth(adjustedWidth)
    }
    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [isDrawerOpen])

  const handleMouseUp = () => {
    document.body.style.removeProperty('user-select')
    document.removeEventListener('mousemove', handleMouseMove)
    document.removeEventListener('mouseup', handleMouseUp)
  }

  useEffect(() => {
    const baseRoute = location.pathname.split('/')[1]
    if (location.pathname.includes(`/${baseRoute}/${PK}/${SK}/main`)) {
      setTabIndex(0)
    } else if (location.pathname.includes(`/${baseRoute}/${PK}/${SK}/messages`)) {
      setTabIndex(1)
    } else if (location.pathname.includes(`/${baseRoute}/${PK}/${SK}/filemgmt`)) {
      setTabIndex(2)
    } else {
      setTabIndex(0)
    }
  }, [location.pathname, PK, SK])

  // If task updates externally, update the local state
  useEffect(() => {
    if (task) {
      setLocalAddtlNotes(task.addtlNotes || '')
    }
  }, [task])

  const DRAWER_TABS = DRAWER_CONFIG(context)
  const isLoading = isTaskLoading || isDealLoading || isOrganizationLoading

  const handleNavigate = taskRoute => {
    const route = taskRoute.includes('nco') ? '/nco' : '/teamtasks'
    const newView = boardViewMapping[route]
    if (newView) {
      handleChangeView({ newView })
    }
    closeTaskDrawer()
    navigate(`${taskRoute}`)
  }

  // Update localAddtlNotes on editor changes (only update state; no saving yet)
  const handleFieldUpdates = (field, newValue) => {
    if (field === 'addtlNotes') {
      setLocalAddtlNotes(newValue)
    }
  }

  // Save only when the Save button is clicked.
  const handleSave = () => {
    // Only update if there is a change
    if (localAddtlNotes !== task?.addtlNotes) {
      const updatePayload = {
        primaryKey: 'PK',
        sortKey: 'SK',
        tableName: 'TASKS_TABLE',
        pkValue: task?.PK,
        skValue: task?.SK,
        fieldName: 'addtlNotes',
        newValue: localAddtlNotes,
      }
      console.log('Saving updatePayload:', updatePayload)
      updateTaskItem({ updates: [updatePayload] })
    }
    // Optionally, close the drawer after saving
    // closeTaskDrawer()
  }

  return (
    <>
      <Drawer
        anchor='right'
        open={isDrawerOpen}
        onClose={closeTaskDrawer}
        PaperProps={{
          sx: {
            overflow: 'hidden',
            height: '100vh',
            pt: pinned ? '60px' : 0.8,
            transition: 'padding 0.3s ease, width 0.1s ease',
            width: taskDrawerWidth,
            bgcolor: theme.palette.background.default,
          },
        }}
      >
        <Box sx={{ transition: 'padding 0.3s ease', height: 'calc(100vh - 120px)' }}>
          <ExpandCollapseButton
            expanded={expanded}
            setExpanded={setExpanded}
            displayElement={
              <Grid container sx={{ display: 'flex', alignItems: 'center' }}>
                <Grid item xs={12}>
                  <Typography variant='h6' sx={{ flexGrow: 1, textAlign: 'left' }}>
                    {task?.name}
                  </Typography>
                </Grid>
              </Grid>
            }
          >
            <TaskDrawerHeader task={task} organization={organization} />
          </ExpandCollapseButton>
          {isLoading ? (
            <CircularProgress />
          ) : (
            <>
              <Tabs value={tabIndex} onChange={handleChange}>
                {DRAWER_TABS.map((tab, index) => (
                  <StyledTab key={index} label={tab.label} />
                ))}
              </Tabs>
              <Box
                sx={{
                  flex: 1,
                  height: expanded ? 'calc(100vh - 295px)' : 'calc(100vh - 155px)',
                  maxHeight: '100vh',
                  overflowY: 'auto',
                  bgcolor: theme.palette.background.section,
                  p: 2,
                }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    // marginTop: '20px',
                    overflow: 'visible',
                  }}
                >
                  {tabIndex === 0 && task && (
                    <TaskDetails
                      task={task}
                      onNavigate={handleNavigate}
                      onChange={handleFieldUpdates}
                      onSave={handleSave}
                      addtlNotes={localAddtlNotes}
                      expanded={expanded}
                    />
                  )}
                  {tabIndex === 1 && task && <TaskMessages task={task} />}
                  {tabIndex === 2 && task && <DriveFoldersById folderId={task?.taskFolderId} isParentFolder={false} />}
                </Box>
              </Box>
            </>
          )}
          <Box
            sx={{
              width: '5px',
              cursor: 'ew-resize',
              backgroundColor: 'lightgray',
              height: '100%',
              position: 'absolute',
              left: 0,
              top: 0,
              zIndex: 1200,
            }}
            onMouseDown={handleMouseDown}
          />
        </Box>
      </Drawer>
    </>
  )
}

export default TaskDrawer

// import React, { useState, useEffect, useCallback } from 'react'
// import TaskDetails from './TaskDetails'
// // import TaskMessages from './TaskMessages/TaskMessages'
// import TaskMessages from '../Comments/TaskMessages.tsx'
// import { Button, CircularProgress, Drawer, Grid, InputLabel, Typography } from '@mui/material'
// import { Box, Tabs, TableCell, useTheme } from '@mui/material'
// import { MyStyledComponent, CustomBox } from '../../style/styleElements'
// import { styled } from '@mui/material/styles'
// import { Tab as MuiTab } from '@mui/material'
// import { throttle } from 'lodash'
// import { useLocation, useNavigate, useParams } from 'react-router-dom'
// import { useTask } from '../../api/aws/tasks/useTask.ts'
// import FileManagement from '../DataGrid/components/FileManagement.js'
// import { useDeal } from '../../api/aws/useDeal.js'
// import { useOrganizationById } from '../../api/aws/useOrganization.js'
// import DriveFoldersById from '../Google/DriveFoldersById.tsx'
// import { useDrawerContext } from '../../context/DrawerProvider/DrawerProvider.js'
// import ExpandCollapseButton from '../ui/expand-collapse-button.tsx'
// import TaskDrawerHeader from './TaskDrawerHeader.js'
// import { useLayoutContext } from '../../context/LayoutProvider/LayoutProvider.js'
// import { boardViewMapping } from '../../utility/boardViewMapping.ts'
// import { useChangeView } from '../../utility/useChangeView.ts'
// import { useUpdateTaskItems } from '../../api/aws/tasks/useUpdateTaskItems.tsx'
// import SaveIconButton from '../ui/save-icon-button.js'
// import { debounce } from 'lodash'

// export const DRAWER_CONFIG = context => {
//   return [
//     {
//       tabValue: 0,
//       label: context === 'creative' ? 'Project Details' : 'Main',
//       path: 'main',
//       component: TaskDetails,
//     },
//     {
//       tabValue: 1,
//       label: context === 'creative' ? 'Project Messages' : 'Messages',

//       path: 'messages',
//       component: TaskMessages,
//     },
//     {
//       tabValue: 2,
//       label: 'File Management',
//       path: 'filemgmt',
//       component: DriveFoldersById,
//     },
//   ]
// }
// const StyledTab = styled(MuiTab)(({ theme }) => ({
//   '&.Mui-selected': {
//     color: 'rgb(121 212 255)', // Change to the desired color
//   },
// }))
// const TaskDrawer = ({ closeTaskDrawer, context }) => {
//   const { PK, SK } = useParams()
//   const { pinned } = useLayoutContext()
//   const location = useLocation()
//   const navigate = useNavigate()
//   const [tabIndex, setTabIndex] = useState(0)
//   const [expanded, setExpanded] = useState(false)
//   const handleChangeView = useChangeView()
//   const [updates, setUpdates] = useState(null)
//   const [taskDrawerWidth, setTaskDrawerWidth] = useState(null) // Initial width of the drawer
//   const { task, isTaskLoading, isTaskError } = useTask(PK, SK)
//   const { deal, isDealLoading, isDealError } = useDeal(task?.dealId)
//   const { organization, isOrganizationLoading, isOrganizationError } = useOrganizationById(task?.orgId)
//   const [localAddtlNotes, setLocalAddtlNotes] = useState(task?.addtlNotes || '')
//   const { mutate: updateTaskItem } = useUpdateTaskItems()
//   const { isDrawerOpen, closeDrawer } = useDrawerContext()
//   const theme = useTheme()
//   const drawerBackgroundColor = theme.palette.background.default
//   const drawerTransitionDuration = 300

//   const handleChange = (event, newValue) => {
//     const baseRoute = location.pathname.split('/')[1]
//     if (newValue === 1) {
//       setTabIndex(newValue)
//       navigate(`/${baseRoute}/${PK}/${SK}/messages`)
//     } else if (newValue === 2) {
//       setTabIndex(newValue)
//       navigate(`/${baseRoute}/${PK}/${SK}/filemgmt`)
//     } else {
//       setTabIndex(newValue)
//       navigate(`/${baseRoute}/${PK}/${SK}/main`)
//     }
//   }
//   const handleMouseDown = e => {
//     // Apply styles to prevent text selection
//     document.body.style.userSelect = 'none'
//     document.body.style.webkitUserSelect = 'none' // Safari
//     document.body.style.mozUserSelect = 'none' // Firefox
//     document.body.style.msUserSelect = 'none' // Internet Explorer/Edge

//     document.addEventListener('mousemove', handleMouseMove)
//     document.addEventListener('mouseup', handleMouseUp)
//   }

//   const logWidth = throttle(width => {
//     console.log(`Current drawer width: ${width}px`)
//   }, 1000) // Throttle logging to once per second
//   const handleMouseMove = e => {
//     const newWidth = window.innerWidth - e.clientX
//     const minWidth = 200 // Set your global minimum width here
//     const maxWidth = window.innerWidth * 0.8 // Set your global maximum width here
//     const adjustedWidth = Math.min(Math.max(minWidth, newWidth), maxWidth)

//     setTaskDrawerWidth(adjustedWidth)

//     // Call the throttled logging function
//     logWidth(adjustedWidth)
//   }

//   useEffect(() => {
//     if (!isDrawerOpen) {
//       // Wait for the drawer closing animation to complete before resetting the width
//       const timer = setTimeout(() => {
//         if (!isDrawerOpen) {
//           // Reset the drawer width only if the drawer remains closed
//           setTaskDrawerWidth(window.innerWidth * 0.8)
//         }
//       }, drawerTransitionDuration)

//       return () => clearTimeout(timer)
//     }
//     // Set the drawerWidth to 80% of the window's width when the component mounts
//     const initialWidth = window.innerWidth * 0.8
//     setTaskDrawerWidth(initialWidth)

//     // Optional: Adjust the drawer width dynamically on window resize
//     const handleResize = () => {
//       const adjustedWidth = Math.min(Math.max(500, window.innerWidth * 0.8), window.innerWidth * 0.9)
//       setTaskDrawerWidth(adjustedWidth)
//     }

//     window.addEventListener('resize', handleResize)
//     return () => window.removeEventListener('resize', handleResize)
//   }, [isDrawerOpen]) // Empty dependency array ensures this runs once on mount

//   const handleMouseUp = () => {
//     // Remove the styles to re-enable text selection
//     document.body.style.removeProperty('user-select')
//     document.body.style.removeProperty('webkit-user-select')
//     document.body.style.removeProperty('moz-user-select')
//     document.body.style.removeProperty('ms-user-select')
//     document.removeEventListener('mousemove', handleMouseMove)
//     document.removeEventListener('mouseup', handleMouseUp)
//   }

//   useEffect(() => {
//     // console.log('Current Path:', location.pathname) // ✅ Debugging route behavior

//     // ✅ Extract the base route dynamically (either "teamtasks" or "creative")
//     const baseRoute = location.pathname.split('/')[1]

//     if (location.pathname.includes(`/${baseRoute}/${PK}/${SK}/main`)) {
//       setTabIndex(0)
//     } else if (location.pathname.includes(`/${baseRoute}/${PK}/${SK}/messages`)) {
//       setTabIndex(1)
//     } else if (location.pathname.includes(`/${baseRoute}/${PK}/${SK}/filemgmt`)) {
//       setTabIndex(2)
//     } else {
//       setTabIndex(0)
//     }
//   }, [location.pathname, PK, SK])

//   const brandingGuide = ['Assemble work into Brand Template', 'Brand Messaging', 'Brand Patterns', 'Brand Photography']

//   const createPhotoVideoTravelList = brandingGuide.map((name, index, array) => {
//     const key = name
//       .replace(/[^a-zA-Z0-9 ]/g, '') // Remove special characters
//       .replace(/\s+(.)/g, (_, char) => char.toUpperCase()) // Convert to camelCase
//       .replace(/\s/g, '') // Remove spaces
//       .replace(/^(.)/, (_, char) => char.toLowerCase()) // Ensure first character is lowercase

//     return {
//       key,
//       name,
//       role: 'PM',
//       dependencies:
//         index === 0
//           ? []
//           : [
//               array[index - 1] // Ensure dependencies use the previous key
//                 .replace(/[^a-zA-Z0-9 ]/g, '')
//                 .replace(/\s+(.)/g, (_, char) => char.toUpperCase())
//                 .replace(/\s/g, '')
//                 .replace(/^(.)/, (_, char) => char.toLowerCase()),
//             ],
//       id: index + 1,
//       relatedOrgField: 'creativeProgress',
//     }
//   })

//   console.log(createPhotoVideoTravelList)

//   const DRAWER_TABS = DRAWER_CONFIG(context)
//   const isLoading = isTaskLoading || isDealLoading || isOrganizationLoading

//   const handleNavigate = taskRoute => {
//     console.log('taskRoute', taskRoute)
//     const route = taskRoute.includes('nco') ? '/nco' : '/teamtasks'
//     const newView = boardViewMapping[route]
//     console.log('newView', newView)
//     if (newView) {
//       handleChangeView({ newView })
//     }
//     closeTaskDrawer()
//     navigate(`${taskRoute}`)
//   }
//   const debouncedSave = useCallback(
//     debounce((field, newContent) => {
//       const updatePayload = {
//         primaryKey: 'PK',
//         sortKey: 'SK',
//         tableName: 'TASKS_TABLE',
//         pkValue: task?.PK,
//         skValue: task?.SK,
//         fieldName: field,
//         newValue: newContent,
//       }
//       updateTaskItem({ updates: [updatePayload] })
//     }, 1000), // Adjust the delay as needed
//     [task, updateTaskItem]
//   )

//   const handleFieldUpdates = (field, newValue) => {
//     setLocalAddtlNotes(newValue)
//     debouncedSave(field, newValue)
//   }
//   const handleSave = () => {
//     if (updates && updates.addtlNotes !== undefined) {
//       // Construct the update object.
//       // Adjust these values to match your DynamoDB table schema.
//       const updatePayload = {
//         primaryKey: 'PK', // Your primary key name (example)
//         sortKey: 'SK', // Your sort key name (if applicable)
//         tableName: 'TASKS_TABLE', // Your table name
//         pkValue: task?.PK, // Unique identifier for the task
//         skValue: task?.SK, // If using a sort key
//         fieldName: 'addtlNotes',
//         newValue: updates.addtlNotes,
//       }
//       console.log('updatePayload', updatePayload)
//       // updateTaskItem({ updates: [updatePayload] })
//     }
//     // Optionally close the drawer afterwards
//     // closeTaskDrawer()
//   }

//   return (
//     <>
//       <Drawer
//         anchor='right'
//         open={isDrawerOpen}
//         onClose={closeTaskDrawer}
//         PaperProps={{
//           sx: {
//             overflow: 'hidden',
//             height: '100vh',
//             pt: pinned ? '60px' : 0.8,
//             transition: 'padding 0.3s ease, width 0.1s ease',
//             width: taskDrawerWidth, // Use the state to set the width
//             bgcolor: theme.palette.background.default,
//           },
//         }}
//       >
//         <Box sx={{ transition: 'padding 0.3s ease', height: 'calc(100vh - 120px)' }}>
//           <ExpandCollapseButton
//             expanded={expanded}
//             setExpanded={setExpanded}
//             displayElement={
//               <Grid container sx={{ display: 'flex', alignItems: 'center' }}>
//                 <Grid item xs={12}>
//                   <Typography variant='h6' sx={{ flexGrow: 1, textAlign: 'left' }}>
//                     {task?.name}
//                   </Typography>
//                 </Grid>
//               </Grid>
//             }
//           >
//             <TaskDrawerHeader task={task} organization={organization} />
//           </ExpandCollapseButton>
//           {isLoading ? (
//             <CircularProgress />
//           ) : (
//             <>
//               <Tabs value={tabIndex} onChange={handleChange}>
//                 {DRAWER_TABS.map((tab, index) => (
//                   <StyledTab key={index} label={tab.label} />
//                 ))}
//               </Tabs>

//               <Box
//                 sx={{
//                   flex: 1,
//                   overflowY: 'auto',
//                   bgcolor: theme.palette.background.section,
//                   p: 2,
//                 }}
//               >
//                 <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-end' }}>
//                   <SaveIconButton hasErrors={false} onSave={handleSave} />
//                 </Box>
//                 <Box
//                   sx={{
//                     display: 'flex',
//                     flexDirection: 'column',
//                     alignItems: 'center',
//                     justifyContent: 'center',
//                     marginTop: '20px', // Adjust this value as needed
//                     overflow: 'visible',
//                   }}
//                 >
//                   {tabIndex === 0 && task && (
//                     <TaskDetails
//                       task={task}
//                       onNavigate={handleNavigate}
//                       onChange={handleFieldUpdates}
//                       addtlNotes={localAddtlNotes}
//                     />
//                   )}
//                   {tabIndex === 1 && task && <TaskMessages task={task} />}
//                   {tabIndex === 2 && task && <DriveFoldersById folderId={task?.taskFolderId} isParentFolder={false} />}
//                 </Box>
//               </Box>
//             </>
//           )}
//           <Box
//             sx={{
//               width: '5px',
//               cursor: 'ew-resize',
//               backgroundColor: 'lightgray',
//               height: '100%',
//               position: 'absolute',
//               left: 0,
//               top: 0,
//               zIndex: 1200,
//             }}
//             onMouseDown={handleMouseDown}
//           />
//         </Box>
//       </Drawer>
//     </>
//   )
// }

// export default TaskDrawer
