import React, { useState, useEffect } from 'react'
import { Select, MenuItem, Typography, useTheme, Chip } from '@mui/material'

const ChangeBoardView = ({ context, onChangeView, selectedView, setSelectedView }) => {
  const theme = useTheme()

  // Initialize the selectedView state from localStorage or default to 'invoice'
  // Update localStorage and invoke onChangeView when the view changes
  const handleChange = event => {
    const newView = event.target.value // Ensure newView is a string
    console.log('View selected:', newView)

    if (typeof newView !== 'string') {
      console.error('Invalid view value:', newView)
      return
    }

    setSelectedView(newView)
    // localStorage.setItem(`${context}SelectedView`, JSON.stringify(newView))
    onChangeView(newView)
  }

  const options = [
    { label: 'NCO', value: 'nco', color: 'rgb(52, 211, 145)', disabled: false },
    { label: 'Creative', value: 'creative', color: 'rgb(150, 88, 238)', disabled: false },
    { label: 'SEO', value: 'seo', color: ' rgb(238, 111, 141)', disabled: false },
    { label: 'Website', value: 'website', color: 'rgb(112, 84, 240)', disabled: false },
    { label: 'Monthly Tasks', value: 'mtb', color: 'rgb(20, 119, 248)', disabled: false },
    { label: 'Team Tasks', value: 'teamtasks', color: 'rgb(247, 63, 236)', disabled: false },
  ]

  return (
    <Select
      value={selectedView ?? 'onboarding'}
      onChange={handleChange}
      sx={{
        minWidth: 200,
        maxWidth: 200,
        height: 40,
        color: theme.palette.text.primary,
        bgcolor: theme.palette.background.paper,
        borderRadius: 2,
        '& .MuiSelect-select': { cursor: 'pointer' }, // Prevent I-beam cursor
      }}
    >
      {options.map(option => (
        <MenuItem key={option.value} value={option.value} disabled={option?.disabled}>
          <Chip
            size='small'
            style={{
              backgroundColor: option.color,
              width: 14,
              height: 14,
              borderRadius: '50%',
              marginRight: 8,
            }}
          />
          <Typography variant='subtitle1'>{option.label}</Typography>
        </MenuItem>
      ))}
    </Select>
  )
}

export default ChangeBoardView
