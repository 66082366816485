import React, { useState, useEffect } from 'react'
import { Box, useTheme } from '@mui/material'
import { styled } from '@mui/material/styles'
import CreateOrganization from './components/CreateOrganization/CreateOrganization'
import CreateDeal from './components/CreateDeal/CreateDeal'
import CreateContact from './components/CreateContact/CreateContact'
import FloatingNavBar from './components/AppSidebar/FloatingNavBar'
import { useActiveUser } from './api/slack/useActiveUser'
import { DealDetailsProvider } from './context/DealsDetailsProvider/DealsDetailsProvider'
import { initialDealState } from './context/DealsDetailsProvider/initialDealState'
import SpeedDialComponent from './components/SpeedDial/SpeedDial'
import { NotificationProvider } from './context/NotificationProvider/NotificationProvider'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { useUser } from './api/aws/useUser'
import CreateTask from './components/Tasks/CreateTask'
import { useNavigate, useLocation } from 'react-router-dom'
import { useLayoutContext } from './context/LayoutProvider/LayoutProvider'
import { WebsocketProvider } from './context/Websocket/WebsocketProvider'
import CreativeIntakeForm from './components/Forms/CreativeIntakeForm.tsx'
import { useChangeView } from './utility/useChangeView.ts'
import MarketingTaskForm from './components/Forms/MarketingTaskForm.tsx'

const LAYOUT_NAV_HEIGHT = 60

const LayoutContainer = styled(Box, {
  shouldForwardProp: prop => prop !== 'pinned',
})(({ pinned }) => ({
  display: 'flex',
  flexDirection: 'column',
  position: 'relative',
  justifyContent: 'center',
  width: '100vw',
  // If pinned, we reduce our container’s height by the nav height
  height: pinned ? `calc(100vh - ${LAYOUT_NAV_HEIGHT}px)` : '100vh',

  // We can allow the content to scroll inside:
  overflow: 'hidden',
}))

// This is the scrollable region
// Example for MainContent if navbar is at top
const MainContent = styled(Box)(({ open, pinned, theme }) => ({
  transition: 'height 0.3s ease',
  overflow: 'hidden',
  height: pinned ? `calc(100vh - ${LAYOUT_NAV_HEIGHT}px)` : '100vh',
  marginTop: pinned ? `${LAYOUT_NAV_HEIGHT}px` : 0,
  flex: 1,
}))

const AppLayout = ({ children }) => {
  const theme = useTheme()
  const handleChangeView = useChangeView()
  const { activeUser, isActiveUserLoading } = useActiveUser()
  const { user: currentUser, isUserLoading: isCurrentUserLoading } = useUser(activeUser?.user?.id)
  const [isCreateOrganizationOpen, setIsCreateOrganizationOpen] = useState(false)
  const [isCreateDealOpen, setIsCreateDealOpen] = useState(false)
  const [isCreateContactOpen, setIsCreateContactOpen] = useState(false)
  const [isNotifyDrawerOpen, setIsNotifyDrawerOpen] = useState(false)
  const [isConnectedUsersOpen, setIsConnectedUsersOpen] = useState(false)
  const [isCreateTaskOpen, setIsCreateTaskOpen] = useState(false)
  const [isCreativeIntakeOpen, setIsCreativeIntakeOpen] = useState(false)
  const [isMarketingFormOpen, setIsMarketingFormOpen] = useState(false)

  const devSectionIds = ['UHKAA49CJ', 'U062JDP8R5X']
  const isUserLoading = isActiveUserLoading || isCurrentUserLoading

  // For route navigation tracking:
  const navigate = useNavigate()
  const location = useLocation()
  const [selectedPath, setSelectedPath] = useState(location.pathname)

  useEffect(() => {
    setSelectedPath(location.pathname)
  }, [location])

  const handleNavigation = path => {
    const boardViewMapping = {
      '/nco': 'nco',
      '/creative': 'creative',
      '/seo': 'seo',
      '/website': 'website',
      '/monthlytasks': 'mtb',
      '/deals': 'deals',
      '/organizations': 'organizations',
      '/contacts': 'contacts',
      '/leads': 'leads',
      '/teamtasks': 'teamtasks',
      '/mtb': 'mtb',
    }
    setSelectedPath(path)

    const newView = boardViewMapping[`/${path}`] || null
    if (newView) {
      console.log('[AppLayout] newView', newView)
      handleChangeView({ newView })
    } else {
      navigate(`/${path}`)
    }
  }
  // -- NEW: pinned state logic
  const { pinned, togglePinned, navOpen } = useLayoutContext()
  // same handlers as before...
  const handleOpenCreateOrganization = () => setIsCreateOrganizationOpen(true)
  const handleCloseCreateOrganization = () => setIsCreateOrganizationOpen(false)
  const handleOpenCreateDeal = () => setIsCreateDealOpen(true)
  const handleCloseCreateDeal = () => setIsCreateDealOpen(false)
  const handleOpenCreateContact = () => setIsCreateContactOpen(true)
  const handleCloseCreateContact = () => setIsCreateContactOpen(false)
  const handleOpenNotifyDrawer = () => setIsNotifyDrawerOpen(true)
  const handleCloseNotifyDrawer = () => setIsNotifyDrawerOpen(false)
  const handleOpenConnectedUsers = () => setIsConnectedUsersOpen(true)
  const handleCloseConnectedUsers = () => setIsConnectedUsersOpen(false)
  const handleOpenCreateTask = () => setIsCreateTaskOpen(true)
  const handleCloseCreateTask = () => setIsCreateTaskOpen(false)
  const handleOpenCreativeIntake = () => setIsCreativeIntakeOpen(true)
  const handleCloseCreativeIntake = () => setIsCreativeIntakeOpen(false)
  const handleOpenMarketingForm = () => setIsMarketingFormOpen(true)
  const handleCloseMarketingForm = () => setIsMarketingFormOpen(false)

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <NotificationProvider>
        <DealDetailsProvider dealData={initialDealState}>
          {/* Using column layout so we can push MainContent above the pinned nav */}
          {/* <LayoutContainer pinned={pinned}> */}
          <MainContent open={navOpen}>{children}</MainContent>

          {/* The modals for create org/deal/contact/task remain the same */}
          {isCreateOrganizationOpen && (
            <CreateOrganization open={isCreateOrganizationOpen} onClose={handleCloseCreateOrganization} />
          )}
          {isCreateDealOpen && <CreateDeal open={isCreateDealOpen} onClose={handleCloseCreateDeal} />}
          {isCreateContactOpen && <CreateContact open={isCreateContactOpen} onClose={handleCloseCreateContact} />}
          {isCreateTaskOpen && <CreateTask open={isCreateTaskOpen} onClose={handleCloseCreateTask} />}
          {isCreativeIntakeOpen && (
            <CreativeIntakeForm open={isCreativeIntakeOpen} onClose={handleCloseCreativeIntake} />
          )}
          {isMarketingFormOpen && <MarketingTaskForm open={isMarketingFormOpen} onClose={handleCloseMarketingForm} />}
          {/* {currentUser && <SpeedDialComponent currentUser={currentUser} isLoading={isUserLoading} />} */}

          {/* </LayoutContainer> */}
          {/* Floating Nav */}
          <WebsocketProvider>
            <FloatingNavBar
              handleOpenCreateOrganization={handleOpenCreateOrganization}
              handleOpenCreateDeal={handleOpenCreateDeal}
              handleOpenCreateContact={handleOpenCreateContact}
              onOpenCreateTask={handleOpenCreateTask}
              onCloseCreateTask={handleCloseCreateTask}
              onOpenCreativeIntake={handleOpenCreativeIntake}
              onCloseCreativeIntake={handleCloseCreativeIntake}
              onOpenMarketingForm={handleOpenMarketingForm}
              onCloseMarketingForm={handleCloseMarketingForm}
              isConnectedUsersOpen={isConnectedUsersOpen}
              handleOpenConnectedUsers={handleOpenConnectedUsers}
              handleCloseConnectedUsers={handleCloseConnectedUsers}
              isNotifyDrawerOpen={isNotifyDrawerOpen}
              onOpenNotify={handleOpenNotifyDrawer}
              onCloseNotify={handleCloseNotifyDrawer}
              devSectionIds={devSectionIds}
              handleNavigation={handleNavigation}
              selectedPath={selectedPath}
              imageUrl={activeUser?.user?.profile?.image_512 || null}
              user={currentUser}
              // Pass pinned & its toggle handler
              pinned={pinned}
              onTogglePinned={togglePinned}
            />
            {/* </Box> */}
          </WebsocketProvider>
        </DealDetailsProvider>
      </NotificationProvider>
    </LocalizationProvider>
  )
}

export default AppLayout
